/**
*
*   Shopify Analytics
*       - An integration into hydrogen to push analytics to shopify
*
**/
'use client';

import Config from '@/config';
import { useContext } from 'react';
import { CustomerContext } from '@/context/customer';
import { useCookies } from 'next-client-cookies';
import {
    AnalyticsEventName,
    AnalyticsPageType,
    getClientBrowserParameters,
    sendShopifyAnalytics,
    ShopifyAnalyticsProduct,
    ShopifyPageViewPayload,
    ShopifySalesChannel,
    useShopifyCookies
} from '@shopify/hydrogen-react';

//The Page Type
let pageType = null;

//Examples of content type(s), to be called with: AnalyticsPageType.[ type ]
const types = [
	'article',
	'blog',
	'captcha',
	'cart',
	'collection',
	'customersAccount',
	'customersActivateAccount',
	'customersAddresses',
	'customersLogin',
	'customersOrder',
	'customersRegister',
	'customersResetPassword',
	'giftCard',
	'home',
	'listCollections',
	'forbidden',
	'notFound',
	'page',
	'password',
	'product',
	'policy',
	'search'
];


/**
*
*	useShopifyAnalytics
* 		- The Shopify Analytics function
* 
* 	Params:
* 		n/a
*
**/
export default function useShopifyAnalytics(){

	//Get the Customers
    const { Customer } = useContext(CustomerContext);

    //Load the Cookies
	const cookies = useCookies();

	//Enable Shopify Cookies
	useShopifyCookies({
		hasUserConsent: true
	});



	//
	return {

		



		/**
		*
		*	getCookies
		* 		- Returns the Shopify Cookies
		*
		**/
		getCookies: async () => {
	    	return {
		    	y: await cookies.get('_shopify_y'),
		    	s: await cookies.get('_shopify_s')
	    	}
		},





		/**
		*
		*	sendPageView
		* 		- Sends a Page View to 
		*
		**/
		sendPageView: ({ locale , payload }) => {

			//Get the Customer ID
			payload.customerId 	= Customer.get( customer => customer.id );

			//Default Page Type
			if( payload.pageType ){

				//Set the Page Type and apply to default
				payload.pageType 	= AnalyticsPageType[ ( payload.pageType ?? 'page' ) ];
				pageType 			= payload.pageType;

			}else{

				//Use the previously set one
				payload.pageType = pageType;

			}

			//Send the Page View Analytics
			return sendShopifyAnalytics({
				eventName: AnalyticsEventName.PAGE_VIEW,
				payload: {
					...getClientBrowserParameters(),
					hasUserConsent: 		true,
					shopifySalesChannel: 	ShopifySalesChannel.headless,
					shopId: 				`gid://shopify/Shop/${ Config.shopId }`,
					currency: 				Config.currency,
					acceptedLanguage: 		locale,
					...payload
				}
			});

		},



		/**
		*
		*	sendPageView
		* 		- Sends a Page View to 
		*
		**/
		addToCart: ({ locale , payload }) => {
			
			//
			payload.customerId 	= Customer.get( customer => customer.id );

			return sendShopifyAnalytics({
				eventName: AnalyticsEventName.ADD_TO_CART,
				payload: {
					...getClientBrowserParameters(),
					hasUserConsent: 		true,
					shopifySalesChannel: 	ShopifySalesChannel.headless,
					shopId: 				`gid://shopify/Shop/${ Config.shopId }`,
					currency: 				Config.currency,
					acceptedLanguage: 		locale,
					...payload
				}
			})
		}


	}

}