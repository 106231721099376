/**
*
*   Add To Cart (Button)
*       - Setup & Translations for the Add to Cart Button
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import Storefront from '@/libraries/shopify/class';
import { CartContext } from '@/context/cart';
import { CustomerContext } from '@/context/customer';
import { useTranslations } from 'next-intl';
import { useContext, useState, useEffect } from 'react';

//Build the Element
export default function Reload({ ...props }){

    //Translations
    const t = useTranslations();

    //Cart Context
    const { Cart } = useContext(CartContext);

    //Get the Customer
    const { Customer } = useContext(CustomerContext);

    //The message to display
    const [ state , setState ] = useState({
        key:            'cartid',
        id:             null,
        message:        t('Components.Cart.reload.button'),
        actionable:     true,
        running:        false,
        ready:          false
    });


    //The Reload Component Functions
    const Component = {

        //An Error Occured
        error: () => {

            //Update Message
            setState({
                message: t('Components.Cart.reload.error'),
                actionable: false
            });

            //Remove bad key and end function
            localStorage.removeItem( state.key );

            //Start the Loading
            setState({
                ...state,
                running: false
            })

        },

        //The Reload Function
        reload: async () => {

            //Set it as running
            setState({
                ...state,
                running: true
            });

            //Get the Cart Data
            let data = ( await Storefront.Cart.get( state.id ) ).body;

            //If we had an error
            if(!data) return Component.error();

            //Prep the Data
            const lineItems = [];

            //Add in the rows
            data.lines.forEach((row)=>{
                lineItems.push({
                    product:    row?.product,
                    variant:    row?.product?.variant,
                    quantity:   row.quantity,
                    currency:   'fiat'
                })
            });

            //Create the cart, or return error if failed
            if( !Cart.create( lineItems ) ){
                return Component.error();
            }

            //Set it as running
            setState({
                ...state,
                running: false
            })

        }
    }



    //Make sure the cart doesn't already exists, or the swap cart does
    useEffect( () => {
        if( !Cart.loading ){
            if( Customer.is.loggedin() && typeof window != 'undefined' ){
                if( Cart.size() == 0 ){

                    //The ID
                    const id = ( localStorage.getItem( state.key ) || null );

                    //Validate the ID form
                    if( id && id.indexOf('gid://') > -1 ){

                        //The ID
                        setState({
                            ...state,
                            id:     id,
                            ready:  true
                        })

                    }

                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ Cart.loading ]);


    //Return
    return state.ready && (
        <>
            <p>{ t('Components.Cart.reload.message') }</p>
            { state.actionable && (
                <Button disabled={ state.running } onClick={ () => Component.reload() }>{ state.message }</Button>
            )}
        </>
    )

}