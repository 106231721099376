/**
*
*   Notifications
*       - The Header Notifications Bar
*
**/
'use client';

//
import Config from '@/config';
import Storefront from '@/libraries/shopify/class';
import { useTranslations, useLocale } from 'next-intl';
import { useState, useEffect } from 'react';


//Build the Element
export default function Notifications(){

    //Get the Translations
    const t = useTranslations();

    //Get the Locale
    const locale = useLocale();

    //The Page
    const [ step , setStep ] = useState(0);

    //The Timeout Interval
    let intval = null;

    //The Delay between transitions
    const delay = 5000;

    //The Default Settings
    const preset = [{
        type:    'base',
        content: t.rich('General.shipping',{
            threshold:  () => Config.shipping,
            flag:       (() => {
                if( Config.country == 'CA' ) return (<i className="fa-brands fa-canadian-maple-leaf" />);
                if( Config.country == 'US' ) return (<i className="fa-solid fa-flag-usa"></i>);
            })
        })
    }];

    //Prep the Notifications
    const [ notifications , setNotifications ] = useState( preset );

    //Load the Notifications
    useEffect(()=>{
        (async (data) => {
            
            //Get the Store Data
            const list = await ( await fetch('/api/settings/notifications',{ next: { revalidate: 3600 } } ) ).json();

            //Return if no data or it failed
            if( !list.result || list?.body?.filter( item => item[ locale ] != '' )?.length == 0 )
                return false;

            //Prepare all data
            list.body.filter( item => item[ locale ] != '' ).forEach( item => data.push({
                type:       'api',
                content:    item[ locale ]
            }) );

            //Update
            setNotifications( data );

        })([ ...preset ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Start the timer
    useEffect(()=>{

        //Clear any change
        if( intval ) intval = window.clearTimeout( intval );

        //Set the Interval
        intval = window.setTimeout(
            () => setStep( step == notifications.length - 1 ? 0 : step + 1 ), 
            delay 
        );
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[step, notifications])

    //Return
    return (
        <section id="notifications">
            {
                notifications.map( ( panel , i ) => {
                    switch( panel.type ){
                        case 'base': return (
                            <span key={ i } className={ step == i ? 'active' : '' }>
                                { panel.content }
                            </span>
                        )
                        default: return (
                            <span key={ i } className={ step == i ? 'active' : '' } dangerouslySetInnerHTML={{ __html: panel.content }} />
                        )
                    }
                })
            }
        </section>
    );

}