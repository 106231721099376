/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { PatternFormat } from 'react-number-format';
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Country, State, City } from 'country-state-city';


//Build the Element
export default function NewsletterModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm({
        shouldUnregister: true
    });

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //Get all Countries
    const countries = Country.getAllCountries();

    //The State Status
    const [ states , setStates ] = useState([]);

    //The Autocomplete State
    const [ address , setAddress ] = useState('');

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Get the zip label
    const zip = ( getValues('country') == 'CA' ? t('Forms.Labels.postal') : t('Forms.Labels.zip') );

    //Form Functions
    const Form = {

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('root.serverError',{
            type: 500,
            message: message
        }),



        /**
        *
        *   autocomplete
        *       - Modify all form fields when choosing an address
        *
        *   Params:
        *       n/a
        *
        **/
        autocomplete: async (response, onChange) => {
            if( response.value ){

                let place     = await geocodeByPlaceId( response.value.place_id );
                let address   = [];

                if( place.length == 0 ) return onChange( response.value.description )

                place = place[0];

                //Reset Fields
                ['address2','city','postal_code'].forEach( type => resetField(type) );

                //Set the rest
                place.address_components.forEach(value => {
                    if( ['administrative_area_level_2'].indexOf( value.types[0] ) === -1 ) {
                        switch( value.types[0] ){
                            case 'subpremise':
                                setValue( 'address2' , value.long_name )
                                break;
                            case 'locality':
                                setValue( 'city' , value.long_name );
                                break;
                            case 'administrative_area_level_1':
                                setValue( 'province' , value.short_name );
                                break;
                            case 'country':
                                break;
                            case 'postal_code':
                                setValue( 'zip', value.long_name );
                                break;
                            default:
                                address.push( value.long_name );
                        }
                    }
                });

                //Update Field
                onChange( address.join(' ') );

                //Show Label
                setAddress({
                    label: address.join(' '),
                    value: response.value
                });

                return {
                    label: address.join(' '),
                    value: response.value
                };

            }
        },

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                //Show Loading
                setLoading(true);

                //Prep the input
                const input = {
                    address1:   data.address1,
                    address2:   data.address2,
                    city:       data.city,
                    company:    data.company,
                    country:    data.country,
                    firstName:  data.firstName,
                    lastName:   data.lastName,
                    phone:      data.phone,
                    province:   data.province,
                    zip:        data.zip
                }

                //Create a new entry
                const response = (
                    !modal.data ?

                        //Create new entry
                         await Storefront.Customer.addresses.create( session.accessToken , input )
                    :

                        //Update Existing
                        await Storefront.Customer.addresses.update( session.accessToken , modal.data.id , input )

                );

                //Show errors if required
                if(!response.result){
                    Form.thrown(
                        response.errors.map( ( row , i ) => (<p key={i}>{ row.message }</p>)
                    ));
                    return setLoading(false);
                }

                //Success, Return callback
                if( modal.callback ) await modal.callback();

                //Clear Loading
                setLoading(false);

                //Close
                Modal.success();

            });
        }

    }

    //Preset the email
    useEffect(()=>{

        //Set the Values
        Customer.get( customer => {
            setValue( 'firstName' , customer.firstName );
            setValue( 'lastName' , customer.lastName );
            setValue( 'company' , customer.company );
        });


        let modal = Modal.get( modal => {
            if( !modal.data ){

                //Get Default Country
                const country = Config.country.toUpperCase();

                //Set the default country and states
                setValue( 'country' , country );
                setStates( State.getStatesOfCountry( country ) );

            }else{

                //Set the defaultstate
                setStates( State.getStatesOfCountry( modal.data.countryCodeV2 ) );

                //Add the field data
                for( const key in modal.data ){
                    switch( key ){
                        case 'address1':
                            setValue( 'address1' , modal.data[ key ] );
                            setAddress({
                                label: modal.data[ key ],
                                value: {}
                            })
                            break;
                        case 'country':
                            setValue( key , modal.data.countryCodeV2 );
                            break;
                        case 'province':
                            break;
                        default:
                            setValue( key , modal.data[ key ] );
                    }
                }

            }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //On change of states, set province Code as the active province if the country matches
    useEffect( () => {
        Modal.get( modal => {
            if( modal?.data?.provinceCode && modal?.data?.countryCodeV2 == getValues('country') ){

                //Update the province
                setValue( 'province' , modal.data?.provinceCode );

            }
        });
    },[states])

    //The Form Submission
    return Modal.get( modal => (
        <form id="address-form" onSubmit={ handleSubmit(Form.submit) }>
            <header>
                <h2>{ modal.address.title }</h2>
                <p>{ modal.address.description }</p>
            </header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }

            {/* Name */}
            <div className="row">
                <div>
                <label htmlFor="firstName">{ t('Forms.Labels.firstname') }</label>
                    <input type="text" name="firstName" placeholder={ t('Forms.Labels.firstname') + '*' } {...register('firstName', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.firstname') }) })} />
                    <ErrorMessage errors={errors} name="firstName" render={ Form.error } />
                </div>
                <div>
                <label htmlFor="lastName">{ t('Forms.Labels.lastname') }</label>
                    <input type="text" name="lastName" placeholder={ t('Forms.Labels.lastname') + '*' } {...register('lastName', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.lastname') }) })} />
                    <ErrorMessage errors={errors} name="lastName" render={ Form.error } />
                </div>
            </div>

            {/* Company */}
            <div className="row">
                <label htmlFor="company">{ t('Forms.Labels.company') }</label>
                <input type="text" name="company" placeholder={ t('Forms.Labels.company') } {...register('company')} />
                <ErrorMessage errors={errors} name="company" render={ Form.error } />
            </div>

            {/* Country */}
            <div className="row">
                <label htmlFor="country">{ t('Forms.Labels.country') }</label>
                <select
                    name="country"
                    {...register('country', {
                        required: t('Forms.Errors.required',{ type: t('Forms.Labels.country') }),
                        onChange: (e,value) => setStates( State.getStatesOfCountry( e.target.value ) )
                    })}>
                    { countries.map( country => <option key={ country.isoCode } value={ country.isoCode }>{ country.name }</option>) }
                </select>
                <ErrorMessage errors={errors} name="address1" render={ Form.error } />
            </div>

            {/* Address 1 */}
            <div className="row">
                <label htmlFor="address1">{ t('Forms.Labels.address1') }</label>
                <Controller control={control} name="address1" render={({ field: { onChange, name, value } }) => (
                    <GooglePlacesAutocomplete
                        apiKey={ process.env.NEXT_PUBLIC_GOOGLE_API }
                        language={ locale }
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: [ ( getValues('country') || 'usa' ).toLowerCase() ]
                            }
                        }}
                        selectProps={{
                            name:           name,
                            value:          address,
                            placeholder:    t('Forms.Labels.address1'),
                            onChange:       (response) => Form.autocomplete(response, onChange)
                        }}
                    />
                )} />
                <ErrorMessage errors={errors} name="address1" render={ Form.error } />
            </div>

            {/* Address 2 */}
            <div className="row">
                <div>
                    <label htmlFor="address2">{ t('Forms.Labels.address2') }</label>
                    <input type="text" name="address2" placeholder={ t('Forms.Labels.address2') } {...register('address2')} />
                    <ErrorMessage errors={errors} name="address2" render={ Form.error } />
                </div>
            </div>

            {/* City / State / Zip */}
            <div className="row">
                <div>
                    <label htmlFor="city">{ t('Forms.Labels.city') }</label>
                    <input type="text" name="city" placeholder={ t('Forms.Labels.city') + '*' } {...register('city', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.city') }) })} />
                    <ErrorMessage errors={errors} name="city" render={ Form.error } />
                </div>
                <div>
                    <label htmlFor="province">{ t('Forms.Labels.province') }</label>
                    <select name="province"  {...register('province', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.province') }) })}>
                        { states.map( state => <option key={ state.isoCode } value={ state.isoCode }>{ state.name }</option> ) }
                    </select>
                    <ErrorMessage errors={errors} name="province" render={ Form.error } />
                </div>
                <div>
                    <label htmlFor="zip">{ zip }</label>
                    <input type="text" name="zip" placeholder={ zip } {...register('zip', { required: t('Forms.Errors.required',{ type: zip }) })} />
                    <ErrorMessage errors={errors} name="zip" render={ Form.error } />
                </div>
            </div>

            {/* Phone */}
            <div className="row">
                <div>
                    <label htmlFor="phone">{ t('Forms.Labels.phone') + ' ' + t('Forms.Labels.optional') }</label>
                    <Controller control={control} name="phone" render={({ field: { onChange, name, value } }) => (
                        <PatternFormat format="+#-###-###-####" name={name} value={value} onChange={onChange} placeholder={ t('Forms.Labels.phone') + ' ' + t('Forms.Labels.optional') } />
                    )} />
                    <ErrorMessage errors={errors} name="phone" render={ Form.error } />
                </div>
            </div>

            <footer>
                <Button type="submit" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.save') }</Button>
                <a onClick={()=>Modal.close()}>{
                    t('Components.Modal.close')
                }</a>
            </footer>
        </form>
    ));

}