/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import RewardsHook from '@/libraries/rewards';
import Recaptcha from '@/libraries/recaptcha';
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { ReviewsContext } from '@/context/reviews';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useReCaptcha } from 'next-recaptcha-v3';


//Build the Element
export default function ReviewModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm({
        shouldUnregister: true
    });

    const action = Modal.get(modal => modal.reviews.reviewid ? 'edit' : 'add' );

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //The Hover State
    const [ hover , setHover ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Get the Reviews Context
    const Reviews = Modal.get( modal => modal.reviews.context );

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Recaptcha
    const { executeRecaptcha } = useReCaptcha();

    //The Rewards
    const Rewards = RewardsHook();

    //Form Functions
    const Form = {

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('root.serverError',{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                //Show Loading
                setLoading(true);

                //Get the Recaptcha
                const token = await executeRecaptcha('reviews/post');

                //The Review
                const review = {
                    customer:   data.name,
                    city:       data.city,
                    rating:     data.rating,
                    title:      data.title,
                    body:       data.body,
                    helpful:    {
                        yes: 0,
                        no:  0
                    }
                };

                //Add the Rating
                const response = await Reviews.add( review , token );

                //Show errors if required
                if(!response.result){
                    Form.thrown([
                        (<p key={0}>{ response.message }</p>)
                    ]);
                    return setLoading(false);
                }

                //Add the Points
                if( Customer.is.loggedin() && !Rewards.awarded( 'review:' + Reviews.reference ) ) await Rewards.add( 'review:' + Reviews.reference , 'reviews' );

                //Clear Loading
                setLoading(false);

                //Close
                Modal.success();

                //Run the Callback
                if( modal.reviews.callback ) modal.reviews.callback( response.body );

            });
        }

    }

    //Preset the email
    useEffect(()=>{
        if( Customer.is.loggedin() ){

            //Set the Values
            Customer.get( customer => {
                setValue( 'name' , ( customer.username?.value || customer.firstName + ' ' + customer.lastName ) );
                setValue( 'city' , customer.defaultAddress?.city );
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status]);

    //The Form Submission
    return Modal.get( modal => (
        <form id="review" onSubmit={ handleSubmit(Form.submit) }>
            <header>
                <h2>{ t.rich(`Components.Modal.Reviews.header.${action}.title`, { for: Reviews.name }) }</h2>
                <p>{ t(`Components.Modal.Reviews.header.${action}.description`) }</p>
            </header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }

            {/* Rating */}
            <div className="row">
                <div className="rating">
                    <label htmlFor="rating">{ t('Components.Modal.Reviews.rating') }</label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="11" viewBox="0 0 62 11" fill="none">{
                        [
                            'M5.30303 0.5L3.6643 3.7917L0 4.31946L2.65152 6.88191L2.02545 10.5L5.30303 8.7917L8.58049 10.5L7.95455 6.88191L10.6061 4.31946L6.94176 3.7917L5.30315 0.5H5.30303Z',
                            'M17.9293 0.5L16.2905 3.7917L12.6262 4.31946L15.2777 6.88191L14.6518 10.5L17.9293 8.7917L21.2067 10.5L20.5808 6.88191L23.2323 4.31946L19.5681 3.7917L17.9294 0.5H17.9293',
                            'M30.5553 0.5L28.9167 3.7917L25.2524 4.31946L27.9038 6.88191L27.2779 10.5L30.5553 8.7917L33.8329 10.5L33.207 6.88191L35.8585 4.31946L32.1942 3.7917L30.5555 0.5H30.5553',
                            'M43.1816 0.5L41.543 3.7917L37.8787 4.31946L40.5301 6.88191L39.9041 10.5L43.1816 8.7917L46.4592 10.5L45.8332 6.88191L48.4847 4.31946L44.8204 3.7917L43.1817 0.5H43.1816',
                            'M55.8082 0.5L54.1694 3.7917L50.5051 4.31946L53.1566 6.88191L52.5306 10.5L55.8082 8.7917L59.0856 10.5L58.4597 6.88191L61.1112 4.31946L57.4469 3.7917L55.8083 0.5H55.8082'
                        ].map( (d,i) => (
                            <path
                                key={ i }
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d={d}
                                fill={ ( ( hover && hover >= ( i + 1 ) ) || getValues('rating') >= ( i + 1 ) ) ? 'currentcolor' : 'transparent' }
                                stroke="currentcolor"
                                onClick={()=>setValue( 'rating', i + 1 )}
                                onMouseOver={()=>setHover( i + 1 )}
                                onMouseOut={()=>setHover(false)}
                            />
                        ))
                    }</svg>
                    <input type="hidden" id="rating" name="rating"  {...register('rating', { required: t('Forms.Errors.required',{ type: t('Components.Modal.Reviews.rating') }) })} />
                    <ErrorMessage errors={errors} name="rating" render={ Form.error } />
                </div>
            </div>

            {/* Name */}
            <div className="row">
                <div>
                <label htmlFor="name">{ t('Forms.Labels.name') }</label>
                    <input type="text" name="name" readOnly={ Customer.is.loggedin() } placeholder={ t('Forms.Labels.name') + '*' } {...register('name', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.name') }) })} />
                    <ErrorMessage errors={errors} name="name" render={ Form.error } />
                </div>
                <div>
                <label htmlFor="city">{ t('Forms.Labels.city') }</label>
                    <input type="text" name="city" placeholder={ t('Forms.Labels.city') } {...register('city')} />
                    <ErrorMessage errors={errors} name="city" render={ Form.error } />
                </div>
            </div>

            {/* Title*/}
            <div className="row">
                <div>
                    <label htmlFor="title">{ t('Components.Modal.Reviews.title') }</label>
                    <input type="text" name="title" placeholder={ t('Components.Modal.Reviews.title') + '*' } {...register('title', { required: t('Forms.Errors.required',{ type: t('Components.Modal.Reviews.title') }) })} />
                    <ErrorMessage errors={errors} name="title" render={ Form.error } />
                </div>
            </div>

            {/* Message*/}
            <div className="row">
                <div>
                    <label htmlFor="body">{ t('Components.Modal.Reviews.body') }</label>
                    <textarea name="body" placeholder={ t('Components.Modal.Reviews.body') + '*' } {...register('body', { required: t('Forms.Errors.required',{ type: t('Components.Modal.Reviews.body') }) })} />
                    <ErrorMessage errors={errors} name="body" render={ Form.error } />
                </div>
            </div>
            <p>{ Recaptcha.message }</p>

            <footer>
                <Button type="submit" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.save') }</Button>
                <a onClick={()=>Modal.close()}>{
                    t('Components.Modal.close')
                }</a>
            </footer>
        </form>
    ));

}