/**
*
*   Button
*       - Builds the Button Class
*
**/
'use client';
import Config from '@/config';
import Button from '@/components/button';
import { Link } from '@/components/link';
import { ModalContext } from '@/context/modal';
import { useTranslations } from 'next-intl';
import { useContext } from 'react';

//Build the Element
export default function CtaButton({ children, title, description, type, value, link, ...props }){

    const { Modal } = useContext(ModalContext);

    const t = useTranslations();

    //Use Link
    if( link ) return (
        <a onClick={ (e) => Modal.open({
            b2bcta: {
                title:          ( title || t.rich('Components.Modal.Business.title') ),
                description:    ( description || t('Components.Modal.Business.description') ),
                type:           ( type || 'contact' ),
                value:          ( value || 100 )
            }
        })} {...props}>{ children }</a>
    );

    //Use Button
    return (
        <Button onClick={ (e) =>  Modal.open({
            b2bcta: {
                title:          ( title || t.rich('Components.Modal.Business.title') ),
                description:    ( description || t('Components.Modal.Business.description') ),
                type:           ( type || 'contact' ),
                value:          ( value || 100 )
            }
        })} {...props}>{ children }</Button>
    )

}