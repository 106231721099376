/**
*
*   Content Block
*       - A left/right content block + image or video
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import { header } from '@/components/header/wrapper';
import { useSearchParams } from 'next/navigation';
import { ProductContext } from '@/context/product';
import { useState, useRef, useEffect, useContext } from 'react';

//Build the Element
export default function Gallery({ product, ...props }){

    //Default Photo
    const [ photo , setPhoto ] = useState( 0 );

    //The Main Photo
    const ref = useRef();

    //Load Search Params
    const params = useSearchParams();

    //Load the Product Context
    const { Product } = useContext(ProductContext);

    //The Media Library
    const Media = {

        //Get a chunk of teh comparable URL
        chunk: (string) => string.substring( 0 , string.lastIndexOf('_') ),

        //Change the Media URL
        change: (variant) => {

            //If we have a variant
            if( variant && variant?.image?.url ){

                const url = Media.chunk( variant.image.url );

                setPhoto( product.media.findIndex( item => item.image.url.indexOf( url ) > -1 ) )
            
            }

        }

    }

    //Only on first run, set the picture to be the one set in params.
    useEffect(()=>{

        //Get the Variant
        const id = params.get('variant');

        //If we have a variant id
        if( id ){

            const variant = product.variants.find( v => v.id.replace(/[^0-9]+/,'') == id );

            Media.change( variant );

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //Run when the product changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect( () => Media.change( Product.data.variant ) , [ Product ] );


    //Return
    return (
        <section className="gallery" ref={ ref }>
            <div>
                { 
                    product.media.map( (media,i) => (
                        <Image key={i} src={ media.image.url } alt="" className={ 'main' + ( i == photo ? ' selected' : '' ) } width="405" height="405" quality={ 100 } />
                    ))
                }
            </div>
            { product.media.length > 1 && (
                <footer>{
                    product.media.map( (media,i) => (
                        <Image
                            key={i}
                            src={ media.image.url }
                            alt=""
                            className={ ( photo == i ? 'selected' : '' ) + ' thumbnail' }
                            width={ 59 }
                            height={ 61 }
                            onClick={()=>{
                                window.setTimeout(()=>{

                                    setPhoto( i );

                                },300);
                            }}
                        />
                    ))
                }</footer>
            )}
        </section>
    );

}