/**
*
*   Signup Footer
*       - The Signup Area on the Footer
*
**/
'use client';

import Config from '@/config';
import Preloader from '@/components/preloader';
import Button from '@/components/button';
import Image from '@/components/image';
import { gsap } from 'gsap';
import { useGSAP } from "@gsap/react";
import { AnalyticTools } from '@/libraries/analytics';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useReCaptcha } from 'next-recaptcha-v3';

//Load Module
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


//Build the Element
export default function SignupFooter({ children, ...props }){

    //Get the Translations
    const t = useTranslations();

    //Newsletter Section Reference
    const ref = useRef();

    //The Current Locale
    const locale = useLocale();

    //Recaptcha
    const { executeRecaptcha } = useReCaptcha();

    //The Form Code for Klaviyo
    const form = (()=>{

        if( props.business ) return {
            id:     'RMLKvJ',
            type:   'business',
            image:  'footer/business-newsletter-image.jpg'
        }

        //Default Retail Form
        return {
            id:     'YcCukq',
            type:   'retail',
            image:  'footer/newsletter-image.jpg'
        }

    })();

    //Are we loading the form
    const [ loading , setLoading ] = useState(false);

    //Form State
    const [ completed , setCompleted ] = useState(false);

    //Form State
    const [ exists , setExists ] = useState(false);

    //The Copied State
    const [ copied , setCopied ] = useState(false);

    //Set the Coupon Code
    const [ code , setCode ] = useState(false);

    //Form Settings
    const { control, register, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //The Newsletter Form
    const Form = {

        //Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('email',{
            type: 500,
            message: t('Forms.Errors.unknown') + ' ' + message
        }),

        //On Submit
        submit: async data => {

            //Show Loader
            setLoading(true);

            //Get Recaptcha
            const token = await executeRecaptcha('klaviyo/signup');

            //Submit
            const response = (
                await (
                    await fetch( '/api/klaviyo' , {
                        method: 'POST',
                        body:   JSON.stringify({
                            email:  data.email,
                            list:   form.id,
                            coupon: ( form.type == 'retail' ),
                            token:  token
                        })
                    })
                ).json()
            );

            //
            if( !response.result ){
                if( response.code == 'bad-request' ){

                    //Bad Request
                    setLoading(false);
                    return setError('email',{
                        type: 500,
                        message: response.error
                    });

                }else{

                    //Output Exists
                    setLoading( false );
                    setExists( true )

                }
            }else{

                //Output Success
                setLoading( false );
                setCompleted( true );

            }

            //
            AnalyticTools.track(
                { email: data.email },
                () => AnalyticTools.newsletter( form.type )
            );

            //If it's retail add the coupon
            //if( form.type == 'retail' )
                //setCode( response.body.codes[0].code );

            //Identify user with Klaviyo
            klaviyo.identify({
                domain:     Config.domain,
                country:    Config.country,
                currency:   Config.currency,
                locale:     locale,
                email:      data.email
            },()=>{
                //Do Nothing
            });

        }

    };

    //GSAP
    useGSAP(()=>{

        //The Container
        let $container  = ref.current,
            timeline    = gsap.timeline({
                scrollTrigger: {
                    trigger:                $container,
                    invalidateOnRefresh:    true,
                    scrub:                  true,
                    start:                  'top center',
                    end:                    () => "+=" + $container.offsetHeight
                }
            });

        //Initiate
        timeline.fromTo( 'img' , { yPercent: -20 } , { yPercent: -25 } );

    },{ scope: ref });


    //Return
    return (
        <section className={ form.type + ' newsletter' } ref={ ref }>
            <div className="container">
                <div className="image">
                    <Image src={ form.image } alt="" width="540" height="640" />
                </div>
                <div className="form">
                    { !completed && (
                        <>
                            <header>{ t(`Footer.Newsletter.${ form.type }.title`) }</header>
                            <form onSubmit={ handleSubmit(Form.submit) }>
                                <div>
                                    <label htmlFor="email">{ t('Forms.Labels.email') }</label>
                                    <input type="text" placeholder={ t('Forms.Labels.email') } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                                    <Button type="submit" disabled={ loading } button>{(
                                        loading ? (<Preloader state={true} mini />) : t('Footer.Newsletter.button' )
                                    )}</Button>
                                </div>
                                <ErrorMessage errors={errors} name="email" render={ Form.error } />
                            </form>
                        </>
                    )}
                    { exists && (
                        <>
                            <header>
                                <h3>{ t(`Footer.Newsletter.${ form.type }.exists.title`) }</h3>
                                <p>{ t.rich(`Footer.Newsletter.${ form.type }.exists.content`) }</p>
                            </header>
                        </>
                    )}
                    { completed && (
                        <>
                            <header>{ t(`Footer.Newsletter.${ form.type }.success.title`) }</header>
                            <p>{ t(`Footer.Newsletter.${ form.type }.success.content`) }</p>
                            { /*form.type == 'retail' && (
                                <code onClick={()=>{

                                    //Copy to Clipboard
                                    navigator.clipboard.writeText( code );

                                    //Show Copied
                                    setCopied( true );

                                    //Hide it after
                                    window.setTimeout( ()=> setCopied(false) , 1000 );

                                }}>
                                    <div className={ 'success' + ( copied ? ' show' : '' ) }>
                                        { t('Footer.Newsletter.retail.success.copied') }
                                    </div>
                                    <div className="copy">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M384 352H224c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H332.1c4.2 0 8.3 1.7 11.3 4.7l67.9 67.9c3 3 4.7 7.1 4.7 11.3V320c0 17.7-14.3 32-32 32zM433.9 81.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H224c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V416H256v32c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32h64V128H64z"/>
                                        </svg>
                                    </div>{ code }
                                </code>
                            )*/}
                        </>
                    )}
                </div>
            </div>
        </section>
    );

}