/**
*
*   Customer Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import Preloader from '@/components/preloader';
import Storefront from '@/libraries/shopify/class';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Customer from '@/libraries/customer';
import { createContext, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';

//The Cart Context
export const CustomerContext = createContext(null);

//Create the Cart Context
function CustomerProvider({ data , session , children }){

	//Load the Router
	const router = useRouter();

	//The Store Data
	const [customer, setCustomer] = useState(data);

	//Is the Customer Loading
	Customer.loading = false;

	//Is the Customer Wholesale?
	Customer.wholesale = customer.wholesale;

	//
	Customer.is = {

		//Is the Customer Wholesale
		wholesale: (type) => customer.tags.filter( tag => tag.toLowerCase().indexOf( type.toLowerCase() ) > -1 ).length > 0,

		//Is the customer logged in
		loggedin: 	() => session

	};

	//Add the Get field to the Customer object
	Customer.get = (callback) => {
		try {
			return callback(customer)
		} catch(e) {
			return false;
		}
	};

	Customer.set = (data) => setCustomer({
		...customer,
		...data
	});

	//Return the Provider
	return (
		<CustomerContext.Provider value={{ Customer }}>
			{children}
		</CustomerContext.Provider>
	)
}

export default CustomerProvider;