/**
*
*   Modal
*       - The Base Modal Wrapper
*
**/
'use client';

//
import ProductModal from '@/components/modals/product';
import ShareModal from '@/components/modals/share';
import BirthdayModal from '@/components/modals/birthday';
import NewsletterModal from '@/components/modals/newsletter';
import ConfirmModal from '@/components/modals/confirm';
import AddressModal from '@/components/modals/address';
import AccountModal from '@/components/modals/account';
import PasswordModal from '@/components/modals/password';
import CommentModal from '@/components/modals/comment';
import B2BCtaModal from '@/components/modals/b2b-cta';
import ReviewsModal from '@/components/modals/reviews';
import IngredientsModal from '@/components/modals/ingredients';
import { ModalContext } from '@/context/modal';
import { useRef, useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import { usePathname } from 'next/navigation';
import { NextIntlClientProvider, useMessages } from 'next-intl';

//Build the Element
export default function ModalContainer(){

    const { Modal } = useContext(ModalContext);
    const ref = useRef();
    const handle = useCallback((e)=>{
        if( e.target == ref.current ) Modal.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ref])

/*
    //On Mount
    useEffect(() => {
        setTimeout(() => { window.addEventListener('click', handles.backdrop ); });
        return () => window.removeEventListener('click', handles.backdrop );
    }, [])
*/



    //Skip if not opened
    if( !Modal.state() ) return null;

    if( Modal.get( modal => modal.success ) )
        return (
            <div id="modal" ref={ref} onClick={ handle }  { ...Modal.get( modal => modal.attributes ) }>
                <div className="success">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle cx="26" cy="26" r="25" fill="none" />
                        <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                </div>
            </div>
        )

    //Return
    return Modal.get( modal => (
        <div id="modal" ref={ref} onClick={ handle }  { ...modal.attributes }>
            { modal.success && (
                <div className="success">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                </div>
            )}
            { !modal.success && (
                <div className="content">

                    {/* Product Details */}
                    { modal.product && (
                        <ProductModal { ...modal.product } />
                    ) }

                    {/* Give 10 / Get 10 */}
                    { modal.share && (
                        <ShareModal Modal={ Modal } />
                    )}

                    {/* Birthday Signup */}
                    { modal.birthday && (
                        <BirthdayModal Modal={ Modal } />
                    )}

                    {/* Newsletter Signup */}
                    { modal.newsletter && (
                        <NewsletterModal Modal={ Modal } />
                    )}

                    {/* Confirm */}
                    { modal.confirm && (
                        <ConfirmModal Modal={ Modal } />
                    )}

                    {/* Confirm */}
                    { modal.address && (
                        <AddressModal Modal={ Modal } />
                    )}

                    {/* Account Modal*/}
                    { modal.account && (
                        <AccountModal Modal={ Modal } />
                    )}

                    {/* Password Modal*/}
                    { modal.password && (
                        <PasswordModal Modal={ Modal } />
                    )}

                    {/* Comment Form Modal*/}
                    { modal.comment && (
                        <CommentModal Modal={ Modal } />
                    )}


                    {/* Comment Form Modal*/}
                    { modal.b2bcta && (
                        <B2BCtaModal Modal={ Modal } />
                    )}


                    {/* Product Ingredients */}
                    { modal.ingredients && (
                        <IngredientsModal Modal={ Modal } />
                    )}


                    {/* Product Ingredients */}
                    { modal.reviews && (
                        <ReviewsModal Modal={ Modal } />
                    )}

                    {/* Generic Content */}
                    { modal.content && (
                        modal.content
                    )}

                    <a onClick={ () => Modal.close() } rel="model:close" className="close">
                        <span></span>
                        <span></span>
                    </a>
                </div>
            )}
        </div>
    ));

}