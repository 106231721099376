/**
*
*   i18n
*       - International Locale Messages Middleware
*
**/
'use client';

import Config from '@/config';
import { ReactNode } from "react";
import { NextIntlClientProvider, AbstractIntlMessages , useLocale } from "next-intl";


export default function LanguageProvider({ messages, locale , children , now , timeZone }) {

  const l = useLocale();

  return (
    <NextIntlClientProvider
      locale={ locale || l }
      messages={ messages }
      defaultTranslationValues={ Config.i18n.html }
      timeZone={ Config.i18n.timeZone }
    >
      {children}
    </NextIntlClientProvider>
  );
}