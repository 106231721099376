/**
*
*   Store Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import Storefront from '@/libraries/shopify/class';
import useShopifyAnalytics from '@/libraries/shopify/analytics';
import { AnalyticTools } from '@/libraries/analytics';
import { useLocale, useTranslations } from 'next-intl';
import { useState , useEffect } from 'react';

//Create the Cart Context
export default function GuestCart(){

	//The Cart ID
	const id = typeof window != 'undefined' ? ( localStorage.getItem('cartid') || null ) : null ;

	//The Shopify Analytics Hook
	const ShopifyAnalytics = useShopifyAnalytics();

	//Get the Locale
	const locale = useLocale();

	//The Store Data
	const [cart, setCart] = useState({

		//The Cart ID
		id: null,

		//Cart Loading State
		loading: true,

		//Free Items
		free: {
			shipping: parseInt( process.env.NEXT_PUBLIC_FREE_SHIPPING ),
			samples: []
		}

	});

	/**
	*
	*	Apply
	* 		- Applies the Changes done in Cart
	*
	*
	**/
	const Apply = async (data) => {

		if(!data) return false;

		//Store the Cart ID
		localStorage.setItem( 'cartid' , data.id );

		//Prepare and Remove any samples based on eligibility
		const samples = cart.free.samples;

		for( var i=0; i < samples.length; i++ ){

			const product = samples[i];

			//Get the Sample Config
			const sample = Config.samples.find( gift => product.id === gift.id );

			//Check if it's already added
			const filtered = data.lines.filter( row => row.product.variant.id == product.samples?.[0].id );

			//Remove any samples that don't meet eligibility
			if( filtered.length > 0 && sample.requirement > data.cost.subtotalAmount.amount )
				data = ( await Storefront.Cart.remove( cart.id, filtered[0].id ) )?.body;

			//Update the Sample
			samples[i] = {
				...product,
				...sample,
				added: data?.lines?.filter( row => row.product.variant.id == product.samples?.[0].id ).length > 0
			}

		}

		//Update the Cart Data
		setCart({
			...cart,
			...data,
			loading: false,
			free: {
				...cart.free,
				samples: samples
			}
		});

        //Klaviyo - Track the Cart Event
        ( window._learnq || [] ).push(['track', 'Added to Cart', {
        	...cart,
        	...data,
        	free: {
        		...cart.free,
        		samples: samples
        	}
        }]);

		//Return
		return data;
	}



	//The Cart Methods
	const Cart = {

		//The Cart data
		data: cart,

        //Format the value into money
        money: (price) => '$' + Storefront.money( price.toString().replace(/[^0-9.]/,'') ),

        //Get the Size of the Cart
        size: () => ( cart.lines || [] ).reduce((sum, line) => sum + line.quantity, 0),

        //Is the Cart Loading
        loading: cart.loading,

		//Get the Cart Data
		get: (callback) => {
			try {
				return callback(cart)
			} catch(e) {
				return false;
			}
		},

		//Add to Cart
		add: async (product, quantity) => {

			//Start Loading
			setCart({
				...cart,
				loading: true
			});

			//The Data to insert
			let insert = [{
				quantity: quantity,
				merchandiseId: product.variant.id
			}];

			//Create / Add to / the Cart
			let data = (
				!cart.id ?
					//Create Cart
					await ( await fetch( '/api/cart/guest' , {
	                    method: 'PUT',
	                    body:  	JSON.stringify({
	                    	data: insert
	                    })
	                }) ).json()
				:
					//Add to Cart
					await ( await fetch( '/api/cart/guest' , {
	                    method: 'POST',
	                    body:  	JSON.stringify({
	                    	id: 	cart.id,
	                    	data: 	insert
	                    })
	                }) ).json()
			);

			//
			AnalyticTools.addToCart( product , quantity );

			//Add to Shopify Analytics
			ShopifyAnalytics.addToCart({
                locale:     locale,
                payload: 	{
                	cartId: 		data.id,
                	totalValue: 	( product.variant.price.amount * quantity ),
                	products:  		[{
	                    productGid:     product.id,
	                    name:           product.title,
	                    brand:          product.vendor,
	                    price:          product.variant.price.amount,
	                    variant:        product.variant.id,
	                    variantName:    product.variant.title,
	                    category:       product.productType,
	                    sku:            product.variant.sku
                	}]
                }
            });

			//Return
			return Apply( data );

		},


		//Update Items in the Cart
		update: async ( line , quantity ) => {

			//Start Loading
			setCart({
				...cart,
				loading: true
			});

			//Update
			let data = ( 
				await Storefront.Cart.update(
					cart.id,
					[{
						quantity: parseInt( quantity ),
						id: line.id
					}]
				)
			)?.body;

			//Apply the Changes
			return Apply(data);

		},


		//Remove Line Item from the Cart
		remove: async (line) => {

			//Start Loading
			setCart({
				...cart,
				loading: true
			});

			//Remove
			let data = ( 
				await Storefront.Cart.remove(
					cart.id,
					line.id
				)
			)?.body

			//Apply the Changes
			return Apply( data );

		}

	};


	useEffect(()=>{
		//If we have a cart
		if( id ){

			//The Cart Data
			( async function(){

				//Get the Cart Data
				let data = ( id.indexOf('gid://') > -1 ? ( await Storefront.Cart.get(id) )?.body : {} );

				//Get the Free Samples
				const samples = ( await Storefront.Products.get( Config.samples.map( gift => gift.id) ) )?.body;

				//Loop through the samples
				for( var i=0; i < samples.length; i++ ){

					const product = samples[i];

					//Get the Sample Config
					const sample = Config.samples.find( gift => product.id === gift.id );

					//Check if it's already added
					const filtered = data?.lines?.filter( row => row.product.variant.id == product.samples?.[0].id );

					//Remove any samples that don't meet eligibility
					if( ( filtered && filtered.length > 0 ) && sample.requirement > data.cost.subtotalAmount.amount )
						data = ( await Storefront.Cart.remove( data.id , filtered[0].id ) )?.body;

					//Update the Sample
					samples[i] = {
						...product,
						...sample,
						added: data?.lines?.filter( row => row.product.variant.id == product.samples?.[0].id ).length > 0
					}

				}

				//Update the Cart Data
				setCart({
					...cart,
					...data,
					loading: false,
					free: {
						...cart.free,
						samples: samples
					}
				});

			})();

		}else{

			setCart({
				...cart,
				loading: false
			});

		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);


	return Cart;

}
