/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import Preloader from '@/components/preloader';
import Image from '@/components/image';
import { CustomerContext } from '@/context/customer';
import { useContext } from 'react';


//Build the Element
export default function AccountSidebar({ width=50 , height=50 }){

    //
    const { Customer } = useContext(CustomerContext);

    //The Loading Array
    const Loading = (
        <div className="profile-loader" style={{ width: width + 'px', height: height + 'px' }}>
            <Preloader status={ true } mini />
        </div>
    )

    //Show Preloader
    if( Customer.loading ) return Loading;

    //If it's still loading
    if( Customer.get( customer => customer.photo?.value?.indexOf('gid://') > -1 ) ){

        //Globalize the interval so we don't have multiple running
        //We don't use useEffect so this can run behind the scenes and self cleanup if necessary
        if( typeof window !== 'undefined' && !window.photo ){

            //Run every 10 seconds
            window.photo = setInterval( async () => {

                //Request the Photo
                const photo = await (
                    await fetch( '/api/customer/photo?id=' + Customer.get( customer => customer.photo.value ) )
                ).json();

                //Something went wrong, just end it all.
                if( !photo.result ) return window.clearInterval( window.photo );

                //If we have a photo returned
                if( photo.body?.[0]?.image?.url ){

                    //Save
                    const profile = await (
                        await fetch( '/api/customer/profile', {
                            method: 'POST',
                            body:   JSON.stringify({
                                key:    'photo',
                                value:  photo.body[0].image.url
                            })
                        })
                    ).json();

                    //Set the new photo
                    Customer.set({
                        photo: profile.body?.photo
                    });

                    //Clear the Interval
                    clearInterval(window.photo);

                }

            },10000);

        }

        return Loading;
    }

    //Get Photo
    return Customer.get(
        customer => (
            <Image src={ ( customer.photo?.value || 'account/profile.svg' ) } alt="" className="profile-photo" base width={ width } height={ height } />
        )
    )

}