/**
*
*   Wholesale Library
*       - A Wholesale connector with Shopify Tools
*
**/

import Config from '@/config';
import Translation from '@/components/translation';
import ShopifyTools from '@/libraries/shopify-tools/class';

//The Recaptcha Validation Class
const Recaptcha = {

    //The TOS Form message
    message: (
        <small className="recaptcha-note">
            <Translation t="Forms.Recaptcha.message" data={{
                privacy:    (chunks) => <a href="https://policies.google.com/privacy" target="_blank">{ chunks }</a>,
                terms:      (chunks) => <a href="https://policies.google.com/terms" target="_blank">{ chunks }</a>
            }} />
        </small>
    ),

    //Validate a Request
    validate: async ( token ) => {

        //
        const response = await (
            await fetch( 'https://www.google.com/recaptcha/api/siteverify' , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body:   'secret=' + process.env.NEXT_RECAPTCHA_SECRET_KEY + '&response=' + token
            })
        ).json();

        return Boolean( response.success );

    }
}


//Output
export default Recaptcha;