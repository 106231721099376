/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { PatternFormat } from 'react-number-format';
import { CustomerContext } from '@/context/customer';
import { useContext, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


//Build the Element
export default function BirthdayModal({ Modal }){

    //Form Settings
    const { control, register, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //The submission Status
    const [ status , setStatus ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Translations
    const t = useTranslations();

    //Form Functions
    const Form = {

        //Settings
        date: {
            validate: date => /^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(date) || t('Forms.Errors.date.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => {
            setLoading(false);
            setError('root.serverError',{
                type: 500,
                message: message
            })
        },

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: async (data) => {
            Customer.get( async (customer) => {

                setLoading(true);

                if( !data.date )
                    return Form.thrown( t('Forms.Errors.required',{ type: t('Forms.Labels.date') }))

                //
                let date = data.date.split('-'),
                    bday = new Date( date[0] , date[1] - 1, date[2] ),
                    now  = new Date();

                //Set 10 Years ago
                now.setYear( now.getFullYear() - 10 );

                //
                if( now < bday )
                    return Form.thrown( t('Components.Account.birthday.required') );

                //Send the Referral
                const response = await ShopifyTools.put( 'rewards' , 'birthday' , {
                    customerid: ShopifyTools.id( customer.id ),
                    date:       data.date
                });

                if( !response )
                    return Form.thrown( t('General.offline') );

                if( !response.result )
                    return Form.thrown( response.message );

                //Run the callback, if exists
                Modal.get( modal => modal.callback() );

                //Clear Loading
                setLoading(false);

            })
        }
    }

    //Return
    return (
        <>
            <section>
                <header>
                    <h2>
                        { t.rich('Components.Account.birthday.title') }
                    </h2>
                </header>
                <p>{ t('Components.Account.birthday.details') }</p>
                <form className="form" onSubmit={ handleSubmit(Form.submit) }>

                    {/* Submission Response Error */}
                    { errors.root?.serverError?.type > 200 && (
                        <div className="failed">{
                            errors.root.serverError.message
                        }</div>
                    ) }

                    {/* Submission Success */}
                    { status && (
                        <div className="success">{
                            t('Components.Account.birthday.success')
                        }</div>
                    )}

                    <div className="row">
                        <Controller control={control} name="date" render={({ field: { onChange, name, value } }) => (
                            <PatternFormat format="####-##-##" name={name} value={value} onChange={onChange} placeholder={ t('Forms.Labels.date') } />
                        )} />
                        <Button disabled={ isSubmitting || loading } type="submit" button>{
                            loading ? (<Preloader status={true} mini />) : t('Components.Account.birthday.save')
                        }</Button>
                    </div>

                    <ErrorMessage errors={errors} name="email" render={ Form.error } />
                    <p><a onClick={ () => Modal.close() }>{ t('Components.Account.birthday.close') }</a></p>
                </form>
            </section>
        </>
    );

}