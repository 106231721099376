/**
*
*   Storeobj
*       - A map of store locations
*
**/
'use client';

import { Link as NextLink } from '@/libraries/navigation';

//Build the Element
const Link = ({ children, href, onClick, ...props }) => {

    return (
        <NextLink {...props} href={ href } onClick={(e)=>{

            //Scroll smooth to internal links
            if( href && href.charAt(0) == '#' ){
                e.preventDefault();
                window.scroll({
                    top:        document.getElementById( href.replace('#','') ).offsetTop,
                    behavior:   'smooth'
                })
            }

            //Continue to next
            if( onClick ) onClick(e);

        }}>{ children }</NextLink>
    )

}

export { Link };