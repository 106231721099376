/**
*
*   Product Card
*       - The Product Card
*
**/
import Image from '@/components/image';
import CartForm from '@/components/cart/form';
import Tracker from '@/components/tracker';
import Rating from '@/components/reviews/rating';
import ProductPrice from '@/components/product/price';
import Gallery from '@/components/product/gallery';
import ProductProvider from '@/context/product';
import ReviewsProvider from '@/context/reviews';
import Storefront from '@/libraries/shopify/class';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Specifications from '@/components/product/specifications';
import { Link } from '@/libraries/navigation';


//Build the Element
export default function ProductModal({ product, callback, type = 'fiat' }){

    //Return
    return (
        <>
            <Tracker data={ product } id={ product.id } type="product" />
            <ProductProvider product={ product }>
                <ReviewsProvider name={ product.title } reference={ ShopifyTools.id( product.id ) } identifier={ 'product:' + product.handle }>
                    <div className="quickadd">
                        <Gallery product={ product } specifications={ true } />
                        <div className="details">
                            <header>
                                <div>
                                    <h2>
                                        <a href={ '/products/' + product.handle }>
                                            { product.title }
                                        </a>
                                        { product.inci && (
                                            <span className="inci">{ product.inci }</span>
                                        )}
                                    </h2>
                                    <ProductPrice />
                                </div>
                                <Rating href={ '/products/' + product.handle } identifier={ 'product:' + product.handle } />
                                <p>{ product.description.split('.')[0] + '.' }</p>
                                <Specifications product={ product } />
                            </header>
                            <CartForm callback={ callback } type={ type } />
                            <footer>
                                <div className="links">

                                    <Link href={ '/products/' + product.handle }>
                                        { 'Experience This Product' }
                                    </Link>

                                    <Link href={ '/pages/stockists' }>
                                        { 'Buy in Store' }
                                    </Link>

                                </div>
                            </footer>
                        </div>
                    </div>
                </ReviewsProvider>
            </ProductProvider>
        </>
    );

}