/**
*
*   Popup (Quick) Cart Context
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import { useContext, createContext, useState, useEffect, useRef } from 'react';

//The Cart Context
export const QuickCartContext = createContext(null);

//Create the Cart Context
export default function QuckCart({ session , children }){

	//The Quick Cart Modal
	const [modal, setModal] = useState({
		opened: 	false
	});


	//The Quick Cart Modal Settings
	const QuickCart = {

		//The Window
		list: useRef(),

		//Is the Modal Opened?
		opened: modal.opened,

		//Open the modal
		open: () => {

			//Scroll to Top
			QuickCart.list.current.scrollTop = 0;

			//Prevent overscroll on smaller screens
			document.body.classList.add('mopen');

			//Set as Opened
			setModal({
				opened: true
			});

		},

		//Close the Modal
		close: () => {
			
			//Prevent overscroll on smaller screens
			document.body.classList.remove('mopen');

			//Set as Closed
			setModal({
				opened: false
			})
		}

	}



	//Return the Provider
	return (
		<QuickCartContext.Provider value={{ QuickCart }}>
			{children}
		</QuickCartContext.Provider>
	)
}