/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import CartForm from '@/components/cart/form';
import Storefront from '@/libraries/shopify/class';
import { ModalContext } from '@/context/modal';
import { CustomerContext } from '@/context/customer';
import { Link } from '@/libraries/navigation';
import { useState, useLayoutEffect, useRef, useContext } from 'react';
import { useTranslations, useLocale, NextIntlClientProvider, useMessages } from 'next-intl';


//Build the Element
export default function ProductCard({ product, purchasable = true, type = 'fiat' }){

    //The current locale
    const locale = useLocale();

    Storefront.setLocale(locale);

    //Translations
    const t = useTranslations();

    //The Modal Context
    const { Modal } = useContext(ModalContext);

    //The Customer Context
    const { Customer } = useContext(CustomerContext);

    //The Form Data
    const Form = {

        /**
        *
        *   Form.show
        *       - Load the Modal Popup
        *
        **/
        show: (e) => {

            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            Modal.open({
                product: {
                    product:    product,
                    type:       type,
                    callback:   Form.callback
                },
                attributes: {
                    className:  'product',
                    style:      { '--image' : 'url(' + product.lifestyle?.src + ')' }
                }
            });


        },




        /**
        *
        *   Validate the Form Submission
        *       - Close the Form if successful before the QuickCart opens
        *
        **/
        callback: ( response , next ) => {
            if( response.result ){

                //Close the Modal
                Modal.close()

                //Continue
                next();

            }else{

                //An Error Occurect

            }
        }
    };

    //If we don't have a product, just return
    if( !product?.variant ) return false;

    //The total point value for the product
    const points = Math.ceil( product.variant.price.amount * Config.points );

    //Wether the product is currently eligible for sale
    const unavailable = (
        product.soldout || (
            type == 'points' && Customer.get( customer => customer?.rewards?.points || 0 ) <= points
        )
    );

    //Return
    return (
        <div className="product-card">
            <Link href={ '/products/' + product.handle }>
                {/*
                <ul className="badges">{
                    product.badges.map( (badge,i) => (
                        <li key={i} className="custom">
                            <span style={ badge.styles }>{ badge.text }</span>
                        </li>
                    ))
                }</ul>
                */}
                <div className="image">
                    <div>
                        <Image src={ product.lifestyle?.src + '&transform=cover=1:1/resize=890x890/zoom=1.2' } alt="" width="890" height="890" blurDataURL={ product.lifestyle?.src + '&transform=cover=1:1/resize=316x316/output=preview' } quality={ 100 } placeholder="blur" />
                        <Image src={ product.art?.photo?.src + '&transform=cover=1:1/resize=890x890' } alt="" width="890" height="890" blurDataURL={ product.art?.photo?.src + '&transform=cover=1:1/resize=632x632/output=preview' } quality={ 100 } placeholder="blur" className="hover" />
                    </div>
                </div>

                <div className="details">
                    <div className="top">
                        <div className="header">
                            <h2 className="name">{ product.title }</h2>
                            { purchasable && (
                                <div className="from">
                                    { type == 'points' && (
                                        t.rich('Components.Cart.from.points',{
                                            points: () => (
                                                <span className="price">
                                                    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                                                <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                                                    <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                                                    <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <span className="current">{ points }</span>
                                                </span>
                                            )
                                        })
                                    )}
                                    { type == 'fiat' && (
                                        t.rich('Components.Cart.from.fiat',{
                                            price: () => (
                                                <span className="price">
                                                    { parseFloat( product.variant.compareAtPrice?.amount ) > 0 ? (<strike className="was">${ Storefront.money( product.variant.compareAtPrice.amount ) }</strike>) : null }
                                                    <span className="current">{ '$' + Storefront.money( product.variant.price.amount ) }</span>
                                                </span>
                                            )
                                        })
                                    )}
                                </div>
                            )}
                        </div>
                        { product?.inci && (
                            <p className="inci">{ product.productType } | { product.inci }</p>
                        )}
                        { !product?.inci && (
                            <p className="inci">{ product.productType }</p>
                        )}
                        <p className="caption">{ product.caption }</p>
                        <div className="profile">
                            <span className="scent">{ product.profile?.[ locale ] }</span>
                            <span className="benefits">{ product.benefits?.[ locale ] }</span>
                        </div>
                    </div>
                    <div className="hover">
                        { product?.inci && (
                            <p className="inci">{ product.productType } | { product.inci }</p>
                        )}
                        { !product?.inci && (
                            <p className="inci">{ product.productType }</p>
                        )}
                        <h3 className="headline">{ product.art?.headline?.[ locale ].replace(/(<([^>]+)>)/gi,'') }</h3>
                        <div className="profile">
                            <span className="benefits">{ product.benefits?.[ locale ] }</span>
                            <span className="scent">{ product.profile?.[ locale ] }</span>
                        </div>
                    </div>
                    <div className="buttons">
                        {/*
                        <div className="price">
                            { product.variants.length > 0 ? (
                                <span className="from">from </span>
                            ) : null }
                            { product.variant.compareAtPrice ? (
                                <strike className="was">${ product.variant.compareAtPrice.amount }</strike>
                            ) : null }
                            <span className="current">
                                ${ product.variant.price.amount }
                            </span>
                            <small> ({ product.variant.options[ product.variant.options.length - 1 ] })</small>
                        </div>
                        */}
                        { purchasable && (
                            <Button onClick={(e)=>Form.show(e)} disabled={ unavailable } type="white" size="small" button inverted>
                                { product.soldout && (
                                    <>{ t('Components.Cart.sold-out') }</>
                                )}
                                { type == 'points' && !product.soldout && (
                                    t.rich('Components.Cart.button',{
                                        price: () => (
                                            <span className="price">
                                                <svg className="points" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                                            <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                                                <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                                                <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                { t.rich('General.points',{ points: points }) }
                                            </span>
                                        )
                                    })
                                )}
                                { type == 'fiat' && !product.soldout && (
                                    t.rich('Components.Cart.button',{
                                        price: () => (
                                            <span className="price">
                                                { parseFloat( product.variant.compareAtPrice?.amount ) > 0 ? (<strike className="was">${ Storefront.money( product.variant.compareAtPrice.amount ) }</strike>) : null }
                                                <span className="current">{ '$' + Storefront.money( product.variant.price.amount ) }</span>
                                            </span>
                                        )
                                    })
                                )}
                            </Button>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );

}