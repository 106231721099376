/**
*
*   Confirm Modal
*       - The Confirm Modal
*
**/
'use client';

import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { PatternFormat } from 'react-number-format';
import { CustomerContext } from '@/context/customer';
import { useContext, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


//Build the Element
export default function ConfirmModal({ Modal }){

    //Translations
    const t = useTranslations();

    //Return
    return Modal.get( modal => (
        <section className="confirm">
            <header>
                <h2>
                    { modal.confirm.title }
                </h2>
            </header>
            <p>{ modal.confirm.content }</p>
            <div className="buttons">
                <Button onClick={ () => Modal.close() } inverted>{ t('Components.Modal.cancel') }</Button>
                <Button onClick={ () => modal.callback() }>{ t('Components.Modal.continue') }</Button>
            </div>
        </section>
    ));

}