/**
*
*   Next Intl: Navigation
*       - Override the Next-intl Navigation
*
**/
import Config from '@/config';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';


//Export the Overriden Links
export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation( Config.i18n.locales );