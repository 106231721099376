/**
*
*   Storeobj
*       - A map of store locations
*
**/
'use client';

import Link from '@/components/link';
import Button from '@/components/button';
import { signOut } from 'next-auth/react';
import { useState } from 'react';
import { useTranslations } from 'next-intl';

//Build the Element
const Logout = ({ children, button, className='', ...props }) => {

    const t = useTranslations();
    const [ state, setState ] = useState(false);

    //add the class
    className = ( className + ' logout' ).trim();

    //Button
    if(button) return (
        <Button onClick={()=>{ setState(true); signOut(); }} className={ className } disabled={ state } {...props}>{
            children || t('Navigation.logout')
        }</Button>
    );

    //Text Link
    return (
        <a onClick={()=>{ setState(true); signOut(); }} className={ className } disabled={ state } {...props}>{
            children || t('Navigation.logout')
        }</a>
    );

}

export { Logout };