/**
*
*   Subscribe
*       - The Subscribe Popup (Klaviyo Integration)
*
**/
'use client';


import Config from '@/config';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import { AnalyticTools } from '@/libraries/analytics';
import { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { usePathname } from 'next/navigation'
import { useTranslations, useLocale } from 'next-intl';
import { ErrorMessage } from '@hookform/error-message';
import { useReCaptcha } from 'next-recaptcha-v3';
import moment from 'moment';

//Build the Element
export default function Subscribe({ children, ...props }){

    //Get the Translations
    const t = useTranslations();

    //Get the Locale
    const locale = useLocale();

    //Get the Path name
    const pathname = usePathname();

    //Available to interaction
    const [ active , setActive ] = useState(false);

    //Open State
    const [ open , setOpen ] = useState(true);

    //Loading State
    const [ loading , setLoading ] = useState(false);

    //Completed State
    const [ completed , setCompleted ] = useState(false);

    //Exists State
    const [ exists , setExists ] = useState(false);

    //The Classes
    const classes = [ ( open ? 'open' : '' ) , ( !active ? 'hidden' : '' ) , ( completed || exists ? 'completed' : '' ) ];

    //Form Settings
    const { control, register, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //Recaptcha
    const { executeRecaptcha } = useReCaptcha();

    //Disabled Pages
    const hidden = [
        '/account/wholesale'
    ];

    //The Form Code for Klaviyo
    const form = (()=>{

        if( props.business ) return {
            id:         'RMLKvJ',
            type:       'business',
            storage:    'subscribe.business'
        }

        //Default Retail Form
        return {
            id:         'YcCukq',
            type:       'retail',
            storage:    'subscribe.retail'
        }

    })();

    //The Newsletter Form
    const Form = {

        //The Tracker Data
        data: ( typeof window != 'undefined' ? ( JSON.parse( localStorage.getItem( form.storage ) ) || {} ) : {} ),

        //Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('email',{
            type: 500,
            message: t('Forms.Errors.unknown') + ' ' + message
        }),

        //On Close
        close: () => {

            //Set Open to False
            setOpen(false);

            //Set
            localStorage.setItem(
                form.storage,
                JSON.stringify({
                    active:     true,
                    date:       new Date(),
                    completed:  false
                })
            );
        },

        //On Submit
        submit: async data => {

            //Show Loader
            setLoading(true);

            //Get Recaptcha
            const token = await executeRecaptcha('klaviyo/signup');

            //Submit
            const response = (
                await (
                    await fetch( '/api/klaviyo' , {
                        method: 'POST',
                        body:   JSON.stringify({
                            email:  data.email,
                            list:   form.id,
                            coupon: ( form.type == 'retail' ),
                            token:  token
                        })
                    })
                ).json()
            );

            //
            if( !response.result ){
                if( response.code == 'bad-request' ){

                    //Bad Request
                    setLoading(false);
                    return setError('email',{
                        type: 500,
                        message: response.error
                    });

                }else{

                    //Output Exists
                    setLoading( false );
                    setExists( true )

                }
            }else{

                //Output Success
                setLoading( false );
                setCompleted( true );

            }

            //If it's retail add the coupon
            //if( form.type == 'retail' )
                //setCode( response.body.codes[0].code );

            //Send Analytics
            AnalyticTools.track(
                { email: data.email },
                () => AnalyticTools.newsletter( form.type )
            );

            //Identify user with Klaviyo
            klaviyo.identify({
                domain:     Config.domain,
                country:    Config.country,
                currency:   Config.currency,
                locale:     locale,
                email:      data.email
            },()=>{
                //Do Nothing
            });

            //Set Completed
            localStorage.setItem(
                form.storage,
                JSON.stringify({
                    active:     true,
                    completed:  true
                })
            );

        }

    };


    useEffect(()=>{

        //Popup Hide State
        if( Form.data?.completed || hidden.includes( pathname ) ) return;
        if( Form.data?.date && new Date() <= moment( Form.data?.date ).add(5 , 'days') ) {
            setActive(true);
            setOpen(false);
        }

        //
        const Conditional = () => {

            //Document Height
            const height = Math.max( document.body.scrollHeight, document.body.offsetHeight , document.documentElement.scrollHeight , document.documentElement.offsetHeight );

            //Scrolled Distance
            const scrolled = ( window.scrollY + window.innerHeight );

            //Get the Difference
            const percentage = 100 - ( ( ( document.body.scrollHeight - scrolled ) / document.body.scrollHeight ) * 100 );

            //Show the window
            if( percentage > 30 && !active )
                setActive(true);

        }

        //On scroll, validate
        window.addEventListener('scroll',Conditional);

        //Clear the listener
        return () => window.removeEventListener('scroll', Conditional);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //Return
    return (
        <section id="subscribe"  className={ classes.join(' ') }>
            { !completed && (
                <Button className="offer" onClick={ () => setOpen(true) }>Get 15% Off</Button>
            )}
            <form onSubmit={ handleSubmit(Form.submit) }>

                <a onClick={()=>Form.close()} className="close">
                    <svg role="img" width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <title id="title-Close dialog">Close dialog</title>
                        <circle cx="10" cy="10" r="9.5" fill="currentColor" stroke="currentColor"></circle>
                        <path d="M6 6L14 14M6 14L14 6L6 14Z" stroke="rgba(245,247,248,1)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </a>

                { !completed && !exists && (
                    <>
                        <header>
                            <h3>
                                <small>{ t(`Components.Subscribe.${ form.type }.header.line1`) }</small>
                                <span>{ t(`Components.Subscribe.${ form.type }.header.line2`) }</span>
                                <small>{ t(`Components.Subscribe.${ form.type }.header.line3`) }</small>
                            </h3>
                            <p>{ t(`Components.Subscribe.${ form.type }.text`) }</p>
                        </header>
                        <input type="text" placeholder={ t('Forms.Labels.email') } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                        <ErrorMessage errors={errors} name="email" render={ Form.error } />
                        <Button type="submit" disabled={ loading } button>{(
                            loading ? (<Preloader state={true} mini />) : t(`Components.Subscribe.${ form.type }.submit`)
                        )}</Button>
                    </>
                )}

                { exists && (
                    <>
                        <header>
                            <h3>{ t(`Footer.Newsletter.${ form.type }.exists.title`) }</h3>
                            <p>{ t.rich(`Footer.Newsletter.${ form.type }.exists.content`) }</p>
                        </header>
                    </>
                )}

                { completed && (
                    <>
                        <header>
                            <h3>{ t(`Footer.Newsletter.${ form.type }.success.title`) }</h3>
                            <p>{ t(`Footer.Newsletter.${ form.type }.success.content`) }</p>
                        </header>
                    </>
                )}

            </form>
        </section>
    );

}