/**
*
*   Store Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import { createContext, useState, useRef } from 'react';

//The Cart Context
export const ModalContext = createContext(null);

//Create the Cart Context
function ModalProvider({ children }){

	//The Ref
	const ref = useRef();

    //The Modal State
    const [modal, setModal] = useState({
        success:    false,
        opened:     false,
        content:    null,
        settings:   {}
    });

    //The Modal Settings
    const Modal = {

        /**
        *
        *   show
        *       - Show the Form Modal
        *
        **/
        open: ({ attributes, ...data }) => {

            //Prevent overscroll on smaller screens
            document.body.classList.add('mopen');

            setModal({
                ...data,
                opened:     true,
                attributes: attributes
            });

        },


        /**
        *
        *   close
        *       - Close the Modal
        *
        **/
        close: () => {
            
            //Prevent overscroll on smaller screens
            document.body.classList.remove('mopen');

            setModal({
                success:    false,
                opened:     false,
                attributes: {}
            });
            
        },


        /**
        *
        *   close
        *       - Close the Modal
        *
        **/
        success: () => {

            setModal({
                ...modal,
                opened: true,
                success: true
            });

            window.setTimeout( () => Modal.close() , 1500 );
        },


        /**
        *
        *   state
        *       - Is the Modal Opened (true) or Closed (false)
        *
        **/
        state: () => modal.opened,


        /**
        *
        *   get
        *       - Get the Modal values
        *
        **/
		get: (callback) => {
			try {
				return callback(modal)
			} catch(e) {
				return false;
			}
		},

    }

	//Return the Provider
	return (
		<ModalContext.Provider value={{ Modal }}>
			{children}
		</ModalContext.Provider>
	)
}

export default ModalProvider;