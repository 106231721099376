/**
*
*   Session Provider
*       - Next Auth Session Provider
*
**/
'use client'

import { SessionProvider } from 'next-auth/react';

export default SessionProvider;