import qs from 'qs';


/**
*
*   A ShopifyTools Server Warpper
*       -
*
**/
const ShopifyTools = ( () => {

    /**
    *
    *   ShopifyTools Container Class
    *
    *
    **/
    const sdk = {

        //The API Domain
        api:        process.env.NEXT_PUBLIC_SHOPIFY_TOOLS_DOMAIN,

        //The Shop Domain
        shop:       process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,


        /**
        *
        *   ajax
        *       - Send an AJAX Request
        *
        *   Params:
        *       - app:      (String) The App to Load
        *       - method:   (String) get, post, put or delete
        *       - command:  (String) The Command to Call
        *       - payload:  (Object) The Object to Pass
        *       - callback: (function) The Callback Functio
        *
        **/
        ajax: async function( method , app , command , payload , callback ){

            //The Request URL
            let url = new URL(
                ( app ? sdk.api + '/' + app + '/send' : sdk.api + '/' + command ) + '?cache=' + new Date().getTime()
            );

            //Params
            const params = [];

            //SDK Connection Headers
            const headers = {
                'X-Shopify-Shop-Domain':    sdk.shop,
                'Content-Type':             'application/json'
            };

            //Prepare the data
            const data = await ( () => {

                //If no app, return base payload
                if( !app ) return payload;

                //Return app paylod
                return {
                    'command': command,
                    'payload': payload
                };

            } )();

            //Prepare the GET parameters
            if( method == 'get' )
                url += '&' + qs.stringify( data , { encode: false } );

            try {

                //Fetch
                const response = await fetch( url , {
                    method:     method.toUpperCase(),
                    headers:    headers,
                    next:       { revalidate: 30 },
                    body:       ( method == 'get' ? null : JSON.stringify( data ) )
                });
                
                //The Text
                const text = await response.text();

                //Format
                const formatted = JSON.parse( text );

                //Try to return thejson
                return formatted;

            } catch(e) {

                console.warn( 'ShopifyTools (' + ( app ? app + '/' : '' ) + command + ') failed:' , "\n" , e );

                //Server might be offline
                return null;

            }

        }
    }

    return {

        //Get the ID
        id: (id) => id.match(/([^\/]*)\/*$/)[1].split('?')[0],

        /**
        *
        *   requests (get, put, post, delete)
        *       - Send a Request to the AJAX function
        *
        *   Params:
        *       - app:      (String) The App to Load
        *       - method:   (String) get, post, put or delete
        *       - command:  (String) The Command to Call
        *       - payload:  (Object) The Object to Pass
        *       - callback: (function) The Callback Function
        *
        *
        **/
        get:    async ( app , command , payload ) => await sdk.ajax( 'get' , app , command , payload ),
        put:    async ( app , command , payload ) => await sdk.ajax( 'put' , app , command , payload ),
        post:   async ( app , command , payload ) => await sdk.ajax( 'post' , app , command , payload ),
        delete: async ( app , command , payload ) => await sdk.ajax( 'delete' , app , command , payload )

    }

})();


//Export
export default ShopifyTools;