/**
*
*   Account Profile
*       - The Account Profile Popup Editor
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ProfilePhoto from '@/components/account/photo';
import Uploader from '@/libraries/uploader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import {useDropzone} from 'react-dropzone'
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PatternFormat } from 'react-number-format';


//Build the Element
export default function AccountModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //Success
    const [ success , setSuccess ] = useState(false);

    //Uploading
    const [ uploading , setUploading ] = useState(0);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Dropzone
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg', '.webp']
        },
        disabled:       uploading,
        maxSize:        20000000, //20MB
        maxFiles:       1,
        onDrop:         async files => {

            //Show it as uploading
            setUploading(true);

            //Run
            const upload = Uploader();

            //On Progress
            upload.onProgress(e => console.log(e) )

            //Await the response
            const response = await upload.start( files );

            //If there was an error
            if(!response.result){
                Form.thrown(
                    response.errors.map( ( row , i ) => (<p key={i}>{ row.message }</p>)
                ));
                return setUploading(false);
            }

            if(!response.body[0].result){
                Form.thrown(
                    response.body[0].errors.map( ( row, i ) => (<p key={i}>{ row.message }</p>)
                ));
                return setUploading(false);
            }

            //Save
            const profile = await (
                await fetch( '/api/customer/profile', {
                    method: 'POST',
                    body:   JSON.stringify({
                        key:    'photo',
                        value:  response.body[0].id
                    })
                })
            ).json();

            //Set the new photo
            Customer.set({
                photo: profile.body?.photo
            });

            //Clear Loading after 500ms delay (to let photo load)
            window.setTimeout( () => setUploading(false) , 500 );

        }
    });

    //Form Functions
    const Form = {

        //Email Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Password Settings
        password: {
            validate: value => ( watch('password') == value ) || t('Forms.Errors.password.confirm')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError( 'root.serverError' ,{
            type: 500,
            message: message
        }),



        /**
        *
        *   autocomplete
        *       - Modify all form fields when choosing an address
        *
        *   Params:
        *       n/a
        *
        **/
        upload: async (file) => {

        },

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                setLoading(true);
                setSuccess(false);

                let response = await Storefront.Customer.update( session.accessToken, {
                    firstName:              data.firstName,
                    lastName:               data.lastName,
                    email:                  data.email,
                    phone:                  ( data.phone ? data.phone.replace(/[^+0-9]/g,'') : null ),
                    acceptsMarketing:       data.acceptsMarketing
                });

                //Show Error
                if( !response.result ){
                    Form.thrown(
                        response.errors.map( ( row , i ) => (<p key={i}>{ row.message }</p>)
                    ));
                    return setLoading(false);
                }

                //Set the Profile
                const profile = await (
                    await fetch( '/api/customer/profile', {
                        method: 'POST',
                        body:   JSON.stringify({
                            key:    'username',
                            value:  data.username
                        })
                    })
                ).json();

                //Set the new photo
                Customer.set({
                    ...response.body,
                    username: ( profile?.body?.username || null )
                });

                //Success
                Modal.success();

                //Clear Loading
                setLoading(false);


            });
        }

    }

    //Preset the email
    useEffect(()=>{

        //Set the Values
        Customer.get( customer => {
            setValue( 'email' , customer.email );
            setValue( 'firstName' , customer.firstName );
            setValue( 'lastName' , customer.lastName );
            setValue( 'photo' , ( customer?.photo || '' ) );
            setValue( 'phone' , customer?.phone.replace(/\+([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/g,'+$1 $2-$3-$4') );
            setValue( 'username' , ( customer?.username?.value || '' ) );
            setValue( 'acceptsMarketing' , customer?.acceptsMarketing )
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[Customer.loading]);

    //The Form Submission
    return (
        <form id="profile-form" onSubmit={ handleSubmit(Form.submit) }>

            <header>
                <h2>{ t('Components.Modal.Profile.title') }</h2>
                <p>{ t('Components.Modal.Profile.description') }</p>
            </header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }

            { success && (
                <div className="success">{
                    success
                }</div>
            )}

            <div {...getRootProps({ className: 'dropzone' + ( uploading ? ' disabled' : '' ) })}>
                <div>
                    <div>
                        <ProfilePhoto width="100" height="100" />
                    </div>
                </div>
                <div className="full">

                    {/* Photo */}
                    <div>
                        <Preloader status={ uploading } overlay />
                        <input {...getInputProps()} />
                        <h2>{ 'change avatar' }</h2>
                        <p className="text">{ t('Forms.Labels.dropzone') }</p>
                    </div>

                </div>
            </div>

            <div className="group">
                <Preloader status={ Customer.loading } />

                {/* Name */}
                <div className="row">
                    <div>
                        <label htmlFor="firstName">{ t('Forms.Labels.firstname') }</label>
                        <input type="text" name="firstName" placeholder={ t('Forms.Labels.firstname') + '*' } {...register('firstName', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.firstname') }) })} />
                        <ErrorMessage errors={errors} name="firstName" render={ Form.error } />
                    </div>
                    <div>
                        <label htmlFor="lastName">{ t('Forms.Labels.lastname') + ' *' }</label>
                        <input type="text" name="lastName" placeholder={ t('Forms.Labels.lastname') + '*' }  {...register('lastName', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.lastname') }) })} />
                        <ErrorMessage errors={errors} name="lastName" render={ Form.error } />
                    </div>
                </div>

                {/* Email */}
                <div className="row">
                    <div>
                        <label htmlFor="email">{ t('Forms.Labels.email') }</label>
                        <input name="email" placeholder={ t('Forms.Labels.email') + '*' } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                        <ErrorMessage errors={errors} name="email" render={ Form.error } />
                    </div>
                </div>

                {/* Username */}
                <div className="row">
                    <div>
                        <label htmlFor="username">{ t('Forms.Labels.username') }</label>
                        <input name="username" placeholder={ t('Forms.Labels.username') } {...register('username')} />
                        <ErrorMessage errors={errors} name="username" render={ Form.error } />
                    </div>
                </div>

                {/* Phone */}
                <div className="row">
                    <div>
                        <label htmlFor="phone">{ t('Forms.Labels.phone') }</label>
                        <Controller control={control} name="phone" render={({ field: { onChange, name, value } }) => (
                            <PatternFormat format="+# ###-###-####" name={name} value={value} onChange={onChange} placeholder={ t('Forms.Labels.phone') } />
                        )} />
                        <ErrorMessage errors={errors} name="phone" render={ Form.error } />
                    </div>
                </div>

                {/* Consent */}
                <div className="row">
                    <div>
                        <div className="checkbox">
                            <div>
                                <input type="checkbox" id="acceptsMarketing" {...register('acceptsMarketing')} defaultChecked={ Customer.get( customer => customer.acceptsMarketing ) } />
                                <label htmlFor="acceptsMarketing"></label>
                            </div>
                            <p onClick={()=>setValue('acceptsMarketing', !getValues('acceptsMarketing') )}>{ t('Forms.Labels.consent') }</p>
                        </div>
                    </div>
                </div>

            </div>

            {/*
            <h2>{ 'Update Password' }</h2>

            {/* Submission Response Error
            { errors.root?.passwordrror?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }

            <div className="group">

                {/* Password *
                <div className="row">
                    <div>
                        <label htmlFor="email">{ t('Forms.Labels.password') }</label>
                        <input type="password" name="password" placeholder={ t('Forms.Labels.password') + '*' } {...register('password', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.password') })  })} />
                        <ErrorMessage errors={errors} name="password" render={ Form.error } />
                    </div>
                </div>
                <div className="row">
                    <div>
                        <label htmlFor="confirm">{ t('Forms.Labels.confirm') }</label>
                        <input type="password" name="confirm" placeholder={ t('Forms.Labels.confirm') + '*' } {...register('confirm', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.confirm') }), validate: Form.password.validate })} />
                        <ErrorMessage errors={errors} name="confirm" render={ Form.error } />
                    </div>
                </div>
                <div className="buttons">
                    <Button onClick={()=>Form.password()} size="small">{ t('Forms.Labels.save') }</Button>
                </div>
            </div>
            */}

            <footer>
                <Button size="small" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.save') }</Button>
                <a onClick={()=>Modal.close()}>{
                    t('Components.Modal.close')
                }</a>
            </footer>
        </form>
    );

}