/**
*
*   Store Provider
*       - Override the Next-intl Navigation
*
**/
'use client';
import Config from '@/config';
import Storefront from '@/libraries/shopify/class';
import useShopifyAnalytics from '@/libraries/shopify/analytics';
import { AnalyticTools } from '@/libraries/analytics';
import { CustomerContext } from '@/context/customer';
import { useContext, useState, useEffect, useRef } from 'react';
import { useLocale, useTranslations } from 'next-intl';

//Create the Cart Context
export default function CustomerCart(){

	//The Cart ID
	const id = typeof window != 'undefined' ? ( localStorage.getItem('draftid') || null ) : null ;

	//Get the Customer Context
	const { Customer } = useContext(CustomerContext);

	//The Shopify Analytics Hook
	const ShopifyAnalytics = useShopifyAnalytics();

	//Get the Locale
	const locale = useLocale();

	//The Store Data
	const [cart, setCart] = useState({

		//The Cart ID
		id: null,

		//Cart Loading State
		loading: true,

		//The Cart Data
		lineItems: [],

		//Free Items
		free: {
			shipping: parseInt( process.env.NEXT_PUBLIC_FREE_SHIPPING ),
			samples: []
		}

	});


	/**
	*
	*	Apply
	* 		- Applies the Changes done in Cart
	*
	*
	**/
	var obj = {



	        /**
	        *
	        *	build
	        * 		- Formats the current line item data
	        *
	        * 	Params:
	        * 		item: 	(Obj) the Line Item Object
	        *
	        **/
			build: (item) => {
				return {
					product: 	item?.product,
					variant: 	item?.variant,
					quantity: 	item.quantity,
					currency: (
						item.currency ? item.currency
						:
						( item.appliedDiscount?.title == 'Points Redemption' ? 'points' : 'fiat' )
					)
				}
			},


	        /**
	        *
	        *	apply
	        * 		- Applies the changes to the cart data
	        *
	        * 	Params:
	        * 		data: 	(Object) The Cart Data
	        * 		send: 	(Bool) Send to Analytics
	        *
	        **/
	        apply: async ( data ) => {

				if( !data || !data.result ) return false;

				//Store the Cart ID
				localStorage.setItem( 'draftid' , data.body.id );

				//Prepare and Remove any samples based on eligibility
				const samples = cart.free.samples;

				for( var i=0; i < samples.length; i++ ){

					const product = samples[i];

					//Get the Sample Config
					const sample = Config.samples.find( gift => product.id === gift.id );

					//Check if it's already added
					const filtered = data?.body?.lineItems?.filter( row => row.variant.id == product.samples?.[0].id );

					//Remove any samples that don't meet eligibility
					if( filtered.length > 0 && sample.requirement > data.subtotalPrice )
						data = await obj.remove( filtered[0] );

					//Update the Sample
					samples[i] = {
						...product,
						...sample,
						added: data?.body?.lineItems?.filter( row => row.variant.id == product.samples?.[0].id ).length > 0
					}

				}

				//Modify the total points saved based on the cart
				obj.points( data?.body?.lineItems );

				//Update the Cart Data
				//Stop the Loading
				setCart({
					...cart,
					...data.body,
					loading: false,
					free: {
						...cart.free,
						samples: samples
					}
				});

		        //Klaviyo - Track the Cart Event
		        ( window._learnq || [] ).push(['track', 'Added to Cart', {
		        	...cart,
		        	...data,
		        	free: {
		        		...cart.free,
		        		samples: samples
		        	}
		        }]);

				//Return the data
				return data;

			},




			/**
			*
			*	points
			*		- Modify the total points accrued based on the cart usage
			*
			*	Params:
			* 		lineItems: 		(Obj) The Cart Object
			*
			**/
			points: async ( lineItems = [] ) => {

				let total = 0;

				if( lineItems.length > 0 ){

					//Get the Total Points used in the cart
					total = lineItems.reduce( (total , row) => {

						//Increment the new total
						if(row.appliedDiscount?.title == 'Points Redemption')
							return total + ( row.variant.price * Config.points * row.quantity );

						//Return current total
						return total;

					},0);

				}

				//Remove the Points
				Customer.set({
					rewards: Customer.get( customer => {
						return {
							...customer.rewards,
							points: ( customer.rewards.base - total )
						}
					})
				})
			},






	        /**
	        *
	        *	remove
	        * 		- Removes the
	        *
	        * 	Params:
	        * 		data: 	(Object) The Cart Data
	        *
	        **/
			remove: async (line) => {

	        	//The Line Item submission
				const lineItems = [];

				//Prepare Data
				cart.lineItems.forEach( item => {

					//Add it if it's not the one we're deleting
					if( line.id != item.id )
						lineItems.push(
							obj.build(item)
						)

				});

	            //Send Request to Shopify
	            const result = await fetch( '/api/cart' , {
	                method:     ( lineItems.length > 0 ? 'POST' : 'DELETE' ),
	                body:       JSON.stringify({
	                	cartId: 	cart.id,
	                	lineItems: 	lineItems
	                })
	            });

	            //The Preset
	            const preset = {
	            	result: 1,
	            	body: {
	            		id: null,
				        lineItems: [],
				        subtotalPrice: 0
				   }
	            };

	            //If we have an empty array
	            if( lineItems.length == 0 ){

	            	//Reset the Points
	            	obj.points();

	            	//Return a new array
	            	return preset;

	            }

	            //Return the updated result
	            return await result.json();

			}

	}


	//The Cart Methods
	const Cart = {

		//The Cart data
		data: cart,

        //Format the value into money
        money: (price) => '$' + Storefront.money( price.toString().replace(/[^0-9.]/,'') ),

        //Get the Size of the Cart
        size: () => ( cart.lineItems || [] ).reduce((sum, line) => sum + line.quantity, 0),

        //Is the Cart Loading
        loading: cart.loading,

		//Get the Cart Data
		get: (callback) => {
			try {
				return callback((()=>{
					//Normalize the Data
					return {
						...cart,
						cost: {
							subtotalAmount: {
								amount: parseFloat( cart?.subtotalPrice?.replace(/,/g,'') )
							}
						},
						lines: cart.lineItems.map( row => {
							return {
								...row,
								redemption: ( row.appliedDiscount?.title == 'Points Redemption' ),
								product: 	{
									...row.product,
									featuredImage: row.product.featuredImage?.url,
									variant: {
										...row.variant,
										compareAtPrice: {
											amount: row.variant?.compareAtPrice
										},
										price: {
											amount: row.variant?.price
										}
									}
								}
							}
						})
					}
				})())
			} catch(e) {
				console.log(e);
				return false;
			}
		},




		/**
		*
		*	add
		*		- Add a Product to the Cart
		*
		* 	Params:
		* 		- product: 		(object) The Product Object
		* 		- quantity: 	(INT) The quantity to add
		* 		- currency: 	(String) The type of currency to use (Points, or FIAT)
		*
		**/
		add: async (product , quantity , currency='fiat') => {

			//Start the Loading
			setCart({
				...cart,
				loading: true
			})

			//The Line Items
			const lineItems = [ obj.build({
        		currency: 	currency,
        		quantity:  	quantity,
        		variant: 	{
        			id: 		product.variant.id
        		}
        	}) ];

			//Loop through and rebuild the cart
			( cart.lineItems || [] ).forEach( item => {

				//Prepare the Item
				item = obj.build( item );

				//
				if( item.variant.id != product.variant.id || item.currency != currency ){

					//Add existing products
					lineItems.push( obj.build( item ) )

				}else{

					//Increment the Quantity
					lineItems[0].quantity += item.quantity;

				}
			});

            //Send Request to Shopify
            const result = await (
            	await fetch( '/api/cart' , {
	                method:     ( cart.id ? 'POST' : 'PUT' ),
	                body:       JSON.stringify({
	                	cartId: 	cart.id,
	                	lineItems: 	lineItems
	                })
	            })
	        ).json();



			//Only add to cart if it's paid (fiat)
			if( result && result.body && currency == 'fiat' ){

				//Pass to 3rd party tools
				AnalyticTools.addToCart( product , quantity );

				//Pass to Shopify
				ShopifyAnalytics.addToCart({
	                locale:     locale,
	                payload: 	{
	                	cartId: 		result.body.id,
	                	totalValue: 	( product.variant.price.amount * quantity ),
	                	products:  		[{
		                    productGid:     product.id,
		                    name:           product.title,
		                    brand:          product.vendor,
		                    price:          product.variant.price.amount,
		                    variant:        product.variant.id,
		                    variantName:    product.variant.title,
		                    category:       product.productType,
		                    sku:            product.variant.sku
	                	}]
	                }
	            });

	        }

            //Apply the Changes
            return obj.apply( result );

		},




		/**
		*
		*	update
		*		- Update a line item in the cart
		*
		* 	Params:
		* 		- line: 		(object) The Line Item object
		* 		- quantity: 	(INT) The quantity to add
		*
		**/
		update: async ( line , quantity ) => {

			//Start the Loading
			setCart({
				...cart,
				loading: true
			});

			//The Line Item Object
			const lineItems = [];

			//Prepare Data
			cart.lineItems.forEach( item => {

				//Add Line Item
				lineItems.push((
					line.id == item.id ?

						//Overwrite line item if it matches
						obj.build({
							...line,
							quantity: parseInt( quantity )
						})
					:
						//Add the existing item
						obj.build( item )
				));

			});

            //Send Request to Shopify
            const result = await fetch( '/api/cart' , {
                method:    	'POST',
                body:       JSON.stringify({
                	cartId: 	cart.id,
                	lineItems: 	lineItems
                })
            });

            //Apply the Changes
            return obj.apply( await result.json() );

		},



		/**
		*
		*	remove
		*		- Remove a line item in the cart
		*
		* 	Params:
		* 		- line: 		(object) The Line Item object
		*
		**/
		remove: async (line) => {

        	//Start Loading
			setCart({
				...cart,
				loading: true
			})

            //Submit the data
            return obj.apply( await obj.remove( line ) );

		},



		/**
		*
		*	create
		*		- Create a new cart with all line items passed
		* 		- Note: "Add" should be used most times, this is only for building new carts
		*
		* 	Params:
		* 		- lineItems: 		(object) The Line Item object
		*
		**/
		create: async (lineItems) => {

            //Start the Loading
            setCart({
            	...cart,
            	loading: true
            });

            //Send Request to Shopify
            const result = await ( 
            	await fetch( '/api/cart' , {
	                method:     'PUT',
	                body:       JSON.stringify({
	                    cartId:     null,
	                    lineItems:  lineItems
	                })
	            })
	        ).json();

            //Apply the Changes
            return obj.apply( result );

		}




	};


	//Load the Cart on Page Load
	useEffect(()=>{
		if( id ){

			//The Cart Data
			( async function(){

				//Get the Cart Data
				let data = ( id.indexOf('gid://') > -1 ? await ( await fetch( '/api/cart?cartId=' + id ) ).json() : {} );

				//If the stored draft order was paid, remove it.
				if( data?.body?.completedAt ){
					localStorage.removeItem('draftid');
					data = {};
				}

				//Get the Free Samples
				const samples = ( await Storefront.Products.get( Config.samples.map( gift => gift.id) ) )?.body;

				//Loop through the samples
				for( var i=0; i < samples.length; i++ ){

					const product = samples[i];

					//Get the Sample Config
					const sample = Config.samples.find( gift => product.id === gift.id );

					//Check if it's already added
					const filtered = data?.body?.lineItems?.filter( row => row.variant.id == product.samples?.[0].id );

					//Remove any samples that don't meet eligibility
					if( ( filtered && filtered.length > 0 ) && sample.requirement > data.subtotalPrice )
						data = await obj.remove( filtered[0] );

					//Update the Sample
					samples[i] = {
						...product,
						...sample,
						added: data?.body?.lineItems?.filter( row => row.variant.id == product.samples?.[0].id ).length > 0
					}

				}

				//Modify the total points saved based on the cart
				obj.points( data?.body?.lineItems );

				//Update the Cart Data
				setCart({
					...cart,
					...data.body,
					loading: false,
					free: {
						...cart.free,
						samples: samples
					}
				});

			})();

		}else{

			//Stop the Loading
			setCart({
				...cart,
				loading: false
			})

		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[id]);

	//Return the Provider
	return Cart;

}
