/**
*
*   Mobile Nav
*       - On Mobile manage the slider events of the children
*
**/
'use client';



//Build the Element
export default function NavSlider({ children }){



    //Return
    return (
        <a className="control" onClick={ (e) => {
            document.querySelector('[data-mobile-menu-container]').classList.toggle('open')
            e.currentTarget.closest('[data-mobile-menu-parent]').classList.toggle('active');
        }}>
            { children }
        </a>
    );

}