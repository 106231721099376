/**
*
*   Shopify Analytics
*       - An integration into hydrogen to push analytics to shopify
*
**/
'use client';

import Config from '@/config';
import secureLocalStorage from 'react-secure-storage';
import { sendGAEvent } from '@next/third-parties/google'
import { useContext } from 'react';
import { CustomerContext } from '@/context/customer';
import { useCookies } from 'next-client-cookies';
import moment from 'moment';





/**
*
*   AnalyticTools
*       - Function to pass data to different layers
* 
*   Params
*       n/a
* 
* 
**/
const AnalyticTools = (()=>{

    //The ID
    let id = moment().unix();

    //The Tools Object
    const Tools = {


    	//Store the active customer
    	customer: null,




        /**
        *
        *   getId
        *       - Returns a unique id for the request
        *
        *   Params:
        *       n/a
        * 
        * 
        **/
        getId: () => ( id++ ),

        /**
        *
        *   slug
        *       - Create a Slug from a product name
        *
        *   Params:
        *       value: (String) The String to turn into into a slug
        * 
        * 
        **/
        slug: (value) => value.toLowerCase().replace(/ /g,'-').replace(/[-]+/g, '-').replace(/[^\w-]+/g,''),

        /**
        *
        *   sendMeta
        *       - Sends a request to the Conversion API
        *
        *   Params:
        *       data: (Object) The Custom Content Data object to send
        * 
        * 
        **/
        sendMeta: async (type, data) => {
            return await fetch( '/api/analytics/meta' , {
                method: 'POST',
                body:   JSON.stringify({
                    id: Tools.getId(),
                    type: type,
                    customer: ( typeof window != 'undefined' ? ( JSON.parse( secureLocalStorage.getItem('customer.meta') ) || {} ) : {} ),
                    agent: window.navigator.userAgent,
                    data: data
                })
            });
        }
    };

    //Interactive Object
    const Analytics = {



        /**
        *
        *  	track
        *       - Validates and Stores a user data for tracking (if not logged in)
        * 
        *   Params:
        *      	data: 		(Object) The User Data
        * 		callback: 	(Function) The Callback Function
        *
        **/
        track: async (data, callback) => {

        	//Only if the customer is not logged in
        	if( !Tools.customer ){

	        	//Get the Trackable customer
	            const customer = await (
	            	await fetch( '/api/analytics' , {
		                method: 'POST',
		                body:   JSON.stringify( data )
		            })
		        ).json();

	            //If we were successful
		        if( customer.result ){

		        	//Store the Meta Conversion API
		        	secureLocalStorage.setItem('customer.meta', JSON.stringify( customer.meta ) );

		        	//Reinitialize
		        	Analytics.init();

		        }

		    }

	        //If we have a callback function
	        if(callback) callback();

        },


        /**
        *
        *  	init
        *       - Initialized Meta
        * 
        *   Params:
        *      	data: 		(Object) The User Data
        * 		callback: 	(Function) The Callback Function
        *
        **/
        init: async (customer) => {

        	//Initialize Facebook
            fbq('init', 1922962524680385 , (()=>{

                //Try to pull from current customer
                if( customer && Object.keys( customer ).length > 0 ){
                	
                	//Save the Customer
                	Tools.customer = customer;

                	//Return Customer Data
                    return {
                        external_id: customer.id,
                        em: customer.email,
                        fn: customer.firstName,
                        ln: customer.lastName,
                        ph: customer.phone,
                        ct: customer.defaultAddress?.city,
                        st: customer.defaultAddress?.state,
                        zp: customer.defaultAddress?.zip,
                        country: customer.defaultAddress?.country
                    }

                }

                //Try to pull from a subscribe event
                let meta = ( typeof window != 'undefined' ? ( JSON.parse( secureLocalStorage.getItem('customer.meta') ) || {} ) : {} );

                //If we have meta storage, return that
                if( Object.keys( meta ).length > 0 )
                    return {
                        external_id: meta.external_id,
                        em: meta.em,
                        fn: meta.fn,
                        ln: meta.ln,
                        ph: meta.ph,
                        ct: meta.ct,
                        st: meta.st,
                        zp: meta.zp,
                        country: meta.country
                    };

            	//No Customer Data stored
            	return {};

            })() );
        },




        /**
        *
        *   pageView
        *       - Sends a Page View Event
        * 
        *   Params:
        *      	payload: 	(Object) The Analytics Payload for Shopify
        * 		locale: 	(String) The Page Locale
        *
        **/
        pageView: ( customer ) => {
        	
            //Send Google
            //sendGAEvent('event','page_view',{ value:'example' });

            //Send a Page View
        	fbq('track', 'PageView');

        },




        /**
        *
        *   viewProduct
        *       - Sends a Product View Event
        * 
        *   Params:
        *       product: (Object) The Product Object
        *
        **/
        viewProduct: async (product) => {

            //Get the ID
            const id = Tools.getId();

            //Send Google
            sendGAEvent('event','view_item',{
                currency:   Config.currency,
                value:      product.variant.price.amount,
                items:      [{
                    item_id:        product.id,
                    item_name:      product.variant.title,
                    item_brand:     product.vendor,
                    item_variant:   product.variant.title,
                    price:          product.variant.price.amount
                }]
            });

            //Send to Conversions API
            fbq('track','ViewContent',{
                content_name:       product.title + ' ' + product.variant.title,
                content_category:   product.productType,
                content_ids:        [ product.id , product.variant.id ],
                content_type:       'product',
                value:              product.variant.price.amount,
                currency:           Config.currency
            })
            /*
            Tools.sendMeta('ViewContent', {
                content_name:       product.title + ' ' + product.variant.title,
                content_category:   product.productType,
                content_ids:        [ product.id , product.variant.id ],
                content_type:       'product',
                value:              product.variant.price.amount,
                currency:           Config.currency
            });
            */

        },




        /**
        *
        *   addToCart
        *       - Sends an Add To Cart Event
        * 
        *   Params:
        *       product: (Object) The Product Object
        *
        **/
        addToCart: (product, quantity) => {

            //Send Google
            sendGAEvent('event','add_to_cart',{
                currency:   Config.currency,
                value:      ( product.variant.price.amount * quantity ),
                items:      [{
                    item_id:        product.id,
                    item_name:      product.variant.title,
                    item_brand:     product.vendor,
                    item_variant:   product.variant.title,
                    price:          product.variant.price.amount
                }]
            });

            //Send to Conversions API
            fbq('track','AddToCart',{
                content_name:       product.title + ' ' + product.variant.title,
                content_category:   product.productType,
                content_ids:        [ product.id , product.variant.id ],
                content_type:       'product',
                value:              ( product.variant.price.amount * quantity ),
                currency:           Config.currency
            });
            /*
            Tools.sendMeta('AddToCart', {
                content_name:       product.title + ' ' + product.variant.title,
                content_category:   product.productType,
                content_ids:        [ product.id , product.variant.id ],
                content_type:       'product',
                value:              ( product.variant.price.amount * quantity ),
                currency:           Config.currency
            });
            */

        },




        /**
        *
        *   newsletter
        *       - Sends a Newsletter Subscribe Event
        * 
        *   Params:
        *       type: (String) The Newsletter Type
        *
        **/
        newsletter: (type) => {

            //Send Google
            sendGAEvent('event','sign_up',{
                type: 		'newsletter',
                category: 	type
            });

            //Send to Conversions API
            fbq('track','subscribe',{
                content_type:       'newsletter',
                value:              0,
                currency:           Config.currency
            });
            /*
            Tools.sendMeta('subscribe',{
                content_type:       'newsletter',
                value:              0,
                currency:           Config.currency
            });
            */

        },




        /**
        *
        *   inquiry
        *       - Sends a Lead Event
        * 
        *   Params:
        *       product: (Object) The Event Type
        *
        **/
        inquiry: (value, type) => {

            //Send Google
            sendGAEvent('event','generate_lead',{
            	currency: 		Config.currency,
            	value:  		value,
            	category:  		type,
            	lead_source:  	'Website',
            });

            //Send to Conversions API
            fbq('track','SubmitApplication',{
                value:              value,
                currency:           Config.currency,
                content_name:       type,
                content_category:   'service',
                content_ids:        [ Tools.slug( type ) ],
                content_type:       'product'
            });
            /*
            Tools.sendMeta('SubmitApplication',{
                value:              value,
                currency:           Config.currency,
                content_name:       type,
                content_category:   'service',
                content_ids:        [ Tools.slug( type ) ],
                content_type:       'product'
            });
            */

        },



    }

    //Return the Application functions
    return Analytics;

})();


//Export the cookies
export { AnalyticTools };