/**
*
*   Add To Cart (Button)
*       - Setup & Translations for the Add to Cart Button
*
**/
import Config from '@/config';
import Button from '@/components/button';
import Storefront from '@/libraries/shopify/class';
import { useTranslations } from 'next-intl';

//Build the Element
export default function AddToCart({ variant , type = 'fiat' , ...props }){

    //Translations
    const t = useTranslations();


    return (
        <Button {...props} type="submit">
            { variant.soldout && (
                <>{ t('Components.Cart.sold-out') }</>
            )}
            { type == 'points' && !variant.soldout && (
                t.rich('Components.Cart.button',{
                    price: () => (
                        <span className="price">
                            <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                        <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                            <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                            <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            { t.rich('General.points',{ points: variant.price.amount * Config.points }) }
                        </span>
                    )
                })
            )}
            { type == 'fiat' && !variant.soldout && (
                t.rich('Components.Cart.button',{
                    price: () => (
                        <span className="price">
                            { variant.compareAtPrice?.amount > 0 ? (<strike className="was">${ variant.compareAtPrice.amount }</strike>) : null }
                            <span className="current">{ '$' + Storefront.money( variant.price.amount ) }</span>
                        </span>
                    )
                })
            )}
        </Button>
    )

}