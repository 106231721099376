/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import { ChangeEvent, ReactNode, useTransition } from 'react';
import { useRouter, usePathname } from '@/libraries/navigation';
import { useLocale } from "next-intl";


//Build the Element
export default function SearchButton({ children, ...props }){

    const open = (e) => {
        document.getElementById('search').classList.add('open');
        document.body.classList.add('search-open');
    }

    //Return
    return (
        <div {...props}>
            <a onClick={ open } className="search" data-search>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon-search" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <circle cx="7.5" cy="7" r="6.25" stroke="currentColor" strokeWidth="1.5"/>
                    <line x1="12.5607" y1="12" x2="17.5" y2="16.9393" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
            </a>
        </div>
    );

}