/**
*
*   Search
*       - The Search Bar Class
*
**/
'use client';
import Config from '@/config';
import Button from '@/components/button';
import SearchResults from '@/components/search/results';
import { useTranslations, NextIntlClientProvider } from 'next-intl';
import { useRef, useState, useLayoutEffect } from 'react';

//Locales
const locales = Config.i18n.locales;

//Build the Root Layout
export default function SearchBar(){

    //Get the Translations
    const   t = useTranslations();

    //Search Results
    const [ search, setSearch ] = useState({
        loading:    false,
        query:      null
    });

    //The Search element
    const $el = useRef();

    //The Query Input Field
    const $input = useRef();

    //The Search Controller
    const Controller = {

        //
        intval: false,


        /**
        *
        *   close
        *       - Closes the Search Bar
        *
        *   Params:
        *       n/a
        *
        **/
        close: (e) => {

            //Open the area
            $el.current.classList.remove('open');

            //Remove
            document.body.classList.remove('search-open');

            //Focus on the element
            $input.current.focus();

        },

        /**
        *
        *   execute
        *       - Runs the Search
        *
        *   Params
        *       n/a
        *
        **/
        execute: async () => {

            //Skip if it's already run
            if(search.loading)
                return false;

            //Set as Loading
            setSearch({
                ...search,
                loading: true
            });

            //Set the Timeout
            window.setTimeout(()=>{

                //Set as Loading
                setSearch({
                    ...search,
                    loading:  false,
                    query:    $input.current.value
                });

            },500);

        }

    };

    return (
        <section ref={$el} id="search">
            <header>
                <div className="container">
                    <a onClick={ Controller.close } className="close">
                        <span>
                            <span></span>
                            <span></span>
                        </span>
                        close
                    </a>
                </div>
            </header>
            <div id="search-content" className="container">

                <form action="/search">

                    <input type="text" ref={ $input } onKeyUp={Controller.execute} placeholder={ t('Pages.Search.Form.placeholder')} />

                    <button disabled={search.loading} onClick={Controller.execute}>
                        <svg version="1.1" className="icon-search" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 34 33.9" style={{ enableBackground: 'new 0 0 34 33.9' }} xmlSpace="preserve">
                            <circle cx="13.7" cy="13.8" r="12.6"/>
                            <line x1="23" y1="22.9" x2="32.9" y2="32.7"/>
                        </svg>
                    </button>

                </form>

                <div id="search-bar-results">
                    <SearchResults search={ search } />
                </div>

            </div>
        </section>
    );

}