/**
*
*   Account Password
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import Preloader from '@/components/preloader';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';


//Build the Element
export default function IngredientsModal({ Modal }){

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();


    //The Form Submission
    return Modal.get( modal => (
        <div className="ingredients">
            <header>
                <h2>{
                    t.rich('Components.Modal.Ingredients.title',{
                        name: (<em>{ modal.ingredients.product.title }</em>)
                    })
                }</h2>
            </header>
            <div dangerouslySetInnerHTML={{
                __html: modal.ingredients.product.ingredients
            }} />
            <footer>
                <Button onClick={()=>Modal.close()} size="small" inverted button>{ t('Components.Modal.close') }</Button>
            </footer>
        </div>
    ));

}