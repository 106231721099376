/**
*
*   Points
*       - Get the current customer points
*
**/
'use client';

//
import Config from '@/config';
import { useContext } from 'react';
import { CustomerContext } from '@/context/customer';


//Build the Element
export default function Points({ wrap }){

    //The Customer Context
    const { Customer } = useContext(CustomerContext);

    //Return
    return Customer.get( customer => {
        if( !Customer.wholesale ) return ( ( wrap ? '(' : '' ) + ( customer?.rewards?.points || 0 ) + ( wrap ? ')' : '' ) )
    });

}