/**
*
*   Customer API
*       - A Layer for Customer data Hooked between Storefront + Shopify Tools
*
**/

//
import Config from '@/config';
import Storefront from '@/libraries/shopify/class';
import ShopifyTools from '@/libraries/shopify-tools/class';
import ShopifyServerTools from '@/libraries/shopify-tools/server';
//import { signOut } from 'next-auth/react';
import { redirect } from 'next/navigation';

/**
*
*   Customer Class
*
*
**/

//Customer Object
const Customer = {

    //The Customer data
    data: {},


    /**
    *
    *   Load a Customer
    *       - Loads the Current Customer based on the session access token
    *
    *   Params:
    *       session:        (Object) The Session with accessToken
    *
    **/
    load: async ( token ) => {

        //Ensure it only loads once
        if( Customer.data[ token ] ) return Customer.data[ token ];

        //Get the Customer Details
        const customer = ( await Storefront.Customer.get( token ) )?.body;

        //Return session expired
        if( ( !customer && typeof window !== 'undefined' ) ) 
            window.location.redirect = '/api/auth/signout';

        //Server Side Redirect on session expired
        if( !customer ) redirect('/api/auth/signout' );

        //Is the Customer a Wholesale Client
        customer.wholesale = ( found => {
            Config.wholesale.tags.every( tag => {
                if( customer.tags.indexOf( tag.trim() ) > -1 )
                    found = true;
                return !found;
            });
            return found;
        })(false);

        //Load the Rewards
        const rewards = await (async ()=>{

            //Frontend
            if( process.browser ) return await ( await fetch('/api/customer/rewards',{ cache: 'no-store' }) ).json();

            //Prep the Server Tools
            const ServerTools = await ShopifyServerTools();

            //Return Server
            return {
                result: 1,
                body:   await ServerTools.Customer.rewards( ShopifyTools.id( customer.id ) )
            };

        })()

        //Get the Customer Points
        //await ShopifyTools.get( 'rewards' , 'points' , { 'customerid': ShopifyTools.id( customer.id ) } );

        //Add it
        Customer.data[ token ] = {
            ...customer,
            rewards: {
                ...rewards?.body,
                base: ( rewards?.body?.points || 0 )
            }
        };

        //The Customer Object
        return Customer.data[ token ];

    }

};


//Output
export default Customer;