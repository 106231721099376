/**
*
*   Shopify Analytics
*       - Send the Analytics
*
**/
'use client'

import Config from '@/config';
import useShopifyAnalytics from '@/libraries/shopify/analytics';
import { AnalyticTools } from '@/libraries/analytics';
import { sendGAEvent } from '@next/third-parties/google'
import { CustomerContext } from '@/context/customer';
import { useSession } from 'next-auth/react';
import { useEffect, useContext } from 'react';
import { usePathname } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';





/**
*
*  Analytics
*       - The Default Script, Analytics send a Page View event on each page load
* 
*   Params:
*       payload:        (Object) The payload data to pass to Shopify Analytics
* 
**/
export default function Analytics({ payload , ip }) {

    //The Session Data
    const { data: session, status } = useSession();

    //Get the Customers
    const { Customer } = useContext(CustomerContext);

    //The Shopify Analtics library
    const ShopifyAnalytics = useShopifyAnalytics();

    //Validators
    const pathname = usePathname();
    const locale = useLocale();


    //Initialize Facebook on Customer Changes
    useEffect(()=>{
        if( typeof session != 'undefined' ){

            //Initialize the Facebook Query
            Customer.get( customer => AnalyticTools.init( customer ) );

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[session]);

    //On Page Changes
    useEffect(()=>{
        if( typeof session != 'undefined' ){

            (async()=>{

                //Load the Cookies
                const cookies = await ShopifyAnalytics.getCookies();

            })();

            //Send the PageView on load
            ShopifyAnalytics.sendPageView({
                payload:    payload,
                locale:     locale
            });
        
            //Send the Page View
            AnalyticTools.pageView();

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pathname, locale, session]);

}


//Export Helper Functions
export { AnalyticTools };

