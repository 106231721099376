/**
*
*   Account Profile
*       - The Account Profile Popup Editor
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import Uploader from '@/libraries/uploader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import Recaptcha from '@/libraries/recaptcha';
import {useDropzone} from 'react-dropzone'
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PatternFormat } from 'react-number-format';
import { useReCaptcha } from 'next-recaptcha-v3';


//Build the Element
export default function CommentModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Recaptcha
     const { executeRecaptcha } = useReCaptcha();

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Form Functions
    const Form = {

        //Email Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError( 'root.serverError' ,{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                setLoading(true);

                const token = await executeRecaptcha('comment/post');

                const response = await (
                    await fetch( '/api/comment', {
                        method: 'PUT',
                        body:   JSON.stringify({
                            ...data,
                            blog_id:        ShopifyTools.id( modal.comment.blog.id ),
                            article_id:     ShopifyTools.id( modal.comment.article.id ),
                            token:          token
                        })
                    })
                ).json();

                //Show Error
                if( !response.result ){
                    Form.thrown(
                        response.errors.map( ( row , i ) => (<p key={i}>{ row.message }</p>)
                    ));
                    return setLoading(false);
                }

                //Run the callback
                if( modal.callback ) modal.callback();

                //Success
                Modal.success();

                //Clear Loading
                setLoading(false);


            });
        }

    }

    //Preset the email
    useEffect(()=>{

        //Set the Values
        Customer.get( customer => {
            setValue( 'email' , customer.email );
            setValue( 'name' , ( customer?.username?.value || customer.firstName ) );
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[Customer.loading]);

    //The Form Submission
    return (
        <form id="comment-form" onSubmit={ handleSubmit(Form.submit) }>

            <header>
                <h2>{ t('Components.Comments.form.title') }</h2>
                <p>{ t('Components.Comments.form.description') }</p>
            </header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }
            <div className="row">
                <div>
                    <label htmlFor="name">{ t('Forms.Labels.name') }</label>
                    <input type="text" name="name" disabled={ session } placeholder={ t('Forms.Labels.name') + '*' } {...register('name', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.firstname') }) })} />
                    <ErrorMessage errors={errors} name="name" render={ Form.error } />
                </div>
            </div>

            <div className="row">
                <div>
                    <label htmlFor="email">{ t('Forms.Labels.email') }</label>
                    <input name="email" disabled={ session } placeholder={ t('Forms.Labels.email') + '*' } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                    <ErrorMessage errors={errors} name="email" render={ Form.error } />
                </div>
            </div>

            <div className="row">
                <div>
                    <label htmlFor="message">{ t('Forms.Labels.message') }</label>
                    <textarea name="message" placeholder={ t('Forms.Labels.message') } {...register('message', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.message') }) })} />
                    <ErrorMessage errors={errors} name="message" render={ Form.error } />
                </div>
            </div>
            <p>{ Recaptcha.message }</p>
            <footer>
                <Button size="small" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.save') }</Button>
                <a onClick={()=>Modal.close()}>{
                    t('Components.Modal.close')
                }</a>
            </footer>
        </form>
    );

}