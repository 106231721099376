/**
*
*   Specifications
*       - The Oil Specifications (if any)
*
**/
import Config from '@/config';
import { useLocale, useTranslations } from 'next-intl';

//Build the Element
export default function Specifications({ product, icons = true, ingredients = false, ...props }){

    const t = useTranslations();
    const locale = useLocale();
    const type = product.productType.toLowerCase().replace(/\s+/g,'-');

    return (
        <>
            { type == 'essential-oil' && (
                <ul className="specifications">
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M360.8 166.2C371.4 145.4 376 129.7 376 120c0-48.6-39.4-88-88-88s-88 39.4-88 88c0 9.7 4.6 25.4 15.2 46.2c10.2 20 24.1 41.3 38.6 61.2c12 16.5 24.1 31.7 34.1 43.7c10-12 22.1-27.2 34.1-43.7c14.5-19.9 28.4-41.2 38.6-61.2zM309.2 295.7C296.6 310.7 288 320 288 320s-8.6-9.3-21.2-24.3C232.4 254.7 168 171.3 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120c0 51.3-64.4 134.7-98.8 175.7zm92-70.1l-.5-.2c6.1-9.9 12-20 17.1-30.1c2-3.9 3.9-7.8 5.8-11.9L544 139.6 576 128v34V448L400 512 176 448 32 500.4 0 512V477.9 192l138.3-50.3c2.1 10.3 5.4 20.6 9.4 30.6L32 214.4V466.3l128-46.5V288h32V419.3l192 54.9V288h32V472.1l128-46.5V173.7L410.9 222.1l-9.8 3.5zM288 88a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.origin') }:</strong> { product.origin }
                        </p>
                    </li>
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M272 128c-67.9 0-124.9 47-140 110.3c23.6-9.3 49.2-14.3 76-14.3l96 0 16 0 0 32-16 0-96 0c-28.8 0-56 6.9-80 19.2C129.7 353.2 193.5 416 272 416c0 0 0 0 0 0l.8 0c109-.5 207.2-110.5 207.2-259.4c0-23-2.4-45.2-6.9-66.3C447.5 113.7 413.4 128 376 128l-72 0s0 0 0 0l-32 0zM96.7 256.3C104.7 166.4 180.1 96 272 96l32 0 72 0c35.2 0 66.6-16.2 87.2-41.7c8.7-10.8 15.5-23.3 19.8-36.9c6.1 13.5 11.3 27.7 15.5 42.4c8.8 30.3 13.5 62.9 13.5 96.8C512 317.1 405.1 447.3 273 448l-1 0c-89.4 0-163.2-66.7-174.5-153C57.5 327.3 32 376.7 32 432l0 32 0 16L0 480l0-16 0-32c0-73.9 38.6-138.9 96.7-175.7z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.botanical') }:</strong> { product.inci }
                        </p>
                    </li>
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M176 32c8.8 0 16 7.2 16 16v96c0 38.1 12.4 75.1 35.2 105.6l19.2 25.6c27 36 41.6 79.8 41.6 124.8v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V400c0-38.1-12.4-75.1-35.2-105.6l-19.2-25.6C174.6 232.8 160 189 160 144V48c0-8.8 7.2-16 16-16zM16 96c8.8 0 16 7.2 16 16v45.9c0 34.7 10.3 68.7 29.6 97.6l31.5 47.3c22.8 34.2 34.9 74.3 34.9 115.4V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V418.1c0-34.7-10.3-68.7-29.6-97.6L34.9 273.2C12.2 239.1 0 198.9 0 157.9V112c0-8.8 7.2-16 16-16zm336 16v45.9c0 34.7 10.3 68.7 29.6 97.6l31.5 47.3c22.8 34.2 34.9 74.3 34.9 115.4V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V418.1c0-34.7-10.3-68.7-29.6-97.6l-31.5-47.3C332.2 239.1 320 198.9 320 157.9V112c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.extraction') }:</strong> { product.extraction }
                        </p>
                    </li>
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M480 32c0 95.3-69.4 174.4-160.5 189.4c2.7 10.2 4.7 20.7 6.1 31.4C431.4 234.9 512 142.9 512 32c0-17.7-14.3-32-32-32H448C367 0 296 43 256.6 107.5c7.8 8.3 15.1 17.2 21.8 26.4C310.7 73.3 374.5 32 448 32h32zM64 96c106 0 192 86 192 192H224C118 288 32 202 32 96H64zM32 64C14.3 64 0 78.3 0 96C0 219.7 100.3 320 224 320h32V496c0 8.8 7.2 16 16 16s16-7.2 16-16V320 304 288C288 164.3 187.7 64 64 64H32z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.benefits') }:</strong> { product.benefits?.[ locale ] }
                        </p>
                    </li>
                </ul>
            )}
            { type != 'essential-oil' && (
                <ul className="specifications">
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M125.1 0H144c88.4 0 160 71.6 160 160v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V176H242.9C161.8 176 96 110.2 96 29.1C96 13 109 0 125.1 0zM144 32H128c1.5 62.1 52.4 112 114.9 112H271C263.1 80.9 209.3 32 144 32zM336 176V160c0-5.4-.2-10.7-.7-16C396.8 142.8 446.4 93.4 448 32H432c-45.7 0-85.8 23.9-108.4 59.9c-4.3-11.4-9.7-22.3-16-32.5C336.9 23.2 381.7 0 432 0h18.9C467 0 480 13 480 29.1c0 80.2-64.2 145.3-144 146.9zM151 317.4c13.1-8.8 28.6-13.4 44.4-13.4H344c30.9 0 56 25.1 56 56c0 8.6-1.9 16.7-5.4 24h5.6l94.7-56.4c8.3-4.9 17.8-7.6 27.5-7.6h1.3c28.9 0 52.3 23.4 52.3 52.3c0 17.7-9 34.2-23.8 43.8L432.6 493.9c-18.2 11.8-39.4 18.1-61 18.1H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H371.5c15.5 0 30.6-4.5 43.6-12.9l119.6-77.8c5.8-3.7 9.2-10.2 9.2-17c0-11.2-9.1-20.3-20.3-20.3h-1.3c-3.9 0-7.7 1.1-11.1 3l-98.5 58.7c-2.5 1.5-5.3 2.3-8.2 2.3H344 320 256c-8.8 0-16-7.2-16-16s7.2-16 16-16h64 24c13.3 0 24-10.7 24-24s-10.7-24-24-24H195.4c-9.5 0-18.7 2.8-26.6 8.1L88.9 397.3c-2.6 1.8-5.7 2.7-8.9 2.7H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H75.2L151 317.4z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.profile') }:</strong> { product.profile?.[ locale ] }
                        </p>
                    </li>
                    <li>
                        { icons && (
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M480 32c0 95.3-69.4 174.4-160.5 189.4c2.7 10.2 4.7 20.7 6.1 31.4C431.4 234.9 512 142.9 512 32c0-17.7-14.3-32-32-32H448C367 0 296 43 256.6 107.5c7.8 8.3 15.1 17.2 21.8 26.4C310.7 73.3 374.5 32 448 32h32zM64 96c106 0 192 86 192 192H224C118 288 32 202 32 96H64zM32 64C14.3 64 0 78.3 0 96C0 219.7 100.3 320 224 320h32V496c0 8.8 7.2 16 16 16s16-7.2 16-16V320 304 288C288 164.3 187.7 64 64 64H32z"/>
                                </svg>
                            </div>
                        )}
                        <p>
                            <strong>{ t('Pages.Product.Specifications.benefits') }:</strong> { product.benefits?.[ locale ] }
                        </p>
                    </li>
                    { ingredients && (
                        <li>
                            { console.log( product ) }
                            { icons && (
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M132 238.3c23.6-9.3 49.2-14.3 76-14.3l96 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-96 0c-28.8 0-56 6.9-80 19.2C129.7 353.2 193.5 416 272 416c0 0 0 0 0 0l.8 0c109-.5 207.2-110.5 207.2-259.4c0-23-2.4-45.2-6.9-66.3C447.5 113.7 413.4 128 376 128l-104 0c-67.9 0-124.9 47-140 110.3zM96.7 256.3C104.7 166.4 180.1 96 272 96l104 0c35.2 0 66.6-16.2 87.2-41.7l.6-.8c2.3-2.9 4.4-5.8 6.4-8.9c1.6-2.5 3.2-5.1 4.6-7.8c3.5-6.5 13.6-6.8 16.2 .1c1.3 3.5 2.5 7 3.7 10.6s2.3 7.1 3.4 10.8l.4 1.5c8.8 30.3 13.5 62.8 13.5 96.8C512 317.1 405.1 447.3 273 448l-1 0c-89.4 0-163.2-66.7-174.5-153C57.5 327.3 32 376.7 32 432l0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-32c0-73.9 38.6-138.9 96.7-175.7z"/>
                                    </svg>
                                </div>
                            )}
                            <p style={{ display:'block' }}>
                                <strong>{ t('Pages.Product.Specifications.ingredients') }:</strong> { product?.ingredients?.replace(/(<([^>]+)>)/gi,'') }
                            </p>
                        </li>
                    )}
                </ul>
            )}
        </>
    )

}