/**
*
*   CartTotal
*       - The Cart Total in the header
*
**/
'use client';

//
import Config from '@/config';
import Preloader from '@/components/preloader';
import { Link } from '@/libraries/navigation';
import { QuickCartContext } from '@/context/quick-cart';
import { CartContext } from '@/context/cart';
import { useContext } from 'react';


//Build the Element
export default function CartTotal(){

    //Get the Quick Cart Data
    const { QuickCart } = useContext(QuickCartContext);

    //Get the Cart
    const { Cart } = useContext(CartContext);

    //Return
    return (
        <a onClick={ () => QuickCart.open() } className="cart">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.1 15.2" style={{ enableBackground:'new 0 0 12.1 15.2' }} xmlSpace="preserve">
                <g>
                    <polygon fill="transparent" stroke="currentColor" points="11.5,14.7 0.5,14.7 1,3.7 11.1,3.7  "/>
                    <path fill="transparent" stroke="currentColor" d="M3.6,2.9c0-2.2,0.7-2.4,2.4-2.4S8.4,0.7,8.4,3c0,0.7,0,0.7,0,0.7H3.6V2.9z"/>
                </g>
            </svg>
            <span className="count">{
                Cart.loading ? <Preloader status={ true } mini /> : '(' + Cart.size() + ')'
            }</span>
        </a>
    )

}