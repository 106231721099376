/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { Link } from '@/components/link';
import { CustomerContext } from '@/context/customer';
import { useState, useEffect, useContext } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';


//Build the Element
export default function AccountSidebar({ children, ...props }){

    //
    const t = useTranslations();
    const [ loading , setLoading ] = useState(true);
    const [ stores , setStores ] = useState([]);
    const { Customer } = useContext(CustomerContext);

    //Get the Stores
    useEffect(()=>{
        (async () => {
            if( Customer.wholesale ){

                //
                let request = await ( await fetch('/api/account/stores' , { cache: 'no-store' }) ).json();
                //ShopifyTools.get( 'wholesale-orders' , 'store' , ShopifyTools.id( Customer.get( customer => customer.id ) ) )

                //Set the Stores
                setStores( ( request?.stores || [] ) );

                //Stop Loading
                setLoading(false);

            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <section className="stats">
            {/* Accumulated Points */}
            <div className="points">
                <span className="total">
                    { Customer.wholesale && (
                        <>
                            {/* Total */}
                            <span className="total">{
                                loading ? (<Preloader status={ true } mini />) : stores.length
                            }</span>

                            {/* Stores Icon */}
                            <svg className="stores" width="21px" height="21px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <path d="M16.001 1.072c5.291 0 9.596 4.305 9.596 9.597 0 1.683-0.446 3.341-1.29 4.799l-8.307 14.394-8.308-14.395c-0.843-1.456-1.289-3.115-1.289-4.798 0-5.292 4.305-9.597 9.597-9.597zM16.001 14.4c2.058 0 3.731-1.674 3.731-3.731s-1.674-3.731-3.731-3.731c-2.058 0-3.732 1.674-3.732 3.731s1.674 3.731 3.732 3.731zM16.001 0.006c-5.889 0-10.663 4.775-10.663 10.663 0 1.945 0.523 3.762 1.432 5.332l9.23 15.994 9.23-15.994c0.909-1.57 1.432-3.387 1.432-5.332 0-5.888-4.774-10.663-10.662-10.663v0zM16.001 13.334c-1.472 0-2.666-1.193-2.666-2.665 0-1.471 1.194-2.665 2.666-2.665s2.665 1.194 2.665 2.665c0 1.472-1.193 2.665-2.665 2.665v0z" fill="currentcolor"></path>
                            </svg>
                        </>
                    )}

                    { !Customer.wholesale && (
                        <>
                            {/* Total */}
                            <span className="total">{ Customer.get( customer => ( customer.rewards.points || 0 ) ) }</span>

                            {/* Points Icon */}
                            <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                        <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                            <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                            <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </>
                    )}

                </span>
                { Customer.wholesale && (
                    <span>
                        <Link href="/account/stores">{ t('Components.Account.stores') }</Link>
                    </span>
                )}
                { !Customer.wholesale && (
                    <span>{ t('Components.Account.points') }</span>
                )}

            </div>

            {/* Trees Planted */}
            <div className="trees">
                <span className="total">

                    {/* Total */}
                    { Customer.get( customer => ( customer.numberOfOrders || 0 ) ) }

                    {/* Trees Icon */}
                    <svg width="14px" height="28px" viewBox="0 0 14 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <polygon id="path-1" points="0 0 14 0 14 28 0 28"></polygon>
                        </defs>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
                            <g transform="translate(-200.000000, -330.000000)">
                                <g transform="translate(200.000000, 330.000000)">
                                    <mask id="tree-icon" fill="white">
                                        <use xlinkHref="#path-1"></use>
                                    </mask>
                                    <path d="M0.827544149,21.7965641 L7.00013813,1.56333333 L13.1723815,21.7965641 L7.35077401,21.7965641 L7.35077401,20.1474359 L9.19020982,18.2642564 C9.32695781,18.1242564 9.32695781,17.8970256 9.19020982,17.7566667 C9.05311119,17.6163077 8.83115868,17.6163077 8.69441069,17.7566667 L7.35077401,19.1322564 L7.35077401,16.324359 L8.90303904,14.7348205 C9.04013767,14.5948205 9.04013767,14.3675897 8.90303904,14.2272308 C8.76629105,14.0868718 8.54433854,14.0868718 8.40723991,14.2272308 L7.35077401,15.3091795 L7.35077401,11.8895897 C7.35077401,11.6914359 7.19368914,11.5306154 7.00013813,11.5306154 C6.80623649,11.5306154 6.64950226,11.6914359 6.64950226,11.8895897 L6.64950226,15.3091795 L5.59268572,14.2272308 C5.45558709,14.0868718 5.23363458,14.0868718 5.09688659,14.2272308 C4.95978796,14.3675897 4.95978796,14.5948205 5.09688659,14.7348205 L6.64950226,16.324359 L6.64950226,19.1322564 L5.30551494,17.7566667 C5.16876695,17.6163077 4.94681444,17.6163077 4.80971581,17.7566667 C4.67296782,17.8970256 4.67296782,18.1242564 4.80971581,18.2642564 L6.64950226,20.1474359 L6.64950226,21.7965641 L0.827544149,21.7965641 Z M7.35077401,28 L7.35077401,22.5145128 L13.6492463,22.5145128 C13.7610991,22.5145128 13.8659392,22.4606667 13.9318588,22.3684103 C13.998129,22.2761538 14.0174139,22.1576923 13.9837529,22.0485641 L7.33464476,0.252 C7.2890621,0.101948718 7.15336601,0 7.00013813,0 C6.84655962,0 6.71086353,0.101948718 6.66528087,0.252 L0.0161727287,22.0485641 C-0.0174883155,22.1576923 0.00214729362,22.2761538 0.0680668386,22.3684103 C0.133986383,22.4606667 0.238826511,22.5145128 0.350679356,22.5145128 L6.64950226,22.5145128 L6.64950226,28 L7.35077401,28 Z" fill="currentcolor" mask="url(#tree-icon)"></path>
                                </g>
                            </g>
                        </g>
                    </svg>

                </span>
                <span>{ t('Components.Account.trees') }</span>
            </div>
        </section>
    );

}