/**
*
*   Content Block
*       - A left/right content block + image or video
*
**/
'use client';

import Config from '@/config';
import Storefront from '@/libraries/shopify/class';
import { ProductContext } from '@/context/product';
import { useLocale } from 'next-intl';
import { useContext } from 'react';


//Build the Element
export default function ProductPrice({ ...props }){

    //
    const { Product } = useContext(ProductContext);

    //Get the Locale
    const locale = useLocale();

    //Set the Locale to Storefront
    Storefront.setLocale( locale );

    //Get the Product Data
    const product = Product.data;

    //Return
    return (
        <p className="price">
            { Product.currency == 'points' && (
                <>

                    { parseFloat( product.variant.compareAtPrice?.amount ) > 0 ? (
                        <strike className="was">
                            <svg className="points" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                        <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                            <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                            <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            { product.variant.compareAtPrice.amount * Config.points }
                        </strike>
                    ) : null }
                    <span className="current">
                        <svg className="points" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                    <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                        <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                        <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        { product.variant.price.amount * Config.points }
                    </span>
                </>
            )}
            { Product.currency == 'fiat' && (
                <>
                    { parseFloat( product.variant.compareAtPrice?.amount ) > 0 ? (<strike className="was">${ Storefront.money( product.variant.compareAtPrice.amount ) }</strike>) : null }
                    <span className="current">{ '$' + Storefront.money( product.variant.price.amount ) }</span>
                </>
            )}
        </p>
    );

}