/**
*
*   HeaderWrapper
*       - Manages the Scroll for the Header
*
**/
'use client';

import { useEffect, useRef, createRef } from 'react';


//The Header Ref
export const header = createRef();


//Build the Element
export default function HeaderWrapper({ children, props }){

    const scroll = {
        last:       0,
        callback:   () => {

            //Set the Position
            let position = window.scrollY,

            //Are we scrolling down?
            scrolling = ( position > 100 && position > scroll.last ),

            //The Body
            $header = header.current;

            //Scroll Started
            $header.classList.toggle( 'scrolled' , ( position > 1 ) );


            if( !$header.classList.contains('open') )
               $header.classList.toggle( 'scroll' , scrolling );

           //Scrolling
           document.querySelector('.body').classList.toggle( 'scroll' , scrolling )

            //Set the Position
            scroll.last = position;

        }
    }

    useEffect(() => {

        //On Mount
        setTimeout(() => {
            window.addEventListener('scroll', scroll.callback );
            window.dispatchEvent(new CustomEvent('scroll'));
        });

        //On Unmount
        return () => window.removeEventListener('scroll', scroll.callback );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Return
    return (
        <header id="header" ref={ header }>
            { children }
        </header>
    );

}