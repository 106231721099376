/**
*
*   Next Intl: Navigation
*       - Override the Next-intl Navigation
*
**/
import Config from '@/config';


//The Upload Library
const Uploader = (() => {

	//Prepare the Form Data
	const form = new FormData();

    //
    const obj = {

        //The Progress Callback
        progress: () => {},



        /**
        *
        *   parseJSON
        *       - Parse the field for JSON
        *       - If it's invalid, return NULL
        *
        *   Params:
        *       item:    (String) The String to Parse
        *
        *
        **/
        parseJSON: (item) => {
            try {
                if( !!(JSON.parse(item) && item) ) return JSON.parse(item);
            } catch(e) {
                return null
            }
        }

    }


    return {

    	//The Progress Callback
    	onProgress: (callback) => obj.progress = callback,

    	//Start
    	start: (files) => {
            return new Promise( resolve => {

                //The XHR Request
                const xhr = new XMLHttpRequest();

                //Add each file
                files.forEach( file => form.append( 'files' , file ) );

                //Open the XHR
                xhr.open( 'PUT' , '/api/files' , true);

                //On Progress
                xhr.onprogress = obj.progress;

                //On Success
                xhr.onload = (e) => resolve( obj.parseJSON( xhr.response ) );

                //On Error
                xhr.onerror = (e) => resolve( obj.parseJSON( xhr.response ) );

                //Start the upload
                xhr.send(form);

            });
    	}

    }

})



//Export the Overriden Links
export default Uploader