/**
*
*   Cart Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import CustomerCart from '@/libraries/carts/customer';
import GuestCart from '@/libraries/carts/guest';
import { createContext, useState, useEffect, useRef } from 'react';

//The Cart Context
export const CartContext = createContext(null);

//Create the Cart Context
export default function Cart({ session , children }){

	//Get the Cart Type
	const Cart = (
		session ?
			CustomerCart()
		:
			GuestCart()
	)

	//Return the Provider
	return (
		<CartContext.Provider value={{ Cart }}>
			{children}
		</CartContext.Provider>
	)

}