/**
*
*   Cart Form
*       - A left/right content block + image or video
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Storefront from '@/libraries/shopify/class';
import { useState, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'next/navigation';
import { useForm, Controller } from 'react-hook-form';
import { CartContext } from '@/context/cart';
import { QuickCartContext } from '@/context/quick-cart';
import { ProductContext } from '@/context/product';
import { CustomerContext } from '@/context/customer';
import { useTranslations } from 'next-intl';

//Build the Element
export default function CartForm({ children , callback ,  type = 'fiat' , ...props }){

    //Load the Cart
    const { Cart } = useContext(CartContext);

    //Load the QuickCart Modal
    const { QuickCart } = useContext(QuickCartContext);

    //Load the Product Context
    const { Product } = useContext(ProductContext);

    //The Customer Context
    const { Customer } = useContext(CustomerContext);

    //Translations
    const t = useTranslations();

    //Load Search Params
    const params = useSearchParams();

    //The Product Data
    const product = Product.data;

    //Form Settings
    const { control, register, watch, getValues, setValue, formState: { isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm({
        defaultValues: ( ( state ) => {
            product.variant.selectedOptions.forEach(option => {
                state[option.name] = option.value;
            });
            return state;
        })({ quantity: 1 })
    });

    //Quantity Settings
    const { onChange, onBlur, name, ref }  = {...register('quantity')};

    //Watch the value of quantity
    const [ $qty , qty ] = [ useRef() , watch('quantity') ];

    //Form Functions
    const Form = {

            /**
            *
            *   disabled
            *       - Is it purchasable based on points
            *
            *   Params:
            *       n/a
            *
            **/
            disabled: () => {
                if( type == 'fiat' ) return false;

                //Customer not logged in while using points, disabled
                if( !Customer.is.loggedin() ) return true;

                //Get the Customer Points
                const points = Customer.get( customer => customer.rewards.points );

                //Valdiate based on availability of points
                return ( points <= ( product.variant.price.amount * Config.points ) );
            },


            /**
            *
            *   update
            *       - Store the value
            *
            **/
            update: (e, search = []) => {

                //Prepare the Compare Feild
                let compare = product.variant.selectedOptions.map( option => {
                    return ( e.target.name == option.name ? e.target.value : getValues( option.name ) );
                }).join(' / ');

                //Set the State
                Product.setVariant(
                    product.variants.findIndex( variant => ( variant.title == compare ) )
                );

            },

            /**
            *
            *   submit
            *       - Add the Variant to the cart
            *
            *   Params:
            *       n/a
            *
            **/
            submit: async (data) => {

                //Get the Variant
                let variant = ((title)=>{

                    //Get the Variant Title
                    for( const key in data )
                        if( key != 'quantity' )
                            title.push( data[ key ] );

                    //Return the Variant
                    return product.variants.find(
                        variant => ( variant.title == title.join(' / ') )
                    );

                })([]);

                //Prepare the insert data
                const insert = {
                    ...product,
                    variant: variant
                }

                //Add it to the cart
                const response = await Cart.add( insert , parseInt( data.quantity ) , type );

                //Set a Default Callback
                if( !callback ) callback = (data, next) => next();

                //Run Callback
                callback({
                    result:     ( response?.id || response?.result ),
                    form:       data,
                    variant:    variant,
                    cart:       Cart.get( cart => cart )
                },()=>{

                    //Open the QuickCart
                    QuickCart.open();

                })

            }
        }


    useEffect(()=>{

        //Get the Variant
        const id = params.get('variant');

        //If we have a variant id
        if( id ){

            const variant = product.variants.find( v => v.id.replace(/[^0-9]+/,'') == id );

            //If we have a variant
            if( variant ){

                //Apply all variant values
                variant.options.forEach( ( value , i ) => {

                    //Update all form values
                    setValue( product.options[i].name , value )

                    //Trigger the update
                    if( i == variant.options.length - 1 )
                        Form.update({
                            target: {
                                value: value
                            }
                        });

                });

            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Return
    return (
        <form className="cart-form" onSubmit={ handleSubmit(Form.submit) }>
            { !product.variant.wholesale && product.productType.toLowerCase().replace(/\s+/,'-') == 'essential-oil' && (
                <p className="warn">
                    <i className="fa fa-exclamation-circle" />
                    <span>{ t.rich('Components.Cart.temporary') }</span>
                </p>
            )}
            { (()=>{

                //Prepare the Option data
                const options = product.options.map( (option,i) => {

                    //Skip Default Title
                    if( option.values[0] == 'Default Title' ) return false;

                    //Return
                    return (
                        <div key={i} className="item">
                            {/* Options */}
                            <strong>{ option.name }</strong>
                            <div className="list">
                                {
                                    option.values.map( (value,j) => {
                                        if( value.toLowerCase().indexOf('100% off') === -1 ){
                                            return (
                                                <span key={j}>
                                                    <input
                                                        type="radio"
                                                        id={ option.name + '-' + value }
                                                        value={ value }
                                                        {...register( option.name , {
                                                            onChange:   (e) => Form.update(e)
                                                        })}
                                                    />
                                                    <label htmlFor={ option.name + '-' + value }>{ value }</label>
                                                </span>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    )
                }).filter( option => option !== false );

                //If we have options
                return ( options.length > 0 && (
                    <div className="options">{ options }</div>
                ))
            })() }
            <div className="options">
                <div className="qty">
                    {/* Quantity */ }
                    <strong>{ t('Components.Cart.quantity') }</strong>
                    {
                        /* Quantity Fields: Input or Select based on quantity */
                        (!$qty.current?.tagName || $qty.current.tagName == 'SELECT') && qty < 10 ?

                            <select onChange={onChange} onBlur={onBlur} name={name} ref={(e)=>{
                                ref(e)
                                $qty.current = e;
                            }}>{
                                [1,2,3,4,5,6,7,8,9,'10+'].map( (value,i)=>(
                                    <option key={i} value={ ( value == '10+' ? 10 : value ) }>{ value }</option>
                                ))
                            }</select>

                        : (

                            <input type="number" onChange={onChange} onBlur={onBlur} name={name} min="1" pattern="\d*" ref={(e)=>{
                                ref(e);
                                $qty.current = e;
                            }} />

                        )
                    }
                </div>
                <div className="submit">
                    {/* Submit */}
                    <strong>&nbsp;</strong>
                    <Button disabled={ product.soldout || product.variant.soldout || isSubmitting || Form.disabled() } type="submit" button>
                        { product.soldout || product.variant.soldout && (
                            t('Components.Cart.sold-out')
                        )}
                        { type == 'points' && !product.variant.soldout && !product.soldout && (
                            t.rich('Components.Cart.button',{
                                price: () => (
                                    <span className="price">
                                        <svg className="points" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g transform="translate(-110.000000, -333.000000)" stroke="currentcolor">
                                                    <g id="Group-7" transform="translate(111.000000, 334.000000)">
                                                        <circle cx="9.5" cy="9.5" r="9.5"></circle>
                                                        <circle strokeWidth="0.5" cx="9.5" cy="9.5" r="6.5"></circle>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        { t.rich('General.points',{ 
                                            points: product.variant.price.amount * Config.points * qty
                                        }) }
                                    </span>
                                )
                            })
                        )}
                        { type == 'fiat' && !product.variant.soldout && !product.soldout && (
                            t.rich('Components.Cart.button',{
                                price: () => (
                                    <span className="price">
                                        { parseFloat( product.variant.compareAtPrice?.amount ) > 0 ? (<strike className="was">${ Storefront.money( product.variant.compareAtPrice.amount * qty ) }</strike>) : null }
                                        <span className="current">{ '$' + Storefront.money( product.variant.price.amount * qty ) }</span>
                                    </span>
                                )
                            })
                        )}
                    </Button>

                    { children ? (
                        <div className="links">{ children }</div>
                    ) : null }

                </div>
            </div>
        </form>
    );

}