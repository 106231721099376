/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import Preloader from '@/components/preloader';
import ProductCard from '@/components/collection/product-card';
import Storefront from '@/libraries/shopify/class';
import { useState, useEffect, Fragment } from 'react';
import { useRouter, usePathname } from '@/libraries/navigation';
import { useTranslations } from 'next-intl';


//Build the Element
export default function SearchResults({ search }){

    //Load the Data
    const { query , loading } = search;

    //Translations
    const t = useTranslations();

    //The Results
    const [ results, setResults ] = useState([]);

    //Load the Data
    useEffect(()=>{
        (async (output) => {

            if( query ){

                //Load the Data
                let data = ( await Storefront.Search.query( query ) )?.body;

                //Prepare the Data
                if( data?.products ){


                    //Remove all hidden products and the gift card
                    data.products = data.products.filter( product => {
                        return product.tags.find( tag => tag.toLowerCase() == 'hidden') === undefined && product.handle != 'gift-card'
                    });

                    //Build the list
                    data.products.forEach( product => {
                        output.push(
                            <ProductCard product={product} />
                        )
                    });

                }

            }

            setResults(output);

        })([]);
    },[query]);

    //Return
    return (
       <>
            <Preloader status={ loading } />
            {
                results.length > 0 ? (
                    <div className="collection">{
                        results.map( ( row , i ) => (<Fragment key={i}>{ row }</Fragment>))
                    }</div>
                ):(
                    search?.query?.length > 0 ? (
                        <p className="none">{ t('Components.Search.none') }</p>
                    ):(
                        <p className="none">{ t('Components.Search.empty') }</p>
                    )
                )
            }
        </>
    );

}