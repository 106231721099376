'use client';

import Config from '@/config';
import Button from '@/components/button';
import { ModalContext } from '@/context/modal';
import { useCookies } from 'next-client-cookies';
import { Country, State, City }  from 'country-state-city';
import { useEffect , useState , useContext } from 'react';
import { useTranslations } from 'next-intl';

/**
 *
 * obj
 *      - Checks a users browser / location and prompts a redirect if applicable
 *
 */
export default function Geolocation(){

    //The Modal
    const { Modal } = useContext(ModalContext);

    //Cookies
    const cookies = useCookies();

    //Set the country
    const [ location , setLocation ] = useState(false);

    //Get the Translations
    const t = useTranslations();

    //The obj
    const obj = {

        //Default Data
        data: {
            hidden:     false,
            country:    null
        },
        
        /**
        * 
        *  reroute
        *      - Reroute the URL to the opposite domain
        * 
        *  Params:
        *      location:   (Object) (The country-state-city location with isoCode)
        * 
        **/
        reroute: location => {

            //Get the domain without https
            const domain = Config.domain.replace( /https?:\/\// , '' );

            //Redirect .com -> .ca
            if( location.isoCode == 'CA' )
                return window.location.href.replace( domain , domain.replace('.com' , '.ca' ) );
            
            //Redirect .ca -> .com
            return window.location.href.replace( domain , domain.replace('.ca' , '.com' ) );

        },


        /**
        * 
        *  destination
        *      - Returns the currency of the alternate site
        * 
        *  Params:
        *      location:   (Object) (The country-state-city location with isoCode)
        * 
        **/
        destination: location => {
            switch( location.isoCode ){
                case 'US':  return location.currency;
                case 'CA':  return location.currency;
                default:    return 'USD';
            }
        }

    };

    //Load the 
    useEffect(()=>{
        ( async () => {

            try {

                //Return Saved Geo Data
                obj.data = {
                    ...obj.data,
                    ...JSON.parse( await cookies.get('geo.data') )
                }

            }catch(e){ /*Do Nothing*/ }

            //Ignore if hidden
            if( obj.data.hidden ) return;

            //If we don't have the stored obj.data
            if( !obj.data.country ){

                //Load the customer data
                const data = await ( await fetch('/api/customer/location',{ cache: 'no-store' }) ).json();

                //Get the Country
                obj.data.country = Country.getCountryByCode( data.location?.country );

                //Store the wholesale method
                cookies.set('geo.data', JSON.stringify(obj.data) );

            }

            //No country
            if( !obj.data.country || !obj.data.country?.isoCode ) return;

            //Same Country, skip
            if( Config.country == obj.data.country.isoCode ) return;

            //If International on U.S. website
            if( Config.country == 'US' && obj.data.country.isoCode != 'CA' ) return;

            //Add Padding
            document.body.classList.add('geolocation');

            //Set the Location
            setLocation( obj.data.country );

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Return Null
    return location && (
        <section id="countrySelector">
            <p>
                {t('Components.Geolocation.text',{
                    current: ( Config.country == 'US' ? 'US' : 'Canadian' )
                })}
                <a href={ obj.reroute( location ) }>
                    { t('Components.Geolocation.button',{
                        alternate: obj.destination( location )
                    })}
                </a>
            </p>
            <div className="close">
                <a onClick={()=>{

                    //Store the data and mark it as hidden
                    cookies.set('geo.data', JSON.stringify({
                        ...obj.data,
                        hidden: true
                    }) );

                    //Remove the Location
                    setLocation(false);

                    //Remove Padding
                    document.body.classList.remove('geolocation');

                }}>x</a>
            </div>
        </section>
    );

};
