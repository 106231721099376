/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Button from '@/components/button';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import RewardsHook from '@/libraries/rewards';
import { PatternFormat } from 'react-number-format';
import { CustomerContext } from '@/context/customer';
import { useEffect, useContext, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useReCaptcha } from 'next-recaptcha-v3';


//Build the Element
export default function NewsletterModal({ Modal }){

    //Form Settings
    const { control, register, setValue, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //The submission Status
    const [ status , setStatus ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Recaptcha
    const { executeRecaptcha } = useReCaptcha();

    //Translations
    const t = useTranslations();

    //Load the Rewards Class
    const Rewards = RewardsHook();

    //Form Functions
    const Form = {

        lists: [{
            id:     null,
            type:   'empty',
            name:   t('Pages.Account.Points.newsletter.empty')

        },{
            id:     'YcCukq',
            type:   'retail',
            name:   t('Pages.Account.Points.newsletter.retail')
        },{
            id:     'RMLKvJ',
            type:   'business',
            name:   t('Pages.Account.Points.newsletter.business')
        }],

        //Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('root.serverError',{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: async (data) => {
            Modal.get( async modal => {

                //Show Loading
                setLoading(true);

                //Get Recaptcha
                const token = await executeRecaptcha('klaviyo/signup');

                //The Form
                let form = Form.lists.find( form => form.id == data.form );

                //Default to retail
                if(!form) form = Form.lists[0];

                //Submit
                const response = (
                    await (
                        await fetch( '/api/klaviyo' , {
                            method: 'POST',
                            body:   JSON.stringify({
                                email:  data.email,
                                list:   form.id,
                                coupon: ( form.type == 'retail' ),
                                token:  token
                            })
                        })
                    ).json()
                );

                //Output the system error
                if( response.errors ){
                    setLoading(false);
                    return Form.thrown(
                        response.errors[0].message
                    )
                }

                //Output the submission error
                if( response.error ){
                    setLoading(false);
                    return setError('email',{
                        type: 500,
                        message: response.error
                    });
                }

                //Output Success
                setLoading( false );

                //End
                return ( modal.callback ? modal.callback() : null );

            })
        }

    }

    //Preset the email
    useEffect(()=>{
        setValue('email', Customer.get( customer => customer.email ) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Return
    return (
        <>
            <section>
                <div className="content">
                    <header>
                        <h2>
                            { t.rich('Components.Account.newsletter.title',{ points: Rewards.points['newsletter'] }) }
                        </h2>
                    </header>
                    <p>{ t('Components.Account.newsletter.details') }</p>
                    <form className="form" onSubmit={ handleSubmit(Form.submit) }>

                        {/* Submission Response Error */}
                        { errors.root?.serverError?.type > 200 && (
                            <div className="failed">{
                                errors.root.serverError.message
                            }</div>
                        ) }

                        {/* Submission Success */}
                        { status && (
                            <div className="success">{
                                t('Components.Account.birthday.success')
                            }</div>
                        )}

                        <div className="row">
                            <div>
                                <select 
                                    name="form" 
                                    {...register('form', {                                
                                        required: t('Forms.Errors.required', {
                                            type: t('Pages.Account.Points.newsletter.required')
                                        }),
                                        validate: (v) => v != Form.lists[0].name || t('Pages.Account.Points.newsletter.required')
                                    })}
                                >
                                    {Form.lists.map( ( list, i ) => (
                                        <option key={ i } value={ list.id }>{ list.name }</option>
                                    ))}
                                </select>
                                <ErrorMessage errors={errors} name="form" render={ Form.error } />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={ t('Forms.Labels.email') + '*' }
                                    {...register('email', {
                                        required: t('Forms.Errors.required', {
                                            type: t('Forms.Labels.email')
                                        }),
                                        validate: Form.email.validate 
                                    })}
                                />
                                <ErrorMessage errors={errors} name="email" render={ Form.error } />
                            </div>
                        </div>
                        <div className="row">
                            <Button disabled={ isSubmitting || loading } type="submit" button>{
                                loading ? (<Preloader status={true} mini />) : t('Components.Account.newsletter.save')
                            }</Button>
                        </div>
                        <p><a onClick={ () => Modal.close() }>{ t('Components.Account.newsletter.close') }</a></p>
                    </form>
                </div>
            </section>
        </>
    );

}