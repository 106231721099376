/**
*
*   ReviewsHook
*       - Data Manager for the Reviews Components
*
**/
'use client';
import Config from '@/config';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { createContext, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';

//The Cart Context
export const ReviewsContext = createContext(null);

//Create the Cart Context
function ReviewsProvider({ name , reference , identifier , children }){

    //Loading
    const [ loading, setLoading ] = useState(true);

    //The Review Data
    const [ reviews , setReviews ] = useState([]);

    //Internal Functions
    const obj = {

        /**
        *
        *  normalize
        *  - Normalize the Object so the data can be manipulated
        *
        *   Params:
        *       - review:   (Object) The Review Object
        *       - id:       (INT) The Review index stored as an ID
        *
        **/
        normalize: (review,id) => Object.assign({
            id:         id,
            loading:    false,
            helpful: {
                yes:    0,
                no:     0
            }
        },review),



        //Helpful Object
        helpful: {

            /**
            *
            *  id
            *  - Get the Helpeful Item Localized ID
            *
            *   Params:
            *       - review:         (Object) The Review Object
            *
            **/
            id: (review) => identifier + '-' + review.customer + '-' + review.date
        }

    }


    //Load the Cart on Page Load
    useEffect(()=>{
        (async ()=>{

            //Get the Data
            const data = await ShopifyTools.get( 'pubsub' , 'subscribe' , identifier );

            //Set the Reviews
            setReviews(
                ( data?.result ? data.body : [] ).map(
                    ( item , i ) => obj.normalize( item , i )
                ).reverse()
            );

            setLoading( false );

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    /**
    *
    *	Reviews
    * 		- Functional Class to manage the Review Data
    *
    * 	Params:
    * 		n/a
    *
    *
    **/
	const Reviews = {

        //The Name of the Component the reviews are for
        name:           name,

        //A Reference for the point attribution
        reference:      reference,

        //An Identifier for the review parent
        identifier:     identifier,

        //The Review Array
        all: reviews,

        //The Total Reviews
        total: reviews.length,



        /**
        *
        *  add
        *  - Add a new Review
        *
        *   Params:
        *       - list  (Array) The review list
        *
        **/
        add: async (review , token ) => {

            //
            const response = await ShopifyTools.put( 'pubsub' , 'publish' , {
                key:    identifier,
                token:  token,
                value:  review
            });

            //If successful, normalize and prep the data
            if( response.result ) setReviews([
                ...response.body.map(
                    ( item , i ) => obj.normalize( item , i )
                ).reverse()
            ]);

            //
            return response;

        },




        /**
        *
        *  loading
        *   - Functions based on the loading state
        *
        **/
        loading: {

            //Output the Loading State
            state: loading,

            //Stat & Stop
            start:  () => setLoading(true),
            stop:   () => setLoading(false),

            //Output a Preloader if it's currently loading
            output: (html) => {
                if( loading ) return <Preloader status={ true } mini />
                return html;
            }

        },




        /**
        *
        *  sort
        *  - Sort the Review List
        *
        *   Params:
        *       n/a
        *
        **/
        sort: (f) => {
            setReviews([ ...reviews.sort(f) ])
        },



        /**
        *
        *  average
        *  - Get the average rating based on the total reviews
        *
        *   Params:
        *       - list  (Array) The review list (Default: The review object)
        *
        **/
        average: ( list = reviews ) => Math.ceil(
            list.reduce( (sum , r) => {
                return sum + parseInt( r.rating )
            } , 0 ) / list.length
        ),



        /**
        *
        *  rating
        *  - Outputs the Star Rating
        *
        *   Params:
        *       - value: (INT) The rating value
        *
        **/
		rating: (value) => {

            //SVG Coordinates
            const coords = [
                'M5.30303 0.5L3.6643 3.7917L0 4.31946L2.65152 6.88191L2.02545 10.5L5.30303 8.7917L8.58049 10.5L7.95455 6.88191L10.6061 4.31946L6.94176 3.7917L5.30315 0.5H5.30303Z',
                'M17.9293 0.5L16.2905 3.7917L12.6262 4.31946L15.2777 6.88191L14.6518 10.5L17.9293 8.7917L21.2067 10.5L20.5808 6.88191L23.2323 4.31946L19.5681 3.7917L17.9294 0.5H17.9293',
                'M30.5553 0.5L28.9167 3.7917L25.2524 4.31946L27.9038 6.88191L27.2779 10.5L30.5553 8.7917L33.8329 10.5L33.207 6.88191L35.8585 4.31946L32.1942 3.7917L30.5555 0.5H30.5553',
                'M43.1816 0.5L41.543 3.7917L37.8787 4.31946L40.5301 6.88191L39.9041 10.5L43.1816 8.7917L46.4592 10.5L45.8332 6.88191L48.4847 4.31946L44.8204 3.7917L43.1817 0.5H43.1816',
                'M55.8082 0.5L54.1694 3.7917L50.5051 4.31946L53.1566 6.88191L52.5306 10.5L55.8082 8.7917L59.0856 10.5L58.4597 6.88191L61.1112 4.31946L57.4469 3.7917L55.8083 0.5H55.8082'
            ];

            //Return
            return (
    			<svg xmlns="http://www.w3.org/2000/svg" width="62" height="11" viewBox="0 0 62 11" fill="none">{
                    [...Array(5)].map( (r,i) => (
                        <path
                            key={i}
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d={ coords[ i ] }
                            fill={ ( i + 1 ) <= value ? 'currentcolor' : 'transparent' }
                            stroke="currentcolor"
                        />
                    ))
                }</svg>
            );
        },



        //Helpfulness Functions
        helpful: {




            /**
            *
            *  get
            *  - Get the value stored in local storage
            *
            *   Params:
            *       - review:         (Object) The Review Object
            *
            **/
            get: (review) => localStorage.getItem( obj.helpful.id( review ) ),




            /**
            *
            *  set
            *  - Applies a value to the helpfulness
            *
            *   Params:
            *       - item:         (Object) The Review Object
            *       - type:         (String) The type to apply
            *
            **/
            set: async (review, type) => {

                //The Current State
                const current = Reviews.helpful.get( review );

                //Get the Index
                const index = reviews.findIndex( item => review.id == item.id );

                //Set as Loading
                reviews[ index ].loading = true;

                //Update
                setReviews([ ...reviews ]);

                //If it's the same, delete it
                if( current == type ){

                    //Delete it
                    const response = await ShopifyTools.delete( 'pubsub' , 'helpful' , {
                        publication:    identifier,
                        issue:          review.id,
                        type:           current
                    });

                    //Remove the Item
                    localStorage.removeItem( obj.helpful.id( review ) );

                    //Set the updated review
                    reviews[ index ] = obj.normalize( response.body, review.id );


                }else{

                    //Delete any existing one, don't wait for it to complete
                    if( current ) await ShopifyTools.delete( 'pubsub' , 'helpful' , {
                        publication:    identifier,
                        issue:          review.id,
                        type:           current
                    });

                    //Add the Next One
                    const response = await ShopifyTools.put( 'pubsub' , 'helpful' , {
                        publication:    identifier,
                        issue:          review.id,
                        type:           type
                    });

                    //Update
                    localStorage.setItem( obj.helpful.id( review ) , type );

                    //Set the updated review
                    reviews[ index ] = obj.normalize( response.body, review.id );

                }

                //Stop loading
                reviews[ index ].loading = false;

                //Update State
                setReviews([ ...reviews ]);
            }
        }

    }




    //Return the Provider
    return (
        <ReviewsContext.Provider value={{ Reviews }}>
            {children}
        </ReviewsContext.Provider>
    )

}

export default ReviewsProvider;