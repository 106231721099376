/**
*
*   Language Switcher
*       - A dropdown for switching languages
*
**/
'use client';

import Config from '@/config';
import Translation from '@/components/translation';
import Preloader from '@/components/preloader';
import Storefront from '@/libraries/shopify/class';
import { useTransition, useEffect, useState, Fragment } from 'react';
import { useRouter, usePathname } from '@/libraries/navigation';
import { useLocale, useTranslations } from "next-intl";


//Build the Element
export default function LanguageSwitcher({ type, text='', ...props }){

    const t = useTranslations();

    //Current Locale
    const current = useLocale();

    //Router Library
    const router = useRouter();

    //Transitions
    const [isPending, startTransition]  = useTransition();

    //
    const pathname = usePathname();

    //
    const updateLocale = (e) => {

        //Get the locale
        const locale = e.target.value;

        //Set the Locale
        Storefront.setLocale( locale );

        //Update the path
        startTransition(() => {
            router.replace( pathname, { locale: locale });
        });

    };

    //Get the domain Locales
    const domain = Config.i18n.domains.filter( source => process.env.NEXT_PUBLIC_DOMAIN.indexOf( source.domain ) > -1 );

    //Set the Locales
    const locales = domain && domain[0].locales.length > 1 ? domain[0].locales : [] ;

    //Return
    return (
        <div className="language" data-viewport="desktop">
            { locales.length > 0 && (
                <>
                    {/* Text to display */}
                    { text }

                    {/* Dropdown Style */}
                    { type == 'dropdown' && (
                        <select defaultValue={ current } disabled={ isPending } onChange={ updateLocale }>
                            {locales.map( (locale) => (
                                <option key={locale} value={locale}>
                                    <Translation t="General.locales" data={{ locale: locale }} />
                                </option>
                            ))}
                        </select>
                    )}

                    {/* Button Style */}
                    { type == 'button' && (
                        <>
                            { locales.map( ( locale , i ) => (
                                <Fragment key={i}>
                                    { i > 0 && <hr /> }
                                    <button onClick={ updateLocale } value={ locale } key={ locale } className={ current == locale ? 'selected' : '' }>
                                        { locale }
                                    </button>
                                </Fragment>
                            )) }
                        </>
                    )}

                </>
            )}
        </div>
    );

}