/**
*
*   Intercom Messenger
*       - Manages the Intercom Chat on the Website
*
**/
'use client';

import Config from '@/config';
import Intercom from '@intercom/messenger-js-sdk';
import { useContext, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import moment from 'moment';

/**
*
*
*
**/
export default function Messenger({ children, ...props }){

    //The Session
    const { data: session, status } = useSession();
    const { Customer } = useContext(CustomerContext);

    //Update the Customer
    Customer.get( customer => {
        ( async () => {
            if( typeof window !== 'undefined' ){ //Only run on client side

                //The Intercom Data
                let data = {
                    app_id: process.env.NEXT_PUBLIC_INTERCOM_APPID,
                    name:   null,
                    email:  null
                };

                //If we have a customer
                if( customer && customer?.email ){

                    //Load the Intercom User Hash
                    const response = await (
                        await fetch( '/api/intercom' , {
                            method: 'POST',
                            headers: {},
                            body:   JSON.stringify({
                                email:  customer.email
                            })
                        })
                    ).json();

                    //Add the Data
                    data = Object.assign({
                        //user_id:    customer.id.match(/Customer\/([0-9]+)/)[1],
                        name:       customer.firstName + ' ' + customer.lastName,
                        email:      customer.email,
                        created_at: moment( customer.created_at ).unix(),
                        user_hash:  response.hash
                    },data);

                }

                //Load Intercom
                Intercom(data);

            }
        })();
    });
    
    return children;
}