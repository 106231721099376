/**
*
*   Account Password
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getProviders, signIn } from 'next-auth/react';
import { getCsrfToken } from 'next-auth/react';


//Build the Element
export default function AccountModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //
    const [ csrfToken , setCsrfToken ] = useState(null);

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Form Functions
    const Form = {

        //Password Settings
        password: {
            validate: value => ( getValues('password') == value ) || t('Forms.Errors.password.confirm')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError( 'root.serverError' ,{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                setLoading(true);

                let response = await Storefront.Customer.update( session.accessToken, {
                    password: data.password
                });

                //Show Error
                if( !response.result ){
                    Form.thrown(
                        response.errors.map( ( row , i ) => (<p key={i}>{ row.message }</p>)
                    ));
                    return setLoading(false);
                }


                signIn('credentials',{
                    email:      Customer.get( customer => customer.email ),
                    password:   data.password,
                    redirect:   false
                }).then( ({ ok, error })=>{

                    //Error
                    if( !ok && error ){
                        setError(
                            'root.serverError',
                            ((e) => {
                                try {

                                    //Get the JSON and format
                                    error   = JSON.parse(error);
                                    e       = {
                                        type:       error.status,
                                        message:    error.errors.map( (e,i) => (<p key={i}>{ e.message }</p>) )
                                    }

                                } catch( err ){
                                    console.log(err);
                                }

                                return e;
                            })({
                                type:       500,
                                message:    ( t('Forms.Errors.unknown') ).replace(':','')
                            })
                        )
                    }

                    //Success
                    Modal.success();

                    //Clear Loading
                    setLoading(false);

                });

            });
        }

    }

    //The Form Submission
    return (
        <form id="profile-form" onSubmit={ handleSubmit(Form.submit) }>

            <header>
                <h2>{ t('Components.Modal.Password.title') }</h2>
                <p>{ t('Components.Modal.Password.description') }</p>
            </header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }

            {/* Password */}
            <div className="group">
                <div className="row">
                    <div>
                        <label htmlFor="email">{ t('Forms.Labels.password') }</label>
                        <input type="password" name="password" placeholder={ t('Forms.Labels.password') + '*' } {...register('password', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.password') })  })} />
                        <ErrorMessage errors={errors} name="password" render={ Form.error } />
                    </div>
                </div>
                <div className="row">
                    <div>
                        <label htmlFor="confirm">{ t('Forms.Labels.confirm') }</label>
                        <input type="password" name="confirm" placeholder={ t('Forms.Labels.confirm') + '*' } {...register('confirm', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.confirm') }), validate: Form.password.validate })} />
                        <ErrorMessage errors={errors} name="confirm" render={ Form.error } />
                    </div>
                </div>
            </div>

            <footer>
                <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
                <Button size="small" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.save') }</Button>
                <a onClick={()=>Modal.close()}>{
                    t('Components.Modal.close')
                }</a>
            </footer>
        </form>
    );

}