/**
*
*   Account Password
*       - The Product Card
*
**/
'use client';

import Config from '@/config';
import Button from '@/components/button';
import ShopifyTools from '@/libraries/shopify-tools/class';
import Storefront from '@/libraries/shopify/class';
import Preloader from '@/components/preloader';
import Recaptcha from '@/libraries/recaptcha';
import { useSession } from 'next-auth/react';
import { CustomerContext } from '@/context/customer';
import { AnalyticTools } from '@/components/layout/analytics';
import { useEffect, useContext, useState } from 'react';
import { useTranslations, useLocale } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getProviders, signIn } from 'next-auth/react';
import { getCsrfToken } from 'next-auth/react';
import { PatternFormat } from 'react-number-format';
import { useReCaptcha } from 'next-recaptcha-v3';


//Build the Element
export default function B2BCtaModal({ Modal }){

    //The Session
    const { data: session, status } = useSession();

    //
    const [ csrfToken , setCsrfToken ] = useState(null);

    //Form Settings
    const { control, register, setValue, getValues, setError, resetField, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //If Success
    const [ success , setSuccess ] = useState(false);

    //if Loading
    const [ loading , setLoading ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Recaptcha
    const { executeRecaptcha } = useReCaptcha();

    //Get the Locale
    const locale = useLocale();

    //Translations
    const t = useTranslations();

    //Form Functions
    const Form = {

        //Email Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError( 'root.serverError' ,{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: (data) => {
            Modal.get( async modal => {

                setLoading(true);

                const token = await executeRecaptcha('retailer/email');

                data.type = modal.b2bcta.type;

                fetch( '/api/email' , {
                    method: 'POST',
                    body:   JSON.stringify({
                        token:      token,
                        to:         'sales@fernandpetal.com',
                        template:   'default',
                        subject:    'B2B Inquiry: ' + ( data.company || data.name ),
                        body:       {
                            title:  'B2B Inquiry',
                            data:   data
                        }
                    })
                })
                //Format to JSON
                .then( res => res.json() )

                //Success
                .then( response => {

                    //An Error Occured
                    if( !response.result )
                        Form.thrown( JSON.stringify( response.error ) );

                    //Success
                    if( response.result ){
                        
                        //Set as Success
                        setSuccess( true );

                        //Send Event
                        AnalyticTools.inquiry(
                            ( modal.b2bcta?.value ?? 100 ), //Default value of 100
                            modal.b2bcta.type
                        )

                    }

                    //Stop Loading
                    setLoading(false);

                })

                //Catch Error
                .catch( err => {
                    setLoading(false);
                    Form.thrown( err.toString() )
                });

            });
        }

    }

    //Preset the email
    useEffect(()=>{
        if( Customer.is.loggedin() ){

            //Set the Values
            Customer.get( customer => {
                setValue( 'name' , customer.firstName + ' ' + customer.lastName );
                setValue( 'company' , customer.company );
                setValue( 'email' , customer.email );
                setValue( 'phone' , customer?.phone.replace(/\+([0-9]{1})([0-9]{3})([0-9]{3})([0-9]{4})/g,'$2-$3-$4') );
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[Customer.loading]);

    //The Form Submission
    return (
        <form id="b2b-cta" onSubmit={ handleSubmit(Form.submit) }>

            <header>{
                Modal.get( modal => (
                    <>
                        <h2>{ modal.b2bcta.title }</h2>
                        <p>{ modal.b2bcta.description }</p>
                    </>
                ))
            }</header>

            {/* Submission Response Error */}
            { errors.root?.serverError?.type > 200 && (
                <div className="failed">{
                    errors.root.serverError.message
                }</div>
            ) }



            {success && (
                <>
                    <p className="success">{ t('Components.Modal.Business.success') }</p>
                    <footer>
                        <Button onClick={()=>Modal.close()} size="small" button>{ t('Components.Modal.close') }</Button>
                    </footer>
                </>
            )}

            {!success && (
                <>
                    <div className="group">
                        <Preloader status={ Customer.loading } />

                        {/* Name */}
                        <div className="row">
                            <div>
                                <label htmlFor="name">{ t('Forms.Labels.name') }</label>
                                <input type="text" name="name" placeholder={ t('Forms.Labels.name') + '*' } {...register('name', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.name') }) })} />
                                <ErrorMessage errors={errors} name="name" render={ Form.error } />
                            </div>
                            <div>
                                <label htmlFor="company">{ t('Forms.Labels.company') + ' *' }</label>
                                <input type="text" name="company" placeholder={ t('Forms.Labels.company') } {...register('company')} />
                                <ErrorMessage errors={errors} name="company" render={ Form.error } />
                            </div>
                        </div>

                        <div className="row">
                            {/* Email */}
                            <div>
                                <label htmlFor="email">{ t('Forms.Labels.email') }</label>
                                <input name="email" placeholder={ t('Forms.Labels.email') + '*' } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                                <ErrorMessage errors={errors} name="email" render={ Form.error } />
                            </div>
                            {/* Phone */}
                            <div>
                                <label htmlFor="phone">{ t('Forms.Labels.phone') }</label>
                                <Controller control={control} name="phone" render={({ field: { onChange, name, value } }) => (
                                    <PatternFormat format="###-###-####" name={name} value={value} onChange={onChange} placeholder={ t('Forms.Labels.phone') } />
                                )} />
                                <ErrorMessage errors={errors} name="phone" render={ Form.error } />
                            </div>
                        </div>

                        <div className="row">
                            <div>
                                <label htmlFor="message">{ t('Forms.Labels.message') }</label>
                                <textarea name="message" placeholder={ t('Forms.Labels.message') + '*' } {...register('message', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.message') }) })} />
                                <ErrorMessage errors={errors} name="message" render={ Form.error } />
                            </div>
                        </div>

                    </div>

                    <p className="recaptcha">{ Recaptcha.message }</p>

                    <footer>
                        <Button size="small" disabled={ isSubmitting || loading } button>{ t('Forms.Labels.submit') }</Button>
                        <a onClick={()=>Modal.close()}>{
                            t('Components.Modal.close')
                        }</a>
                    </footer>
                </>
            )}
        </form>
    );

}