/**
*
*   Wholesale Provider
*       - Override the Next-intl Navigation
*
**/
'use client';
import Config from '@/config';
import Preloader from '@/components/preloader';
import Storefront from '@/libraries/shopify/class';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { CustomerContext } from '@/context/customer';
import { useContext, useState, useEffect } from 'react';
import { useLocale, useTranslations } from 'next-intl';

//Create the Cart Context
export default function RewardsHook(){

	//Get the Customer Context
	const { Customer } = useContext(CustomerContext);

	//The Translations
	const t = useTranslations();

    /**
    *
    *	Rewards
    * 		- Functional Class to manage the Rewards
    *
    * 	Params:
    * 		n/a
    *
    *
    **/
    const Rewards = {

    	//The Reward Points and values
    	points: Config.rewards,

        //Is the current component loading?
        loading: type => loading[ type ],



        //Has the customer received/completed the reward type
        awarded: type => Customer.get( customer => customer.rewards.list.indexOf(type) > -1 ),



        /**
		*
		* 	value
		* 		- Has the person received the points
		*
		* 	Params:
		* 		type: (String) The reward type ot check the value of
		*
		*
		**/
		value:  type => ( Rewards.points?.[type]?.value || 0 ),


		/**
		*
		* 	text
		* 		- Output the Reward Points in text
		*
		* 	Params:
		* 		type: (String) The reward type to return
		*
		*
		**/
		text:  type => {

		    if( loading[ type ] || Customer.loading ) return (<Preloader status={ true } mini />);

		    //Points already obtained
		    if( Rewards.awarded(type) ) return (<i className="far fa-check-circle"></i>);

		    //Return Total Points
		    return t('Pages.Account.Points.get-points', { points: Rewards.value( type ) } )

		},


		/**
		*
		* 	add
		* 		- Add Points to the persons account
		*
		* 	Params:
		* 		type: (String) The reward type ot check the value of
		* 		key:  (String) The Points Key to get the associated value
		*
		*
		**/
        add: async ( type , key ) => {

            //Show Loading
            setLoading({
                ...loading,
                [type]: true
            });

            //Add the Points
            const response = await ShopifyTools.put( 'rewards' , 'points' , {
                'customerid':   Customer.get( customer => ShopifyTools.id( customer.id ) ) ,
                'points':       Rewards.points[ ( key || type ) ].value ,
                'type':         type
            });


            if( response.result ){

            	//Get the rewards
            	const rewards = response.body;

            	//Set the Base
            	rewards.base = ( rewards.points || 0 );

            	//Get the Current Rewards
            	const current = Customer.get( customer => customer.rewards );

            	//Get the Difference (if any)
            	const difference = ( current.base - current.points );

            	//Remove the difference to keep the point system inline
            	if( difference > 0 ) rewards.points = rewards.points - difference;

                //Update the Rewards
                Customer.set({ rewards: rewards })

            }else{

                //Output the error
                Rewards.thrown( response.message );

            }

            //Remove the Loader
            setLoading({
                ...loading,
                [type]: false
            })

        }

    };


    //Add the Points to loading
    const [ loading , setLoading ]  = useState( ((list)=>{
        Object.keys( Rewards.points ).forEach( point => list[ point ] = false );
        return list;
    })({}) );



	//Return the Provider
	return Rewards;

}