/**
*
*   Product Card
*       - The Product Card
*
**/
'use client';

import Button from '@/components/button';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { CustomerContext } from '@/context/customer';
import { useContext, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


//Build the Element
export default function ShareModal({ Modal , callback }){

    //Form Settings
    const { control, register, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //The submission Status
    const [ status , setStatus ] = useState(false);

    //Get the customer
    const { Customer } = useContext(CustomerContext);

    //Translations
    const t = useTranslations();

    //Form Functions
    const Form = {

        //Settings
        email: {
            validate: email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) || t('Forms.Errors.email.invalid')
        },

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('root.serverError',{
            type: 500,
            message: message
        }),

        /**
        *
        *   submit
        *       - Add the Variant to the cart
        *
        *   Params:
        *       n/a
        *
        **/
        submit: async (data) => {
            Customer.get( async (customer) => {

                //Send the Referral
                const response = await ShopifyTools.put( 'rewards' , 'referral' , {
                    customerid: ShopifyTools.id( customer.id ),
                    email:      data.email
                });

                if( !response )
                    return Form.thrown( t('General.offline') );

                if( !response.result )
                    return Form.thrown( response.message );

                //Success
                setStatus(true);

            })
        }
    }

    //Return
    return (
        <>
            <section>
                <header>
                    <h2>{ t.rich('Components.Account.share.title') }</h2>
                </header>
                <div>
                    <p>{ t('Components.Account.share.description.long') }</p>
                    <form className="form" onSubmit={ handleSubmit(Form.submit) }>
                        {/* Submission Response Error */}
                        { errors.root?.serverError?.type > 200 && (
                            <div className="failed">{
                                errors.root.serverError.message
                            }</div>
                        ) }
                        {/* Submission Success */}
                        { status && (
                            <div className="success">{
                                t('Components.Account.share.success')
                            }</div>
                        )}
                        <div>
                            <div className="row">
                                <input type="email" name="email" placeholder={ t('Forms.Labels.email') + '*' } {...register('email', { required: t('Forms.Errors.required',{ type: t('Forms.Labels.email') }), validate: Form.email.validate })} />
                                <Button disabled={ isSubmitting } type="submit" button>{ t('Components.Account.share.send') }</Button>
                            </div>
                            <ErrorMessage errors={errors} name="email" render={ Form.error } />
                        </div>
                        <p><a onClick={ () => Modal.close() }>{ t('Components.Account.share.close') }</a></p>
                    </form>
                </div>
            </section>
        </>
    );

}