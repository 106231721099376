/**
*
*   Rating
*       - A Review Rating snippet to display total reviews
*       - Must be used within the Review context
*
**/
'use client';


import Config from '@/config';
import { Link } from '@/components/link';
import { ReviewsContext } from '@/context/reviews';
import { useTranslations } from 'next-intl';
import { useContext } from 'react';

//Build the Element
export default function Rating({ identifier , href = '' , ...props }){

    //Get the Translations
    const t = useTranslations();

    //The Reviews Manager
    const { Reviews } = useContext(ReviewsContext);

    //Return
    return (
        <div className="rating">
            { Reviews.loading.output(
                <>
                    { Reviews.rating( Reviews.average() ) }
                    <Link href={ href + '#reviews' }>{
                        t('Components.Reviews.total',{
                            average:    Reviews.average(),
                            total:      Reviews.total
                        })
                    }</Link>
                </>
            )}
        </div>
    );

}