/**
*
*   Translation
*       - Returns a next-intl translation for use in async funcvtions
*
**/
import Config from '@/config';
import { useTranslations } from 'next-intl';



//Build the Element
export default function Translation({ t, data={} }){

    //Load the Translations
    let Translate = useTranslations();

    //Return
    return Translate.rich( t , data );

}