/**
*
*   Cart Form Popup
*       - A left/right content block + image or video
*
**/
'use client';

import Config from '@/config';
import Image from '@/components/image';
import Button from '@/components/button';
import Preloader from '@/components/preloader';
import AddToCart from '@/components/cart/add-to-cart';
import CartReload from '@/components/cart/reload';
import Storefront from '@/libraries/shopify/class';
import Link from 'next/link';
import React from 'react';
import { Tracker } from '@/components/tracker';
import { CartContext } from '@/context/cart';
import { QuickCartContext } from '@/context/quick-cart';
import { CustomerContext } from '@/context/customer';
import { useTranslations, useLocale } from 'next-intl';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useState, useLayoutEffect, useRef, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
//import 'swiper/css';

//Build the Element
export default function CartPopup({ children , product , ...props }){

    //Translations
    const t = useTranslations();

    //Locale
    const locale = useLocale();

    //Cart Context
    const { QuickCart } = useContext(QuickCartContext);

    //Cart Context
    const { Cart } = useContext(CartContext);

    //Get the Customer
    const { Customer } = useContext(CustomerContext);

    //Form Settings
    const { control, register, reset, setError, setValue, getValues, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //The Cart Line Item Data
    const lines = ( Cart.get( cart => cart.lines ) || [] );

    //
    const [ upsell, setUpsell ] = useState([]);

    //Swiper
    const [swiper, setSwiper] = useState({
        instance:   null,
        first:      true,
        last:       false
    });

    //Popup Cart Settings
    const Form = {
        timeouts: {},

        //On Submit
        submit: async data => {
            Cart.get( cart => {

                //Customer Checkout
                if( cart?.invoiceUrl ) window.location.href = cart.invoiceUrl;

                //Guest Checkout
                if( cart?.checkoutUrl ) window.location.href = cart.checkoutUrl;
                
            });
        }
    };

    //Swiper Parameters
    const Parameters = {

        //Swiper Settings
        spaceBetween:   10,
        slidesPerView:  1,
        loop:           true,

        onSwiper: swiper => setSwiper({
            instance:       swiper,
            first:          swiper.isBeginning,
            last:           swiper.isEnd
        }),

        onSlideChange: swiper => setSwiper({
            instance:       swiper,
            first:          swiper.isBeginning,
            last:           swiper.isEnd
        }),

        onTransitionEnd: swiper => setSwiper({
            instance:       swiper,
            first:          swiper.isBeginning,
            last:           swiper.isEnd
        }),

        onResize: swiper => setSwiper({
            instance:       swiper,
            first:          swiper.isBeginning,
            last:           swiper.isEnd
        })

    };


    //Load the Upsell Products
    useEffect(()=>{

        let data = Tracker.sort('product');

        //Only if we have tracked data
        if( data.length > 0 ){
            (async () => {

                //
                const tracked = {
                    incart: data.filter( product => product.cart ),
                    rest:   data.filter( product => !product.cart )
                }

                //Get the initial product list
                let list = (
                    await Storefront.Products.get(
                        tracked.rest.map( row => row.id )
                    )
                )?.body

                //If we don't have 6, add the recommendation list
                if( list.length < 6 ) list = list.concat(
                    (
                        await Storefront.Products.recommendations(
                            (
                                tracked.incart.length > 0 ?

                                    //Get Recommendations by most engaged product in cart
                                    tracked.incart[0].id

                                :

                                    //Get Recommendations based on most interacted
                                    tracked.rest[0].id
                            )
                        )
                    )?.body
                );

                //Set the Upsell
                setUpsell( list )

                console.log([ ...samples , ...lines ]);

            })();
        }

        //Reset the Form
        reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ Cart.data ]);

    //Prepare any sample offerings
    const samples = ( Cart.get( cart =>
        cart.free.samples.filter( sample => !sample.added ).map( item  => {
            return {
                id: item.samples[0].id,
                gift: true,
                classes: 'demo',
                requirement: item.requirement,
                quantity: 1,
                product: {
                    ...item,
                    variant: item.samples[0]
                }
            }
        })
    ) || [] );

    //The Next Available Sample
    const sample = Cart.get( cart => cart.free.samples.filter( sample => !sample.added )[0] );

    //Return
    return (
        <form id="quick-cart" onSubmit={ handleSubmit(Form.submit) } className={ QuickCart.opened ? 'active' : null }>
            <div className="bg" onClick={ () => QuickCart.close() }></div>
            <div className="viewport">
                <header>

                    {/* Title */}
                    <div className="row">
                        <a onClick={ () => QuickCart.close() }>
                            <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.575735 4.07574C0.341421 4.31005 0.341421 4.68995 0.575735 4.92427L4.39411 8.74264C4.62843 8.97696 5.00833 8.97696 5.24264 8.74264C5.47696 8.50833 5.47696 8.12843 5.24264 7.89411L1.84853 4.5L5.24264 1.10589C5.47696 0.871574 5.47696 0.491675 5.24264 0.25736C5.00833 0.0230459 4.62843 0.0230459 4.39411 0.25736L0.575735 4.07574ZM20 3.9L1 3.9L1 5.1L20 5.1L20 3.9Z" fill="currentcolor"/>
                            </svg>
                        </a>
                        <h3>
                            { t('Components.Cart.title') }
                            <span>(
                                <span>{ Cart.size() }</span>
                            )</span>
                        </h3>
                    </div>

                    {/* Incentives */}
                    <div data-cart="shipping" className="shipping">
                        {
                            Cart.get( cart => cart.cost.subtotalAmount.amount < cart.free.shipping ) ? (
                                <>
                                    <p>{ t.rich('Components.Cart.remaining',{
                                        total:  (chunks) => (
                                            <strong>
                                                { Cart.money( Cart.get( cart => parseFloat( cart.free.shipping - cart.cost.subtotalAmount.amount ).toFixed(2) ) ) }
                                            </strong>
                                        )
                                    })}</p>
                                    <div className="bar">
                                        <div>
                                            <div style={{
                                                width: Cart.get( cart => Math.round( ( cart.cost.subtotalAmount.amount * 100 ) / cart.free.shipping ) ) + '%'
                                            }} />
                                        </div>
                                    </div>
                                </>
                            ) : sample && Cart.get( cart => sample.requirement > cart.cost.subtotalAmount.amount ) ? (
                                <>
                                    <p>{ t.rich('Components.Cart.gifts.remaining',{
                                        total:  (chunks) => (
                                            <strong>
                                                { Cart.money( Cart.get( cart => ( sample.requirement - cart.cost.subtotalAmount.amount ) ) ) }
                                            </strong>
                                        )
                                    })}</p>
                                    <div className="bar">
                                        <div>
                                            <div style={{
                                                width: Cart.get( cart => Math.round( ( cart.cost.subtotalAmount.amount * 100 ) / sample.requirement ) ) + '%'
                                            }} />
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </header>
                <div className="cart" ref={ QuickCart.list }>
                    <Preloader status={ Cart.loading } sticky />
                    <div className="scroll">
                        <table cellPadding="0" cellSpacing="0">
                            <tbody>

                                {/* Mobile Cart */}
                                {(
                                    lines.length == 0 ? (
                                        <tr className="none">
                                            <td colSpan="3">
                                                <p>{ t('Components.Cart.empty') }</p>
                                                <CartReload />
                                            </td>
                                        </tr>
                                    ) : (
                                        [ ...samples , ...lines ].map( ( item , index ) => (
                                            <React.Fragment key={ 'cart-' + ( item?.redemption ? 'points' : 'fiat' ) + '-' + item.product.variant.id }>
                                                <tr className={ item.classes || '' }>
                                                    { console.log( [ ...samples , ...lines ] ) }

                                                    {/* Featured Image */}
                                                    <td className="thumbnail">
                                                        <Link href={ '/products/' + item.product.handle }>
                                                            <Image src={ item.product.featuredImage } alt="" width="160" height="160" />
                                                        </Link>
                                                    </td>
                                                    <td className="details">

                                                        {/* Details */}
                                                        <div className="product">

                                                            <Link href={ '/products/' + item.product.handle } className="title">
                                                                { item.product.title }
                                                            </Link>

                                                            <p className="type">
                                                                <span>{ item.product.productType }</span>
                                                            </p>

                                                            <p className="variant">{
                                                                item.product.variant.title.replace(
                                                                    /((\/ ?)?default( title)?)|\(100% off\)/gi
                                                                ,'')
                                                            }</p>

                                                        </div>

                                                        {/* Quantity */}
                                                        <div className="quantity">{
                                                            (()=>{

                                                                //Input Classes
                                                                const Input = {
                                                                    update: (e) => {

                                                                        //If we're typing, or blurring without change, just skip
                                                                        if( 
                                                                            e.target.dataset.state == 'active' 
                                                                            ||
                                                                            item.quantity == e.target.value
                                                                        ) return;

                                                                        //Clear the Timeout
                                                                        if( Form.timeouts[ item.id ] ) window.clearInterval( Form.timeouts[ item.id ] );

                                                                        //
                                                                        Form.timeouts[ item.id ] = window.setTimeout(() => {

                                                                            //Remove the item if we have no quantity
                                                                            if( e.target.value <= 0 )
                                                                                return Cart.remove( item );

                                                                            //If it's a lower value, just accept
                                                                            if( item.quantity > e.target.value )
                                                                                return Cart.update( item , e.target.value );

                                                                            //If it's not points, just continue
                                                                            if( !item.appliedDiscount )
                                                                                return Cart.update( item , e.target.value );

                                                                            //Get the difference
                                                                            const difference = e.target.value - item.quantity;

                                                                            //Get the customer points
                                                                            const points = Customer.get( customer => customer.rewards.points );

                                                                            //If we have enough points
                                                                            if( points > ( item.variant.price * Config.points * difference ) )
                                                                                return Cart.update( item , e.target.value );

                                                                            //Show the error
                                                                            setError( item.id , { type: 'custom' , message: t('Components.Cart.insufficient') });

                                                                            //Revert
                                                                            setValue( item.id , item.quantity);

                                                                        },500);
                                                                    }
                                                                };


                                                                if( item.quantity < 10 ) return (
                                                                    <select
                                                                        aria-label={ t('Components.Cart.quantity') }
                                                                        readOnly={ item.gift || false }
                                                                        onFocus={ (e) => e.target.dataset.preset = item.quantity }
                                                                        onKeyDown={ (e) => e.target.dataset.state = 'active' }
                                                                        onKeyUp={ (e) => e.target.dataset.state = 'ready' }
                                                                        {...register( item.id , {
                                                                            value:    item.quantity,
                                                                            onBlur:   Input.update,
                                                                            onChange: Input.update
                                                                        })}
                                                                    >{
                                                                        [1,2,3,4,5,6,7,8,9,'10+'].map( (value,i)=>(
                                                                            <option key={i} value={ ( value == '10+' ? 10 : value ) }>{ value }</option>
                                                                        ))
                                                                    }</select>
                                                                )


                                                                if( item.quantity >= 10 ) return (
                                                                    <input
                                                                        aria-label={ t('Components.Cart.quantity') }
                                                                        type="number"
                                                                        min="1"
                                                                        pattern="\d*"
                                                                        readOnly={ item.gift || false }
                                                                        onFocus={ (e) => e.target.dataset.preset = item.quantity }
                                                                        onKeyDown={ (e) => e.target.dataset.state = 'active' }
                                                                        onKeyUp={ (e) => e.target.dataset.state = 'ready' }
                                                                        {...register( item.id , {
                                                                            value:    item.quantity,
                                                                            onBlur:   Input.update,
                                                                            onChange: Input.update
                                                                        })}
                                                                    />
                                                                )

                                                            })()
                                                        }
                                                            <ErrorMessage errors={errors} name={ item.id } render={({ message })=>(<p className="error">{ message }</p>)} />
                                                        </div>

                                                    </td>
                                                    <td className="action">

                                                        {/* Cart Total */ }
                                                        <div data-label={ t('Components.Cart.total') } className="total">
                                                            { item.redemption && (
                                                                <>
                                                                    <sup className="was">{ Cart.money( item.product.variant.price.amount * ( item.quantity || 1 ) ) }</sup>
                                                                    {t('Components.Cart.points',{
                                                                        points: item.product.variant.price.amount * Config.points * ( item.quantity || 1 )
                                                                    })}
                                                                </>
                                                            )}
                                                            { !item.redemption && (
                                                                <>
                                                                    { item.product.variant.compareAtPrice?.amount > item.product.variant.price.amount && (
                                                                        <sup className="was">{ Cart.money( item.product.variant.compareAtPrice.amount * item.quantity ) }</sup>
                                                                    )}
                                                                    { Cart.money( item.product.variant.price.amount * ( item.quantity || 1 ) ) }
                                                                </>
                                                            )}
                                                        </div>

                                                        <div className="list">

                                                            {/* Remove Item */}
                                                            <a onClick={ () => Cart.remove(item) }>
                                                                <i className="far fa-times-circle"></i>
                                                                <span>{ t('Components.Cart.remove') }</span>
                                                            </a>

                                                        </div>

                                                    </td>
                                                </tr>
                                                { item.gift && (
                                                    <tr className="notice">
                                                        <td colSpan="6">
                                                            <div>
                                                                { Cart.get( cart => cart.cost.subtotalAmount.amount ) >= item.requirement  ? (
                                                                    <Button onClick={ () => Cart.add( item.product , 1 ) }>{ t('Components.Cart.gifts.button') }</Button>
                                                                ):(
                                                                    <>
                                                                        <div className="icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                <path d="M365.1 32c-17.6 0-33.9 9-43.2 23.9l-45 72.1H288h80c26.5 0 48-21.5 48-48s-21.5-48-48-48h-2.9zm-130 96l-45-72.1C180.8 41 164.5 32 146.9 32H144c-26.5 0-48 21.5-48 48s21.5 48 48 48h80 11.1zM256 101l38.8-62c15.2-24.2 41.7-39 70.3-39H368c44.2 0 80 35.8 80 80c0 18-6 34.6-16 48h48 32v32 96 32H480V480v32H448 64 32V480 288H0V256 160 128H32 80C70 114.6 64 98 64 80C64 35.8 99.8 0 144 0h2.9c28.6 0 55.2 14.7 70.3 39L256 101zM144 160H32v96H64 240V160H224 144zm128 0v96H448h32V160H368 288 272zM240 288H64V480H240V288zm32 192H448V288H272V480z"/>
                                                                            </svg>
                                                                        </div>
                                                                        <p>
                                                                            {
                                                                                t.rich('Components.Cart.gifts.remaining',{
                                                                                    total: (chunk) => Cart.money( Cart.get( cart => ( item.requirement - cart.cost.subtotalAmount.amount ) ) )
                                                                                })
                                                                            }
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))
                                    )
                                )}

                                {
                                    Cart.get( cart => cart?.total_discounts ) > 0 && (
                                        <tr className="discounts">
                                            <td colSpan="3">
                                                <h2>{ t('Components.Cart.discounts-applied') }</h2>
                                                <div className="list">
                                                    {
                                                        ( Cart.get( cart => cart.cart_level_discount_applications ) || [] ).map( ( discount , i ) => (
                                                            <div key={i} className="discounts">
                                                                { discount.title }
                                                                <span>({
                                                                    t('Components.Cart.savings',{
                                                                        value: (
                                                                            discount.value_type == 'percentage' ?
                                                                                ( discount.value ) + '%'
                                                                            :
                                                                                ( discount.value )
                                                                        )
                                                                    })
                                                                })</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }

                                {
                                    upsell.length > 0 && (
                                        <tr className="upsell">
                                            <td colSpan="3">
                                                <h2>{ t('Components.Cart.upsell.title') }</h2>
                                                <div className="wrap">
                                                    <Swiper {...Parameters} className="list">
                                                        {
                                                            upsell.filter(

                                                                //Skip products in the cart
                                                                (product) => !Cart.get( cart => cart.lines.find( line => line.product.id == product.id ) )

                                                            //Only show 6
                                                            ).slice(0, 6).map(
                                                                ( product, i ) => (
                                                                    <SwiperSlide key={ 'upsell:' + i + product.handle } className="product">
                                                                        <Link href={ '/products/' + product.handle } title={ 'View: ' + product.title } className="featured">
                                                                            <Image src={ product.featuredImage } alt="" width="230" height="230" />
                                                                        </Link>
                                                                        <div className="info">

                                                                            <h3>
                                                                                <Link href={ '/product/' + product.handle } title={ 'View: ' + product.title }>
                                                                                    { product.title }
                                                                                </Link>
                                                                            </h3>

                                                                            <p>
                                                                                { product.productType } &nbsp;|&nbsp; { product.variant?.title }
                                                                                <br />
                                                                                {  product.profile?.[ locale ] }
                                                                            </p>

                                                                            <AddToCart onClick={(e)=>{
                                                                                e.preventDefault();
                                                                                Cart.add( product , 1 )
                                                                            }} variant={ product.variant } size="small" button />

                                                                        </div>
                                                                    </SwiperSlide>
                                                                )
                                                            )
                                                        }
                                                    </Swiper>
                                                    <nav>
                                                        <a onClick={ () => swiper.instance.slidePrev() }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                                                <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="currentcolor" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M18.4802 9.65869L1.78296 9.65869" stroke="currentcolor" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </a>
                                                        <a onClick={ () => swiper.instance.slideNext() }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                                                <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="currentcolor" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M18.4802 9.65869L1.78296 9.65869" stroke="currentcolor" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </a>
                                                    </nav>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Checkout */}
                <footer>
                    <Button disabled={ isSubmitting || lines.length == 0 } button type="submit">
                        <span className="price">
                            {
                                Cart.get( cart => (
                                    cart.original_total_price > cart.total_price && (
                                        <strike className="was">
                                            { Cart.money( cart.original_total_price ) }
                                        </strike>
                                    )
                                ))
                            }
                            <span className="current">{ Cart.money( ( Cart.get( cart => cart.cost.subtotalAmount.amount ) || 0 ) ) }</span> - </span>{ t('Components.Cart.checkout') }
                    </Button>
                    <p className="info">{
                        t.rich('Components.Cart.info',{
                            em: (chunk) => (<em>{chunk}</em>)
                        })
                    }</p>
                </footer>
            </div>
        </form>
    );

}