/**
*
*   Search Button
*       - The Button Control for the Search in the Nav
*
**/
'use client';

import Config from '@/config';
import Image from 'next/image';
import Preloader from '@/components/preloader';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { ModalContext } from '@/context/modal';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useContext } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { ErrorMessage } from '@hookform/error-message';


//Build the Element
export default function Batch({ children, ...props }){

    //Form Settings
    const { control, register, setError, formState: { errors, isSubmitting, isSubmitSuccessful }, handleSubmit } = useForm();

    //Translations
    const t = useTranslations('Components.Batch');

    //Form Translations
    const f = useTranslations('Forms.Errors');

    //Loading a Batch Inquiry
    const [ loading , setLoading ]  = useState(false);

    //Load the Modal Context
    const { Modal } = useContext(ModalContext);

    //Modal Functions
    const Batch = {

        //Error
        error: ({ message }) =>(<p className="error">{ message }</p> ),

        //A Thrown Error Message
        thrown: ( message ) => setError('batch',{
            type: 500,
            message: ( message == 'Files Not Found' ? t('empty') : message )
        }),

        templates: {

            //A File
            file: (row) => (
                <a href={ row.url } target="_blank">
                    { row.name }
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentcolor" d="M272 16V0H240V16 329.4L139.3 228.7 128 217.4 105.4 240l11.3 11.3 128 128L256 390.6l11.3-11.3 128-128L406.6 240 384 217.4l-11.3 11.3L272 329.4V16zM140.1 320H32 0v32V480v32H32 480h32V480 352 320H480 371.9l-32 32H480V480H32V352H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/>
                    </svg>
                </a>
            ),

            //A Text List
            list: (row) => (
                <>
                    <h3>{ row.name }</h3>
                    <ul>{
                        row.list.map( (item,i) => (
                            <li key={i}>{ item }</li>
                        ))
                    }</ul>
                </>
            ),

            //An ASsociated File
            association: (row) => (
                <>
                    <h3>{ row.link.description } <small>(#{ row.link.identifier })</small></h3>
                    <ul>{
                        row.link.archive.map(
                            (item,j) => ( item.type == 'file' && (
                                <li key={j}>{
                                    Batch.templates.file( item )
                                }</li>
                            ))
                        )
                    }</ul>
                </>
            )

        },


        /**
        *
        *   Batch.submit
        *       - Runs the Batch Query
        *
        **/
        submit: async (data) => {
            setLoading(true);

            //Get the Batch
            const response = await ShopifyTools.get( 'library' , 'single' , {
                classifier: 'batch',
                identifier: data.batch
            });

            //If no results
            if( !response.result ){
                Batch.thrown( response.message );
                return setLoading(false);
            }

            //Secondary Files
            let search = [];

            //Add all the secondary associations
            response.body.archive.forEach( row => {
                if( row.type == 'association' ){
                    search.push({
                        classifier: row.link.split(':')[0],
                        identifier: row.link.split(':')[1]
                    })
                }
            })

            //Load all teh secondary
            if( search.length > 0 ){
                const secondary = await ShopifyTools.get( 'library' , 'all' , search );

                //Link all the secondary data
                for( var key in secondary.body )
                    response.body.archive.find( item => item.link == key ).link = secondary.body[ key ];

            }

            Modal.open({
                content: (
                    <div className="batch-details">
                        <header>
                            <h5>{
                                t('popups.batch.title')
                            }</h5>
                            <p>{ response.body.description } <small>(#{ data.batch })</small></p>
                        </header>
                        <ul className={ 'details' + ( search.length > 0 ? ' multi' : '' ) }>{
                           response.body.archive.map( (row,i) => (
                                <li key={i}>
                                    { row.type == 'file' && Batch.templates.file( row ) }
                                    { row.type == 'list' && Batch.templates.list( row ) }
                                    { row.type == 'association' && Batch.templates.association( row ) }
                                </li>
                           ))
                        }</ul>
                    </div>
                )
            })

            setLoading(false);
        },




        /**
        *
        *   Batch.info
        *       - Load the Batch Informational Popup
        *
        **/
        info: () => {

            Modal.open({
                content: (
                    <div className="batch-locator">
                        <header>
                            <h5>{ t('popups.info.title') }</h5>
                            <p>{ t('popups.info.description') }</p>
                        </header>
                        <div className="details">
                            <div>
                                <p>
                                    <strong>{ t('popups.process.title') }</strong>
                                    { t('popups.process.description') }
                                </p>
                                <Image src={ '/assets/components/batch-number-example.png' } width="165" height="59" alt={ t('popups.process.example') } />
                            </div>
                            <div>
                                <ol>
                                    <li>
                                        <strong>{ t('popups.process.steps.locate.title') }</strong>
                                    </li>
                                    <li>
                                        <strong>{ t('popups.process.steps.search.title') }</strong>
                                        <p>{ t('popups.process.steps.search.description') }</p>
                                    </li>
                                    <li>
                                        <div className="arrow">
                                            <div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <strong>{ t('popups.process.steps.submit.title') }</strong>
                                        <p>{ t('popups.process.steps.submit.description') }</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                )
            });


        }
    };

    //Return
    return (
        <div className="batch-form">
            <form onSubmit={ handleSubmit(Batch.submit) }>
                <input
                    disabled={ loading || isSubmitting }
                    type="text"
                    name="batch"
                    placeholder={ t('placeholder') }
                    {...register('batch', { required: f('required',{ type: t('placeholder') }) }) }
                />
                <button disabled={ loading || isSubmitting } data-batch="submit">
                    { loading && (<Preloader status={ true } mini />) }
                    { !loading && ( t('button.online') )}
                </button>
            </form>
            <ErrorMessage errors={errors} name="batch" render={ Batch.error } />
            <a onClick={ () => Batch.info() }>{ t('locate') }</a>
        </div>
    );

}