/**
*
*   Tracker
*       - Tracks user usage across the website
*
**/
'use client';

import { AnalyticTools } from '@/components/layout/analytics';
import { useEffect, useContext, useRef } from 'react';
import { CartContext } from '@/context/cart';

//The Tracker Data
export const Tracker = {

    //The Tracker Data
    data: ( typeof window != 'undefined' ? ( JSON.parse( localStorage.getItem('tracker') ) || [] ) : [] ),

    //The Loaded Time
    loaded: new Date().toUTCString(),

    //Functions for managing single entries
    get: (type, id) => {
        try {
            return Tracker.data[ Tracker.data.findIndex( row => { row.id == id && row.type == type } ) ];
        } catch(e) {
            return false;
        }
    },

    //Sort the fields based (opt by type)
    sort: (type) => {

        //Filter the type of tracked date
        const response = Tracker.data.filter( row => !type || row.type == type )

        //Sort based on views & time on page
        response.sort(function(a,b){

            return a.views - b.views || a.time - b.time;

        }).reverse();

        //
        return response;

    }
}


//Build the Element
export default function Tracking({ data, type , id }){

    //Get the Cart
    const { Cart } = useContext(CartContext);

    //Use Effect
    useEffect(()=>{

        //Run on Exit
        return () => {

            //Skip on server applications
            if( typeof window !== 'undefined' ){

                //Get the index (if exists)
                let index = Tracker.data.findIndex( row => row.id == id && row.type == type );

                //Add item to tracker and return index
                if( index < 0 ) index = Tracker.data.push({
                    id:     id,
                    type:   type
                }) - 1;

                //Update the base information
                Tracker.data[ index ] = (()=>{

                    //If we have a product
                    if( type == 'product' ) return {
                        ...Tracker.data[ index ],
                        ...{
                            handle:     data.handle,
                            views:      ( Tracker.data[ index ]?.views || 0 ) + 1,
                            time:       ( ( Tracker.data[ index ]?.time || 0 ) + ( new Date() - new Date( Tracker.loaded ) ) ),
                            cart:       Cart.get( cart => cart.lines.filter( row => ( row.product.id == data.id ) ).length > 0 )
                        }
                    }

                })()

                //Store the Data
                localStorage.setItem( 'tracker' , JSON.stringify(Tracker.data) );

                //
                switch( type ){

                    //Send the View Product event
                    case 'product':
                        AnalyticTools.viewProduct( data );
                        break;

                }

            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    //No Return
    return null;

}