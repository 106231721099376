/**
*
*   Button
*       - Builds the Button Class
*
**/
'use client';
import Config from '@/config';
import { Link } from '@/components/link';

//Locales
const locales = Config.i18n.locales;

//Build the Element
export default function Button({ children , onClick=()=>{} , className , disabled , hide , hidden , href , type , inverted , size , arrow , arrowReverse , button , ...props }){

    let classes     = [ 'button' ],
        Tag         = ( !button ? 'a' : 'button' );

    if( className ) classes.push( className );

    if( size && ['small','narrow'].indexOf('size') ) classes.push( size );

    if( inverted ) classes.push('inverted');

    if( type && ['white','sage','dark'].indexOf(type) > -1 ) classes.push( type );

    if( hidden ) classes.push( 'hidden' );

    if( hide ) classes.push( 'hide' );

    if( hidden || hide ) disabled = true;

    if(typeof disabled == 'undefined') disabled = false;

    if( arrow ) classes.push( 'arrow' );

    if( arrow == 'reverse' ) classes.push( 'arrow-left' );

    if( ['down','up'].indexOf( arrow ) > -1 ) classes.push( 'arrow-' + arrow );

    //Return
    if( href ){
        return (
            <Link href={ href } onClick={ (e) => { if(!disabled) onClick(e) } } disabled={ disabled } className={ classes.join(' ') } {...props}>
                <span>
                    {
                        arrow && arrow == 'reverse' ? (
                         <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M7.3145 2.56445L12.25 7.49996L7.3145 12.4355" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2499 7.5L1.75 7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            `,
                        }} /> )
                        : ''
                    }
                    <span>{ children }</span>
                    {
                        arrow && ['reverse','down','up'].indexOf(arrow) == -1 ? (
                         <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M7.3145 2.56445L12.25 7.49996L7.3145 12.4355" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2499 7.5L1.75 7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            `,
                        }} /> )
                        : ''
                    }
                    {
                        arrow && ['down','up'].indexOf(arrow) > -1 ? (
                        <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
                                    <path d="M3.64645 10.3536C3.84171 10.5488 4.15829 10.5488 4.35355 10.3536L7.53553 7.17157C7.7308 6.97631 7.7308 6.65973 7.53553 6.46447C7.34027 6.2692 7.02369 6.2692 6.82843 6.46447L4 9.29289L1.17157 6.46447C0.97631 6.2692 0.659728 6.2692 0.464466 6.46447C0.269204 6.65973 0.269204 6.97631 0.464466 7.17157L3.64645 10.3536ZM3.5 -2.18557e-08L3.5 10L4.5 10L4.5 2.18557e-08L3.5 -2.18557e-08Z" fill="white"/>
                                </svg>
                            `
                        }} /> )
                        : ''
                    }

                </span>
            </Link>
        );
    }else{
        return (
            <Tag onClick={ (e) => { if(!disabled) onClick(e) } } type={ type == 'submit' ? 'submit' : null } disabled={ disabled } className={ classes.join(' ') } {...props}>
                <span>
                    {
                        arrow && arrow == 'reverse' ? (
                         <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M7.3145 2.56445L12.25 7.49996L7.3145 12.4355" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2499 7.5L1.75 7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            `,
                        }} /> )
                        : ''
                    }
                    <span>{ children }</span>
                    {
                        arrow && ['reverse','down','up'].indexOf(arrow) == -1 ? (
                         <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M7.3145 2.56445L12.25 7.49996L7.3145 12.4355" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2499 7.5L1.75 7.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            `,
                        }} /> )
                        : ''
                    }
                    {
                        arrow && ['down','up'].indexOf( arrow ) > -1 ? (
                        <span dangerouslySetInnerHTML={{
                            __html: `
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
                                    <path d="M3.64645 10.3536C3.84171 10.5488 4.15829 10.5488 4.35355 10.3536L7.53553 7.17157C7.7308 6.97631 7.7308 6.65973 7.53553 6.46447C7.34027 6.2692 7.02369 6.2692 6.82843 6.46447L4 9.29289L1.17157 6.46447C0.97631 6.2692 0.659728 6.2692 0.464466 6.46447C0.269204 6.65973 0.269204 6.97631 0.464466 7.17157L3.64645 10.3536ZM3.5 -2.18557e-08L3.5 10L4.5 10L4.5 2.18557e-08L3.5 -2.18557e-08Z" fill="white"/>
                                </svg>
                            `
                        }} /> )
                        : ''
                    }
                </span>
            </Tag>
        )
    };

}