/**
*
*   LogoTag
*       - Run on Client Side, check if it's the home page or not
*
**/
'use client';



//Build the Element
export default function Logo(){

    //The Open Function
    const open = (e) => {

        //Show Open
        document.getElementById('header').classList.toggle('open');

        //Add Modal Open Class to prevent scroll
        document.body.classList.toggle('mopen');

    }

    //Return
    return (
        <button data-viewport="mobile" data-mobile-menu onClick={ open }>
            <span></span>
            <span></span>
            <span></span>
        </button>
    );

}