/**
*
*   Customer Provider
*       - Override the Next-intl Navigation
*
**/
'use client';

import Config from '@/config';
import Preloader from '@/components/preloader';
import Storefront from '@/libraries/shopify/class';
import useShopifyAnalytics from '@/libraries/shopify/analytics';
import ShopifyTools from '@/libraries/shopify-tools/class';
import { createContext, useState, useEffect, useRef } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useCookies } from 'next-client-cookies';

//The Cart Context
export const ProductContext = createContext(null);

//Create the Cart Context
function ProductProvider({ product , children }){

    //Variant State
    const [data, setData] = useState(product);

    //The Cookies Library
    const Cookies = useCookies();

    //The Shopify Analytics
    const ShopifyAnalytics = useShopifyAnalytics();

    //The Locale
    const locale = useLocale();

    //The Purchase Type
    const [ currency , setCurrency ] = useState( ( Cookies.get('currency') || 'fiat' ) );

    //The Product Functions
    const Product = {

    	//The Default Variant
	    default: product.variant,

    	//The Product Data
    	data: data,

    	//setCurrency State
    	setCurrency: setCurrency,

    	//The Current Currency
    	currency: currency,

    	/**
    	*
    	*	set
    	*		- Apply updated Product Data
    	*
    	* 	Params:
    	* 		data: 		(Object) The Product Data to assign
    	*
    	**/
		setProduct: (value) => {

			//Set the Product Data
			const product = {
				...product,
				...value
			};

            //Send the PageView on load
            ShopifyAnalytics.sendPageView({
                locale:  locale,
                payload: {
                	products: [{
	                    productGid:     product.id,
	                    name:           product.title,
	                    brand:          product.vendor,
	                    price:          product.variant.price.amount,
	                    variant:        product.variant.id,
	                    variantName:    product.variant.title,
	                    category:       product.productType,
	                    sku:            product.variant.sku
	                }]
                }
            });

            //Update the Product Data
			setData( product );

		},

    	/**
    	*
    	*	set
    	*		- Apply the new variant
    	*
    	* 	Params:
    	* 		variant: 		(INT) The Index of the variant to assign
    	*
    	**/
		setVariant: (index) => {

			//Set the Variant Data
			const product = {
				...data,
				variant: data.variants[ index ]
			};

            //Send the PageView on load
            ShopifyAnalytics.sendPageView({
                locale:     locale,
                payload: 	{
                	products: [{
	                    productGid:     product.id,
	                    name:           product.title,
	                    brand:          product.vendor,
	                    price:          product.variant.price.amount,
	                    variant:        product.variant.id,
	                    variantName:    product.variant.title,
	                    category:       product.productType,
	                    sku:            product.variant.sku
	                }]
                }
            });

            //Update Variant
            setData( product );
		
		}


    };

	//Return the Provider
	return (
		<ProductContext.Provider value={{ Product }}>
			{children}
		</ProductContext.Provider>
	)
}

export default ProductProvider;